import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from '../../../constants/Themed';
import { StyleSheet, View, Image, Pressable, Platform, Alert } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import Packages from './SubComponents/Packages';
import FlipCard from 'react-native-flip-card';
import apis from '@api/api';
import * as Stripe from '@stripe/stripe-react-native';
import { useDispatch } from 'react-redux';
import { SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import PaymentIntent from '@components/stripe/stripeConnection';
import DynamicPlanInfo from '@components/login/DynamicPlanInfo';

const customAppearance = {
  font: {
    family:
      Platform.OS === 'android' ? 'avenirnextregular' : 'AvenirNext-Regular',
  },
  shapes: {
    borderRadius: 12,
    borderWidth: 0.5,
  },
  primaryButton: {
    shapes: {
      borderRadius: 20,
    },
  },
  colors: {
    primary: '#fcfdff',
    background: '#ffffff',
    componentBackground: '#f3f8fa',
    componentBorder: '#f3f8fa',
    componentDivider: '#000000',
    primaryText: '#000000',
    secondaryText: '#000000',
    componentText: '#000000',
    placeholderText: '#73757b',
  },
};

const PackageModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [flip, setFlip] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const { initPaymentSheet, presentPaymentSheet } = Platform.OS != 'web' ? Stripe.useStripe() : { initPaymentSheet: null, presentPaymentSheet: null };
  const [loading, setLoading] = useState(false);

  const initializePaymentSheet = async () => {
    const {
      paymentIntent,
      ephemeralKey,
      customer,
      publishableKey,
    } = await fetchPaymentSheetParams();

    const { error } = await initPaymentSheet({
      merchantDisplayName: "SmartSaaS ltd",
      customerId: customer,
      appearance: customAppearance,
      // applePay: {
      //   paymentSummaryItems: [
      //     {
      //       label: selectedItem.name,
      //       amount: selectedItem.default_price.unit_amount/100,
      //       paymentType: PlatformPay.PaymentType.Recurring
      //     }
      //   ],
      //   merchantCountryCode: 'GB',
      // },
      customerEphemeralKeySecret: ephemeralKey,
      paymentIntentClientSecret: paymentIntent,
      // Set `allowsDelayedPaymentMethods` to true if your business can handle payment
      //methods that complete payment after a delay, like SEPA Debit and Sofort.
      allowsDelayedPaymentMethods: true,
    });
    if (!error) {
      setLoading(true);
    }
  };


  useImperativeHandle(ref, () => ({
    openModal: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  const fetchPaymentSheetParams = async () => {
    const response = await apis.paymentSheet({ product: selectedItem, customer: { id: 'cus_NORBLvdhWAsR3o' } }).then(res => { return res })
    const { paymentIntent, ephemeralKey, customer, publishableKey } = await response;

    return {
      paymentIntent,
      ephemeralKey,
      customer,
      publishableKey
    };
  };

  const openPaymentSheet = async () => {
    if (!selectedItem) {
      return
    }
    const { error } = await presentPaymentSheet();
    if (error) {
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: error.message, type: 'error' } })
    } else {
      Alert.alert('Success', 'Thank you for your purchase');
    }
  };

  return (
    <Modal
      isVisible={open}
      style={{ minWidth: "53%", transform: [{ scale: 0.8 }], top: -200, minHeight: "100%" }}
      onBackdropPress={() => setOpen(false)}
    >
      <Pressable onPress={() => setOpen(false)} style={{ zIndex: 1, position: 'absolute', top: -18, right: -10, borderRadius: 40 }}>
        <AntDesign name="closecircle" size={40} color="red" />
      </Pressable>
      <FlipCard style={{ flexDirection: "row", marginHorizontal: -20, paddingVertical: 50 }}>
        <View style={packStyle.front}>
        {
          open &&
            <Packages open={open} setOpen={setFlip} />
        }
        </View>
        <View style={packStyle.back}>
          <View style={{ flexDirection: "row", flex: 1, paddingHorizontal: 100 }}>
            <DynamicPlanInfo item={selectedItem} />
            <PaymentIntent item={"price_1QKYjYJpKdabcCSwNOhn90gW"} />
          </View>
        </View>
      </FlipCard>
    </Modal>
  )
});

const packStyle = StyleSheet.create({
  front: { flex: 1 },
  back: { flex: 1 }
})

export default PackageModal;