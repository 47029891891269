import { Linking, Platform } from 'react-native';
import {
	REQUEST,
	SUCCESS,
	FAILED,
	GET_ERRORS,
	SHOW_AUTH_MODAL,
	RESET_APP,
	SHOW_PASSWORD_RESET_MODAL,
	TOGGLE_OTP,
	AWAITINGMFA,
	SHOW_LOADING_MODAL,
	HIDE_LOADING_MODAL,
	INITIALISE_MENU,
	SHOW_OTP,
	DECLINE_TERMS,
} from '@reduxLocal/actions/types';
import { DefaultRootState, store } from '@reduxLocal/persistState';
import { LOGIN_API_URL } from './constant';
import { buildDispatchData } from './utils';
import storeData from '@utils/storeUser';

const loginUser = (loginValues, cb) => {
	console.log('loginValues', loginValues);
	return async (dispatch) => {
		store.dispatch({ type: SHOW_LOADING_MODAL });
		dispatch(buildDispatchData(REQUEST));

		if (!loginValues.email || !loginValues.password) {
			store.dispatch({ type: HIDE_LOADING_MODAL });
			store.dispatch({
				type: SHOW_AUTH_MODAL,
				data: {
					content:
						'Please ensure both, Email and Password fields are not empty.',
					type: 'error',
				},
			});
			return;
		}

		try {
			const response = await fetch(LOGIN_API_URL, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(loginValues),
			});

			if (response.status === 204) {
				store.dispatch({ type: HIDE_LOADING_MODAL });
				store.dispatch({ type: SHOW_PASSWORD_RESET_MODAL });
				return;
			}

			if (response.status === 202) {
				if (Platform.OS === 'android') {
					Linking.openURL('otpauth://');
				}
				store.dispatch({ type: HIDE_LOADING_MODAL });
				store.dispatch({ type: SHOW_OTP });
				return;
			}

			if (response.status === 404) {
				const obj = await response.json();
				store.dispatch({ type: HIDE_LOADING_MODAL });
				store.dispatch({
					type: SHOW_AUTH_MODAL,
					data: { content: obj, type: 'error' },
				});
				return;
			}

			if (response.status !== 200) {
				if (loginValues.token) {
					store.dispatch({ type: HIDE_LOADING_MODAL });
					store.dispatch({ type: SHOW_OTP });
					return;
				}

				const obj = await response.json();
				store.dispatch({ type: HIDE_LOADING_MODAL });
				store.dispatch({
					type: SHOW_AUTH_MODAL,
					data: { content: obj?.data || obj?.error || "An error occurred logging in", type: 'error' },
				});
			} else if (response.status === 200) {
				const obj = await response.json();
				const { data } = obj;
				let amendedData = data;
				amendedData.success = true;
				// Store the user data in local storage
				amendedData.expireAt = Date.now() + 1000 * 60 * 60 * 24 * 7;
				store.dispatch({ type: HIDE_LOADING_MODAL });
				dispatch(buildDispatchData(SUCCESS, amendedData));
				store.dispatch({ type: INITIALISE_MENU });
				cb(data, true);
			}
		} catch (err) {
			store.dispatch({ type: HIDE_LOADING_MODAL });
			console.error(err);
		}
	};
};

export default loginUser;
