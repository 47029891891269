import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Text, View, Pressable, Platform, Alert, Animated } from 'react-native';
import * as Animatable from 'react-native-animatable';
import SwitchWithIcons from 'react-native-switch-with-icons';
import LottieView from '@utils/lottieAnimation';
import BasketItems from './BasketItems';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import { CLEAR_BASKET } from '@reduxLocal/actions/types';
import styles from '@stylesheet';
import StoreCalendar from './SubComponents/StoreCalendar';
import { store } from '@reduxLocal/persistState';
import apis from '@api/api';
import SvgQRCode from 'react-native-qrcode-svg';
import Button from '@components/UI/Button/Button';
import { CountUp } from "use-count-up";
import Draggable from 'react-native-draggable';
// import * as LocalAuthentication from 'expo-local-authentication';
// import { useStripeTerminal } from '@stripe/stripe-terminal-react-native';
import BottomSheet, {
  BottomSheetModalProvider,
  BottomSheetModal
} from '@gorhom/bottom-sheet';
import { XStack, YStack } from 'tamagui';

const Basket = ({ currentWidth, callBack, type }) => {
  const storeState = useSelector((state: DefaultRootState) => state.store);
  const basketItems = useSelector((state: DefaultRootState) => state.store.basketItems);
  const [switchState, setSwitchState] = useState(false);
  const [basketModalVisible, setBasketModalVisible] = useState(false);
  const [qrValue, setQrValue] = useState(`smartsaas://form/`);
  const [qrCode, setQrCode] = useState(false);
  const [flip, setFlip] = useState(false);
  const confirmBottomModal = useRef<BottomSheetModal>(null);
  const [pi, setPi] = useState('');
  const [paymentLoading, setPaymentLoading] = useState(false);
  const dispatch = useDispatch();

  const generateQR = useCallback(() => {
    const url = 'smartsaas://store'
    setQrValue(url)
  }, [storeState]);

  const _handleCallback = () => {
    if (callBack) {
      callBack();
    }
    setSwitchState(!switchState);
  }


  const getTotal = () => {
    let total = 0;
    basketItems.forEach(item => {
      total += (parseFloat(item.data.price) * item.basketQuantity);
    });

    // Format total as a price value with 2 decimal places
    const formattedTotal = total.toFixed(2);
    return formattedTotal;
  };

  const getTax = () => {
    let total = parseInt(getTotal());
    if (storeState.vat) {
      return (total * (storeState.vat / 100));
    }
    else {
      return parseFloat(getTotal() * 0.2).toFixed(2);
    }
  }

  const getGrandTotal = () => {
    const preTot = (parseFloat(getTotal()) + parseFloat(getTax()));
    return preTot.toFixed(2);
  }


  return (
    <View style={{ flex: 1, position: Platform.OS != 'web' ? 'absolute' : undefined, maxWidth: '100%', alignSelf: "stretch", minHeight: "100%", minWidth: currentWidth > 800 ? 400 : undefined }}>
        {currentWidth > 800 ?
        <Animatable.View animation={'slideInRight'} style={{ minWidth: 400, maxHeight: 60, flex: 1, padding: 30, gap: 30, overflow: 'hidden', shadowRadius: 20, shadowOpacity: .4, alignSelf: "stretch", minHeight: "100%", paddingBottom: 50, backgroundColor: "white" }}>
          {/* <View style={{ flex: 1, flexDirection: 'row', borderRadius: 60, shadowRadius: 20, shadowOpacity: 0.2, justifyContent: 'space-between', padding: 10 }}>
              <Animatable.View direction={qrCode ? 'reverse' : 'normal'} transition={'flex'} style={{ backgroundColor: 'white', borderRadius: 20, flex: qrCode ? 1 : 0, padding: 20, minHeight: '40%' }}>
                <View style={{ flex: 1, alignSelf: 'center', justifyContent: 'center', padding: 20 }}>
                  {
                    qrCode &&
                    <SvgQRCode size={250} value={qrValue} logoMargin={10} />
                  }
                  <Button buttonText={qrCode ? 'Close' : 'Generate QR'} onPress={() => { setQrCode(!qrCode); generateQR() }} />
                </View>
              </Animatable.View>
            </View> */}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Text style={{ flex: 2, textAlign: 'center', fontWeight: 'bold', fontSize: 24 }}>{switchState ? 'Services' : 'Products'}</Text>
            <View style={{ flex: 1, flexDirection: 'row', borderRadius: 60, justifyContent: 'space-between', padding: 10 }}>
              <SwitchWithIcons noIcon value={switchState} onValueChange={_handleCallback} style={{ flex: 1, zIndex: -1, }} />
            </View>
          </View>
          {
            type === 'services' ?
              <View style={{ overflow: 'hidden', flex: 8, minHeight: 450 }}>
                <Animatable.View animation={'slideInDown'} style={{ backgroundColor: 'white', borderRadius: 20, padding: 10 }}>
                  <StoreCalendar />
                </Animatable.View>
              </View>
              :
              <View style={{ flex: 1 }}>
                <View style={{ borderRadius: 20, paddingHorizontal: 20, backgroundColor: 'white', flex: 7, padding: 10 }}>
                  <BasketItems />
                </View>
                <YStack style={{ backgroundColor: "#ececec", flex: 1, borderRadius: 20, padding: 10, gap: 10 }}>
                  <XStack style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ flex: 3, fontSize: 24, fontWeight: 'bold' }}>
                      TOTAL: £
                  </Text>
                    <Text style={{ flex: 1 }}><CountUp isCounting start={0} end={parseFloat(getTotal())} /></Text>
                    <Text style={{ fontSize: 22, fontWeight: 'bold' }}>VAT: {storeState.vat}</Text>
                    </XStack>
                </YStack>
                <View style={{ flex: 1 }}>
                  <View style={{ flexDirection: 'row', gap: 30, alignSelf: 'center' }}>
                    <Button buttonText={'Clear'} customStyle={{ borderRadius: 10, shadowOpacity: 0.1, shadowRadius: 10, minWidth: 120 }} onPress={() => dispatch({ type: CLEAR_BASKET })} />
                    <Button buttonText={'Checkout'} customStyle={{ borderRadius: 10, shadowOpacity: 0.1, shadowRadius: 10, minWidth: 120 }} defaultColor='red' onPress={() =>
                      //TODO Implement checkout logic 
                      console.log('hi')
                    } />
                  </View>
                </View>
              </View>
          }
        </Animatable.View>
        :
        <Draggable isCircle shouldReverse onPressOut={() => setBasketModalVisible(true)}>
          <View >
            {/* <Modal
                isVisible={basketModalVisible}
                onBackdropPress={() => setBasketModalVisible(false)}
                style={{ paddingVertical: 0, marginVertical: 40, overflow: 'visible' }}
              >
                <Pressable onPress={() => setBasketModalVisible(false)} style={{ position: 'absolute', top: -15, right: -15, borderRadius: 40, zIndex: 4, backgroundColor: 'white' }}>
                  <AntDesign name="closecircle" size={40} color="red" />
                </Pressable>
                <View style={{ flex: 1, padding: 20, backgroundColor: 'rgb(90,192,234)', alignSelf: "stretch", overflow: 'hidden', borderRadius: 20 }}>
                  <View style={{ flexDirection: 'row', flex: 0.2, maxHeight: 50, paddingHorizontal: 20, alignContent: 'space-between', alignSelf: "stretch", margin: -10 }}>
                    <LottieView style={{ width: '50%', flex: 1, maxWidth: 40, backgroundColor: 'white', borderRadius: 30 }} source={require('@assets/animations/52003-e-commerce-basket.json')} autoPlay />
                    <View style={{ flex: 3 }}></View>
                    <Pressable style={{ width: '50%', flex: 1, maxWidth: 50, opacity: 0.5 }} onPress={() => console.log('hi')}>
                      <AntDesign name="setting" size={36} color={'black'} style={{ flex: 1, alignSelf: 'flex-end' }} />
                    </Pressable>
                  </View>
                  <FlipCard style={{ flex: 1, paddingVertical: 10 }} flipHorizontal={true} flipVertical={false} clickable={false} flip={flip}>
                    <View style={[styles.face, { gap: 20 }]}>
                      <View style={{ backgroundColor: 'white', flex: 1, borderRadius: 20, padding: 5, paddingVertical: 10 }}>
                        <BasketItems />
                      </View>
                      <View style={{ flex: 1, paddingHorizontal: 10, backgroundColor: 'white', borderRadius: 20, paddingVertical: 10, maxHeight: 100 }}>
                        <View style={{ flex: 1, flexDirection: 'row', alignContent: 'space-around' }}>
                          <Text style={{ flex: 1, fontWeight: 'bold' }}>Subtotal</Text>
                          <Text>
                            {getTotal()}
                          </Text>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                          <Text style={{ flex: 1, fontWeight: 'bold' }}>VAT</Text>
                          <Text>
                            <CountUp isCounting start={0} end={parseFloat(getTax())} />
                          </Text>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                          <Text style={{ flex: 1, fontWeight: 'bold' }}>Total</Text>
                          <Text style={{ fontWeight: '800' }}>
                            £ <CountUp isCounting start={0} end={parseFloat(getGrandTotal())} />
                          </Text>
                        </View>
                      </View>
                      <View style={{ flex: .1, flexDirection: 'row', alignSelf: 'center', paddingHorizontal: 5, flexWrap: 'wrap' }}>
                        <Button buttonText={'Clear'} customStyle={{ borderRadius: 10, shadowOpacity: 0.2, shadowRadius: 10 }} defaultColor='red' onPress={() => dispatch({ type: CLEAR_BASKET })} />
                        <Button buttonText={'Checkout'} customStyle={{ borderRadius: 10, shadowOpacity: 0.2, shadowRadius: 10 }} onPress={() => console.log('hi')} />
                      </View>
                    </View>
                    <View style={[styles.back, { gap: 10 }]}>
                      <Button buttonText={'Verify Location'} defaultColor='white' customStyle={{ borderRadius: 10, shadowOpacity: 0.2, shadowRadius: 10, minWidth: '100%' }} onPress={() => apis.registerStripeLocation({ accountId: storeState.storeId })} />
                      <Button buttonText={'Register reader'} customStyle={{ borderRadius: 10, shadowOpacity: 0.2, shadowRadius: 10, minWidth: '100%' }} onPress={() => apis.registerStripeReader({ accountId: storeState.storeId })} />
                    </View>
                  </FlipCard>
                </View>
                <BottomSheetModalProvider>
                  <BottomSheetModal
                    ref={confirmBottomModal}
                    snapPoints={['28%']}
                    backgroundComponent={({ style }) => <Animated.View style={[style, { backgroundColor: themeSelector() ? 'white' : 'black', borderRadius: 20 }]} />}
                    handleIndicatorStyle={{ backgroundColor: 'black' }}
                  >
                    <View style={{ flex: 1, padding: 20, gap: 20, backgroundColor: themeSelector() ? 'white' : 'black', borderRadius: 20 }}>
                      <Title>Confirm Payment of {parseFloat(getGrandTotal())}</Title>
                      {
                        paymentLoading ?
                        <ActivityIndicator/>
                        :
                        <Button buttonText="Confirm Payment" customStyle={{ minWidth: '100%', shadowOpacity: 0 }} onPress={async () => { console.log('hi')}} />
                      }
                    </View>
                  </BottomSheetModal>
                </BottomSheetModalProvider>
            </Modal> */}
            <View
              style={{
                position: "absolute",
                backgroundColor: "red",
                minHeight: 30,
                minWidth: 30,
                borderRadius: 1000,
                right: 0,
                top: 0,
                shadowRadius: 20,
                shadowOpacity: .2
              }}
            >
              <Text style={{ textAlign: 'center', color: 'white', top: 7 }}>{basketItems.length}</Text>
            </View>
            <LottieView style={{ width: 75 }} source={require('@assets/animations/52003-e-commerce-basket.json')} autoPlay />
          </View>
        </Draggable>
      }

    </View>
  )
}
export default Basket;
