import * as Linking from 'expo-linking';
import CreativeTemplates from './screens/MidLevel/DataScreen/TemplatesScreen/CreativeTemplates';

//TODO: Add linking configuration for the working application for WEB URLs!
const linking = {
	prefixes: [Linking.createURL('/')],
	config: {
		screens: {
			Form: 'form/:id',
			LoadingScreen: 'loading',
			UserProfile: 'userprofile/:userId',
			Login: 'login',
			Dashboard: {
				path: 'dashboard',
				screens: {
					// Academy: {
					// 	Completed: 'completed',
					// 	Progress: 'progress',
					// },
					Welcome: 'welcome',
					Invitations: 'invitations',
					Data: {
						path: 'data',
						screens: {
							Data: 'data',
							Find: 'find',
							Upload: 'upload',
							ActionHandler: 'action-handler/:dataId',
							CreateData: 'create-data',
							ManageData: 'manage-data',
							DataStore: 'data-store',
							TimeLineData: 'timeline/:dataId',
							DataCreator: 'data-creator',
							DataHandler: 'data-handler',
							DataBrowser: 'data-browser/:requestId/:title',
							DataTemplates: 'data-templates',
							DataMenu: 'data-menu',
							CreateEmailTemplate: 'create-email-template',
							PublicData: 'public-data',
							PreviewEmailTemplate:
								'preview-email-template/:templateId',
							EmailTemplates: 'email-templates',
							InvoiceTemplates: 'invoice-templates',
							DocumentTemplates: 'document-templates',
							ProductScreen: 'product/:productId',
							CreativeTemplates: 'creative-templates',
							NewCreativeTemplate: 'edit-creative-template',
						},
					},
					Sales: {
						path: 'sales',
						screens: {
							SalesDashboard: 'sales-dash',
							SalesFunnel: 'sales-funnel/:campaignId',
							SalesStats: 'sales-stats/:id',
							ActiveCampaigns: 'active-campaigns',
							PreviousCampaigns: 'previous-campaigns',
							SalesLeads: 'sales-leads',
							Forecast: 'forecast',
						},
					},
					ProjectManagement: {
						path: 'project-management',
						screens: {
							Projects: 'project-list',
							IndividualProjectPage: 'project/:title',
							ProjectMenu: 'project-menu',
						},
					},
					Reports: {
						path: 'data-reports',
						screens: {
							Reports: 'reports',
							IndividualReportScreen: 'report/:dataId',
							ReportDashboard: 'report-dashboard',
						},
					},
					Calendar: {
						path: 'calendar',
						screens: {
							MainCalendar: 'main-calendar',
							Reminders: 'reminders',
							Meetings: 'meetings',
						},
					},
					Users: {
						path: 'users',
						screens: {
							UserList: 'user-list',
							StartupBuilder: 'startup-builder',
							UserActivity: 'user-activity',
							UserManagement: 'user-management',
						},
					},
					Integrations: 'integrations',
					Settings: {
						path: 'settings',
						screens: {
							Profile: 'profile',
							Achievements: 'achievements',
							Upgrade: 'upgrade',
							Credits: 'credits',
							Statistics: 'statistics',
							Affiliate: 'affiliate',
						},
					},
					Knowledge: {
						path: 'knowledge',
						screens: {
							InternalKnowledge: 'internal-knowledge',
							ExternalKnowledge: 'external-knowledge',
							KnowledgeScreen: 'knowledge-screen',
							EditKnowledge: 'edit-knowledge/:knowledgeArticleId',
						},
					},
					MailLoginScreen: {
						path: 'mail',
						screens: {
							MailLoginScreen: 'login',
							Main: 'mailbox',
						},
					},
					Networking: {
						path: 'networking',
						screens: {
							Map: 'map',
							Search: 'search',
						},
					},
					Help: 'help',
				},
			},
			Store: {
				path: 'store',
				screens: {
					StoreLoading: 'loading-store',
					StoreList: 'store/:storeId',
				},
			},
			NotFound: '404',
		},
	},
	// 		getStateFromPath(path, options) {
	// 			let state = getStateFromPath(path, options);
	// console.log(state)
	// 			// If the state is undefined, it means that the path doesn't match any route in our config, and
	// 			// we want to handle the path ourselves to the right screen, by updating the state and returning it.
	// 			// if (!state) {
	// 			// 	// check if route contains the main identifier of the screen we want to show
	// 			// 	const isTree = path.includes('dashboard');
	// 			// 	if (isTree) {
	// 			// 		const [, , branch, ...rest] = path.split('/');

	// 			// 		state = {
	// 			// 			routes: [
	// 			// 				{
	// 			// 					path,
	// 			// 					name: 'Dashboard',
	// 			// 					params: {
	// 			// 						branch,
	// 			// 						path: rest.join('/'), // here, our path is the rest of the path after the branch
	// 			// 					},
	// 			// 				},
	// 			// 			],
	// 			// 		};
	// 			// 	}
	// 			// }
	// 			return state;
	// 		},
};

export default linking;
