//App state handler
export const OPEN_DATA = 'OPENED DATA PAGE';
export const OPEN_PROJECT = 'OPENED PROJECT';
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGGED_IN = "LOGGED_IN";
export const DATA_QUERY = 'DATA_QUERY';
export const SOFTWARE_INFO = 'SOFTWARE_INFO';
export const APP_SETTINGS = 'APP_SETTINGS';
export const AUTHENTICATED = 'AUTHENTICATED';
export const LOGGED_OUT = 'LOGGED OUT';
export const DOWNLOAD_AVATAR = 'AVATAR UPDATED';
export const CLEAR_ERRORS = 'CLEAR ERRORS';
export const HANDLE_FAVOURITE = 'REMOVED FAVOURITE';
export const REFRESH_GOOGLE_TOKEN = 'REFRESH GOOGLE TOKEN';
export const CHANGE_THEME = 'THEME CHANGE REQUESTED';
export const QUOTE_UPDATED = 'UPDATED QUOTE ON DASHBOARD';
export const REGISTER_SUCCESS = 'REGISTERED SUCCESSFULLY';
export const DEFAULT_AVATAR = 'NO AVATAR RETRIEVED, SUBSTITUTING WITH DEFAULT'
export const AVATAR_UPDATED = 'AVATAR UPDATED SUCCESSFULLY';
export const CLEAR_AVATAR = 'CLEARED PREVIOUS AVATAR';
export const LOGO_UPDATED = 'LOGO UPDATED SUCCESSFULLY';
export const SET_MESSAGE_APP_FILTER = 'SET MESSAGE APP FILTER';
export const CHANGE_DESK_IMAGE = 'DESK IMAGE CHANGED';
export const ADD_INTEGRATION = 'ADDED INTEGRATION';
export const REMOVE_INTEGRATION = 'REMOVED INTEGRATION';
export const CLEAR_MAIL_AUTH = 'CLEARED MAIL AUTHENTICATION';
export const NEW_DATA_ADDED = 'ADDED NEW DATA';
export const NEW_USER_ADDED = 'ADDED A NEW USER';
export const SET_UPLOADER_STAGE = 'SET UPLOADER STAGE';
export const CSV_DATA_HELD = 'CSV DATA HELD IN MEMORY';
export const CSV_DATA_RELEASED = 'CSV DATA RELEASED';
export const SHOW_FEEDBACK_MODAL = 'SHOW FEEDBACK MODAL';
export const CHANGE_LANGUAGE = 'LANGUAGE CHANGED';
export const CLEAR_UPLOADER_HISTORY = 'CLEARED UPLOADER HISTORY';
export const UPDATE_PRESENT = 'UPDATE CURRENT STATE';
export const CHANGE_DATA_TITLE = 'UPDATED IMPORTED DATA TITLE';
export const CHANGE_ITEM_DATA_TITLE = 'UPDATED INDIVIDUAL ITEM TITLE';
export const CHANGE_DATA = 'UPDATED DATA';
export const MODIFY_DATA = 'MODIFIED DATA';
export const REMOVE_MAIL_SCOPE = 'REMOVED MAIL SCOPE';
export const ADD_DATA_ITEM = 'ADDED A DATA ITEM';
export const REMOVE_DATA_ITEM = 'REMOVED A DATA ITEM';
export const CHANGE_SELECTED_ITEM = 'CHANGED SELECTED ITEM';
export const CHANGE_DATA_CATEGORY = 'UPDATED IMPORTED DATA CATEGORY';
export const SET_DEFAULT_PREVIEWER = 'RESET UPLOADER STATE';
export const REMOVE_DEFAULT_DATA_MARKER = 'REMOVED DEFAULT UPLOADER MARKER';
export const CHANGE_ITEM_CATEGORY = 'CHANGE UPLOADER ITEM CATEGORY'
export const ADD_DATA_CATEGORY = 'ADDED A NEW DATA CATEGORY';
export const REMOVE_DATA_CATEGORY = 'REMOVED DATA CATEGORY';
export const ASSIGN_DATA_CATEGORY = 'ASSIGNED ITEM A DATA CATEGORY';
export const UNASSIGN_DATA_CATEGORY = 'REMOVED ITEM FROM A DATA CATEGORY';
export const CHANGE_ITEM_DATA_TYPE = 'CHANGED ITEM DATA CATEGORY';
export const CHANGE_VALIDATION_OPTIONS = 'CHANGED VALIDATION OPTIONS';
export const REMOVE_VALIDATION_OPTION = 'REMOVED VALIDATION OPTION';
export const CHANGE_OPTION = 'CHANGED ITEM OPTION';
export const ADD_OPTION = 'ADDED ITEM OPTION';
export const REMOVE_OPTION = 'REMOVED ITEM OPTION';
export const ANALYTICS_COMPONENT_CHANGED = 'CHANGED ANALYTICS COMPONENT';
export const SWITCH_COMPANY_REQUEST = 'USER COMPANY CHANGE REQUESTED';
export const SWITCH_COMPANY_SUCCESSFUL = 'USER COMPANY CHANGED';
export const SWITCH_COMPANY_FAILED = 'USER COMPANY CHANGE REQUEST FAILED'
export const PREVIEW_DATA_REQUESTED = 'DATA PREVIEW REQUESTED';
export const GLOBAL_LOADING = 'GLOBAL LOADING ALTERNATED';
export const SET_NOTIFICATION_TOKEN = 'EXPO NOTIFICATION TOKEN SET';
export const CHANGE_DATA_TAGS = 'CHANGED DATA TAGS';
export const PENDING_REFRESH = 'MARKED FOR REFRESH';
export const REFRESHED = 'REFRESHED';
export const UPDATE_CONVERSATION_ID = "UPSERTED A NEW CONVERSATION";
export const SHOW_TC_MODAL = "SHOW TERMS AND CONDITIONS";
export const HIDE_TC_MODAL = "HIDE TERMS AND CONDITIONS";
export const SET_TUTORIAL_COMPLETE = "SET TUTORIAL COMPLETE";
export const CHANGE_AVAILABILITY = "CHANGE AVAILABILITY";
export const OPEN_QUICK_ACTIONS = "OPENED QUICK ACTIONS";
export const TOGGLE_ADVANCED_MODE = "TOGGLED ADVANCED MODE";
export const CLOSE_QUICK_ACTIONS = "CLOSED QUICK ACTIONS";
export const OPEN_WARPER = "OPENED WARPER";
export const CLOSE_WARPER = "CLOSED WARPER";
export const LOCATION_ALLOWED = "LOCATION ALLOWED";
export const LOCATION_DENIED = "LOCATION DENIED";

export const USER_HIERARCHY_FOCUSED = "FOCUSED ON USER HIERARCHY";
//Report Handler
export const ADD_REPORT = 'ADDED REPORT';
export const SELECT_FIELD = 'SELECTED FIELD';

//Data Handler
export const SET_DATAFILTER = 'SET DATA FILTER';
export const APPLY_VALIDATION = 'APPLIED VALIDATION';
export const REMOVE_VALIDATION = 'REMOVED VALIDATION';
export const REPLACE_HANDLER_SCHEMA = 'REPLACED HANDLER SCHEMA';
export const MODIFY_VALIDATION = 'MODIFIED VALIDATION';
export const CLEAR_HANDLER_DATA = 'CLEARED HANDLER DATA';

//User Management Handler
export const ADD_USER_IN_HIERARCHY = 'ADD_USER';
export const REMOVE_USER_FROM_HIERARCHY = 'REMOVE_USER';
export const CONNECT = 'CONNECT';
export const DISCONNECT = 'DISCONNECT';
export const APPEND_USER_LIST = 'APPEND USER LIST';

//Notification Handler
export const ADD_NOTIFICATIONS = 'ADDED NOTIFICATIONS';
export const REMOVE_NOTIFICATIONS = 'REMOVED NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATED NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEARED NOTIFICATIONS';
export const FILTER_NOTIFICATIONS = 'FILTERED NOTIFICATIONS';
export const NEW_NOTIFICATION = 'NEW NOTIFICATION';

//Feed Handler
export const ADD_LIKE_TO_POST = 'ADDED LIKE TO POST';
export const ADD_COMMENT_TO_POST = 'ADDED COMMENT TO POST';
export const DELETE_COMMENT_FROM_POST = 'DELETED COMMENT FROM POST';
export const DELETE_LIKE_FROM_POST = 'DELETED LIKE FROM POST';

//UI Handler
export const SET_DRAGGED_USER = "SET DRAGGED USER";
export const CLEAR_DRAGGED_USER = "SET DRAGGED ITEM";
export const TOGGLE_GLOBAL_HIERARCHY = 'TOGGLED GLOBAL HIERARCHY';
export const RESET_GLOBAL_HIERARCHY = 'RESET GLOBAL HIERARCHY';
export const USER_HIERARCHY_SOCKET = "SOCKET CONNECTED TO USER HIERARCHY";
export const HEADER_HEIGHT = "HEADER HEIGHT";
export const STATUSBAR_HEIGHT = "STATUSBAR HEIGHT";
export const  OPEN_CONTEXT = "OPENED CONTEXT";
export const  CLOSE_CONTEXT = "CLOSED CONTEXT";
export const CONTEXT_SIZE_TOGGLE = 'TOGGLED CONTEXT SIZE';
export const MENU_RESIZE = 'MENU RESIZE';
export const SECOND_MENU_TOGGLE = 'OPENED SECONDARY MENU';
export const SECOND_MENU_CONTEXT = 'CHANGED SECONDARY MENU CONTEXT';
export const CLOSE_SECOND_MENU = 'SECONDARY MENU CLOSED';
export const MENU_OPEN = 'MENU OPENED';
export const MENU_CLOSE = 'MENU CLOSED';
export const TOGGLE_MENU_WARPER = 'MENU WARPER TOGGLED';
export const CLOSE_MENU_WARPER = 'MENU WARPER CLOSED';
export const SHOW_STICKY_WIDGET = 'SHOWING STICKY NOTES IN WIDGET LIST'
export const UPDATED_INVITATIONS = 'INVITATIONS COUNT UPDATED ';
export const INITIALISE_INVITATIONS = 'INVITATIONS COUNT INITIALISED';
export const RESET_INVITATIONS = 'INVITATIONS COUNT RESET';
export const UPDATED_MESSAGES = 'MESSAGE COUNT UPDATED';
export const UPDATED_CALENDAR_ENTRIES = 'CALENDAR COUNT UPDATED';
export const OPEN_MENU = 'OPENED MENU';
export const OPEN_MESSAGES = 'OPENED MESSAGES';
export const OPEN_HIDDEN_MENU = 'OPENED HIDDEN MENU';
export const CLOSE_HIDDEN_MENU = 'CLOSED HIDDEN MENU';
export const CLOSE_MESSAGES = 'CLOSED MESSAGES';
export const CLOSE_MENU = 'CLOSED MENU';
export const SHAKE_PROFILE = 'SHAKING PROFILE PIC';
export const OPEN_QUICK_ACTION = 'OPENING QUICK ACTIONS';
export const UPDATE_LOCATION = 'UPDATED LOCATION';
export const CLEAR_LOCATION = 'CLEARED LOCATION';

//UI
export const UPDATE_PAGE_BADGES = 'UPDATED PAGE BADGES';
export const CLEAR_PAGE_BADGES = 'CLEARED PAGE BADGES';

//SALES 
export const SET_SALES_DATE_FILTER = 'SET SALES DATE FILTER';

//ACTIVE ITEM
export const SET_ACTIVE_ITEM = 'SET ACTIVE ITEM';

//Report Handler
export const SET_REPORT = 'SET REPORT';

//Company handler
export const SWITCH_COMPANY = 'SWITCHED COMPANY';
export const CLEAR_COMPANY = 'CLEARED COMPANY';
//Widget selector
export const CHANGE_WELCOME_WIDGET = 'HOME SCREEN WIDGET UPDATED';

//Project h
export const ACTIVE_PROJECT = 'ACTIVE PROJECT';
export const PROJECT_TEAM_LIST = 'PROJECT TEAM LIST';

//Arrow handler
export const BEGAN_ARROW_DRAG = 'ARROW DRAGGING BEGAN';
export const STOPPED_ARROW_DRAG = 'ARROW DRAGGING STOPPED';

//Notification Handler
export const NOTIFICATION_RECEIVED = 'RECEIVED NOTIFICATION';
export const NOTICATION_OPENED = 'OPENED NOTIFICATION';
export const NOTIFICATIONS_ACKNOWLEDGED = 'ACKNOWLEDGED NOTIFICATIONS';

//Email Handler
export const UPDATE_GOOGLE_MAIL_TOKEN = 'UPDATED EMAIL TOKEN';
export const GOOGLE_AUTHENTICATED = 'GOOGLE AUTHENTICATION SUCCEEDED';
export const MAIL_AUTHENTICATED = 'AUTHENTICATED USER EMAIL';
export const RETRIEVE_MAILBOX = 'RETRIEVED USER MAILBOX';
export const MAIL_SIGN_IN = 'SIGNED INTO USER MAILBOX';
export const SEND_MAIL = 'SENDING USER MAIL';
export const MAIL_SENT = 'EMAIL SENT';
export const GOOGLE_AUTH_FAILED = 'GOOGLE AUTHENTICATION FAILED';
export const GOOGLE_SIGNIN = 'GOOGLE SIGNIN ATTEMPTED';
export const MAIL_RETRIEVED = 'GOOGLE MAIL RETRIEVED';
export const GOOGLE_LOGOUT = 'LOGGED OUT OF GOOGLE SERVICES';
export const UPDATE_MAIL_COUNT = 'UPDATED MAIL COUNT';
export const MAIL_ITEM_SELECTED = 'SELECTED A MAIL ITEM';
export const CLEAR_SELECTED_MAIL = 'RETURNED TO DEFAULT MAIL SCREEN';
export const UPDATE_ACHIEVEMENTS = 'UPDATED ACHIEVEMENT LIST AND COUNT';
export const HOLD_REGISTER_STATE_VALUES = 'HOLDING REGISTER STATE VALUES';
export const CLEAR_REGISTER_STATE_VALUES = 'CLEARED REGISTER STATE VALUES';
export const UPDATE_MAIL_ACCOUNT = 'UPDATED EMAIL ACCOUNTS';
export const UPDATE_GOOGLE_ACCOUNT = 'UPDATED GOOGLE ACCOUNT'
//ToolBar Handler
export const TOOLBAR_ITEM_DRAGGED = 'TOOLBAR ITEM BEING DRAGGED';
export const TOOLBAR_ITEM_DROPPED = 'TOOLBAR ITEM WAS DROPPED';
export const TOOLBAR_ITEM_PLACED = 'TOOLBAR ITEM PLACED';
export const SIM_GESTURES = 'UPDATED SIMULTANEOUS GESTURE';
export const CLEAR_SIM_GESTURE = 'CLEARED SIM GESTURE';
export const DATA_CREATION_SUCCEEDED = 'SUCCEDED CREATING DATA'
//Sticky Note Handler 
export const ADD_STICKY_NOTE = 'ADDED STICKY NOTE';
export const CHANGE_STICKY_NOTE = 'CHANGED STICKY NOTE';
export const NEXT_STICKY_NOTE = 'CYCLED NEXT NOTE';
export const PREVIOUS_STICKY_NOTE = 'CYCLED PREVIOUS NOTE';
export const REMOVE_STICKY_NOTE = 'REMOVED STICKY NOTE';
export const PIN_NOTE = 'NEW PINNED NOTE';
export const NOTE_DRAGGED = 'DRAGGING NOTE'
export const NOTE_DROPPED = 'DROPPED NOTE'
export const CLEAR_ALL_NOTES = 'CLEARED PINNED NOTES';
export const SET_STICKY_NOTES = 'RETRIEVED STICKY NOTES';
export const MARK_NOTE_COMPLETE = 'MARKED NOTE COMPLETED';
export const UNMARK_NOTE = 'UNMARKED NOTE';

//UPDATE MODAL
export const SHOW_UPDATE_MODAL = 'SHOW UPDATE MODAL';
export const HIDE_UPDATE_MODAL = 'HIDE UPDATE MODAL';
export const TOGGLE_UPDATE_MODAL = 'TOGGLE UPDATE MODAL';

//Terms and Conditions Handlers
export const ACCEPT_TERMS = 'ACCEPTED TERMS';
export const DECLINE_TERMS = "DECLINED TERMS";

//Navigation Handlers
export const ADD_TAB = 'ADD TAB';
export const SWITCH_TAB = 'SWITCH TAB';
export const CURRENT_PAGE = "CURRENT PAGE";
export const ACTIVE_TAB = 'ACTIVE TAB';
export const CLOSE_TAB = 'CLOSE TAB';
export const NAVIGATE_TAB = 'NAVIGATING ON TAB'
export const LOGIN_FLIP = 'FLIP LOGIN';
export const BACK_TO_LOGIN = 'BACK TO LOGIN';
export const CHANGE_MENU = 'MENU TYPE CHANGED';

//Store Handlers
export const ADD_BASKET_ITEM = 'ADDED ITEM TO BASKET';
export const CHECKOUT_REQUESTED = 'REQUESTED CHECKOUT OF BASKET';
export const CHECKOUT_COMPLETED = 'COMPLETED CHECKOUT';
export const CHECKOUT_CANCELLED = 'CANCELLED CHECKOUT';
export const REMOVE_BASKET_ITEM = 'REMOVED ITEM FROM BASKET';
export const RESET_BASKET = 'BASKET RESET';
export const CLEAR_BASKET = 'CLEARED BASKET ITEMS';
export const CLEAR_CHECKOUT_STATUS = 'CLEARED CHECKOUT STATUS';
export const SET_STORE_ID = 'SET A STORE ID';
export const UPDATE_PRODUCT_LIST = 'PRODUCT LIST UPDATED';
export const CLEAR_PRODUCT_LIST = 'PRODUCT LIST CLEARED';
export const UPDATE_READERS = 'UPDATED READER';
export const REMOVE_READER = 'REMOVED READER';
export const ADD_READER = 'ADDED READER';
export const SET_ACTIVE_READER = 'SET ACTIVE READER';
export const CLEAR_BASKET_ITEM = 'CLEARED BASKET ITEM';

//User authentication handler
export const REQUEST = 'REQUEST';
export const SUCCESS = 'AUTHORISED SUCCESSFULLY';
export const FAILED = 'FAILED';
export const LOGOUT = 'LOGOUT';
export const AWAITINGMFA = 'AWAITING USER MFA';
export const REFRESH = 'REFRESHED USER';

//Query handler
export const REQUEST_PCO_REPORT = 'REQUEST_PCO_REPORT';
export const SUCCESS_PCO_REPORT = 'SUCCESS_PCO_REPORT';
export const FAILED_PCO_REPORT = 'FAILED_PCO_REPORT';
export const PUSH_NEW_QUERY = 'APPENDED NEW QUERY'
export const HISTORY_RETRIEVED = 'QUERY HISTORY RETRIEVED';
export const DATA_SELECTED = 'DATA CHOSEN';

//Calendar handler
export const DATE_SELECTED = 'DATE SELECTED';
export const DATE_UNSELECTED = 'DATE UNSELECTED';
export const CALENDARS_LOADED = 'CALANDERS LOADED';
export const CALENDAR_ENTRY_ADDED = 'CALENDAR ENTRY ADDED';
export const CALENDAR_ENTRY_REMOVED = 'CALENDAR ENTRY REMOVED';
export const GOOGLE_CALENDARS_ADDED = 'ADDED GOOGLE CALENDARS';
export const CLEAR_CALENDARS = 'CALENDARS CLEARED';
export const MAKE_CALENDAR_VISIBLE = 'MADE CALENDAR VISIBLE';
export const HIDE_CALENDAR = 'HID ACTIVE CALENDAR';
export const ADD_CALENDAR_TOKEN = 'ADDED CALENDAR API TOKEN';
export const ADD_LOCAL_CALENDARS = 'ADDED LOCAL CALENDARS';
//Weather handler
export const WEATHER_RETRIEVED = 'WEATHER RETRIEVED';

//News handler
export const NEWS_UPDATED = 'NEWS UPDATED';

//Notification handlers
export const ADD_NOTIFICATION = 'ADDED NOTIFICATION';

//Modal handlers
export const FEEDBACK_MODAL_HIDDEN = 'HIDDEN FEEDBACK MODAL';
export const HIDE_ALL_MODALS = 'HIDE ALL MODALS';
//Message Modal

export const SHOW_MESSAGE_MODAL = 'SHOW MESSAGE MODAL';
export const HIDE_MESSAGE_MODAL = 'HIDE MESSAGE MODAL';
export const TOGGLE_MESSAGE_MODAL = 'TOGGLE MESSAGE MODAL';
export const SHOW_USER_CONTEXT_MODAL = 'SHOW USER CONTEXT MODAL';
export const HIDE_USER_CONTEXT_MODAL = 'HIDE USER CONTEXT MODAL';
export const GET_MESSAGE_CONTENT = 'GET MESSAGE CONTENT';
//Error Modal
export const SHOW_AUTH_MODAL = 'AUTH MODAL DISPLAYED';
export const HIDE_AUTH_MODAL = 'AUTH MODAL HIDDEN';
export const UPDATE_AUTH_MODAL = 'AUTH MODAL CONTENT UPDATED';
//Language Modal
export const SHOW_LANGUAGE_MODAL = 'LANGUAGE MODAL DISPLAYED';
export const HIDE_LANGUAGE_MODAL = 'LANGUAGE MODAL HIDDEN';
export const FLIP_MESSAGE_MODAL = 'MESSAGE MODAL FLIPPED';
//Community Modal
export const SHOW_COMMUNITY_MODAL = 'COMMUNITY MODAL DISPLAYED';
export const HIDE_COMMUNITY_MODAL = 'COMMUNITY MODAL HIDDEN';
export const SHOW_TOOLBAR = 'TOOLBAR DISPLAYED';
export const HIDE_TOOLBAR = 'TOOLBAR HIDDEN';
//Add Project MOdal
export const SHOW_PROJECT_MODAL = 'ADD PROJECT MODAL DISPLAYED';
export const HIDE_PROJECT_MODAL = 'ADD PROJECT MODAL HIDDEN';
//Calendar Entry Modal
export const SHOW_CALENDAR_ENTRY_MODAL = 'ADD CALENDAR ENTRY DISPLAYED';
export const HIDE_CALENDAR_ENTRY_MODAL = 'ADD CALENDAR ENTRY HIDDEN';
//Help Modal
export const SHOW_HELP_MODAL = 'HELP MODAL DISPLAYED';
export const HIDE_HELP_MODAL = 'HELP MODAL HIDDEN';
//Loading Modal
export const SHOW_LOADING_MODAL = 'LOADING MODAL DISPLAYED';
export const HIDE_LOADING_MODAL = 'LOADING MODAL HIDDEN';
export const INITIALISE_MODALS = 'INITALISED MODALS';
export const INITIALISE_MENU = 'MENU INITIALISED';
export const CURRENT_PAN = 'CHANGED CURRENTLY PANNED VIEW';
export const ACHIEVEMENT_POINTS_ADDED = 'ADDED ACHIEVEMENT POINTS';
export const ACHIEVEMENT_ANIMATION_END = 'END OF ACHIEVEMENT ANIMATION';
export const DISMISSED_APP_INFO = 'DISMISSED APP INFORMATION';
export const TOGGLE_CONTEXT = 'TOGGLED CONTEXT';
export const TOGGLE_AI = 'TOGGLED AI';
//Data Modal
export const SHOW_DATA_DELETE_CONFIRM = 'SHOW DELETE CONFIRM DATA MODAL';
export const HIDE_DATA_DELETE_CONFIRM = 'HIDE DELETE CONFIRM DATA MODAL';
export const SHOW_DATA_OPTIONS = 'SHOW DATA OPTIONS MODAL';
export const HIDE_DATA_OPTIONS = 'HIDE DATA OPTIONS MODAL';
export const SHOW_DATA_SHARE_USER = 'SHOW DATA SHARE USER MODAL';
export const HIDE_DATA_SHARE_USER = 'HIDE DATA SHARE USER MODAL';
export const SHOW_DATA_ACTIONS = 'SHOW DATA ACTIONS MODAL';
export const HIDE_DATA_ACTIONS = 'HIDE DATA ACTIONS MODAL';
//Lock Modal
export const SHOW_LOCK_MODAL = 'LOCK MODAL DISPLAYED';
export const HIDE_LOCK_MODAL = 'LOCK MODAL HIDDEN';
//Register MOdal 
export const SHOW_REGISTER_MODAL = 'REGISTER MODAL DISPLAYED';
export const HIDE_REGISTER_MODAL = 'REGISTER MODAL HIDDEN';
export const SET_SELECTED_PLAN = 'SELECTED A PLAN';
export const CLEAR_SELECTED_PLAN = 'CLEARED SELECTED PLAN';
export const UPDATE_PRODUCTS = 'PRODUCT LIST UPDATED';
//Password Reset Modals
export const SHOW_PASSWORD_RESET_MODAL = 'PASSWORD RESET MODAL DISPLAYED';
export const HIDE_PASSWORD_RESET_MODAL = 'PASSWORD RESET MODAL HIDDEN';
// Verification OTP
export const TOGGLE_OTP = 'TOGGLE OTP INPUT';
export const SHOW_OTP = 'SHOW OTP INPUT';
export const HIDE_OTP = 'HIDE OTP INPUT';
//Tutorial Modal
export const SHOW_FIRST_TUTORIAL = 'SHOW FIRST TUTORIAL';
export const HIDE_FIRST_TUTORIAL = 'HIDE FIRST TUTORIAL';
//Mail Login Modal
export const SHOW_MAIL_MODAL = 'MAIL MODAL DISPLAYED';
export const HIDE_MAIL_MODAL = 'MAIL MODAL HIDDEN';
//Notifications Modal
export const SHOW_NOTIFICATIONS_MODAL = 'NOTIFICATIONS MODAL DISPLAYED';
export const HIDE_NOTIFICATIONS_MODAL = 'NOTIFICATIONS MODAL HIDDEN';
export const GET_NOTIFICATIONS_CONTENT = 'NOTIFICATION CONTENT UPDATED';
export const TOGGLE_NOTIFICATIONS_MODAL = 'NOTIFICATIONS MODAL TOGGLED';
export const SHOW_NOTIFICATIONS_PANEL = 'NOTIFICATIONS PANEL DISPLAYED';
export const HIDE_NOTIFICATIONS_PANEL = 'NOTIFICATIONS PANEL HIDDEN';
export const TOGGLE_NOTIFICATIONS_PANEL = 'NOTIFICATIONS PANEL TOGGLED';
//Lightning Modal
export const SHOW_LIGHTNING_MODAL = 'LIGHTNING MODAL DISPLAYED';
export const HIDE_LIGHTNING_MODAL = 'LIGHTNING MODAL HIDDEN';
export const TOGGLE_LIGHTNING_MODAL = 'LIGHTNING MODAL TOGGLED';
//Achievemnt Modal
export const SHOW_ACHIEVEMENT_MODAL = 'ACHIEVEMENT MODAL DISPLAYED';
export const HIDE_ACHIEVEMENT_MODAL = 'ACHIEVEMENT MODAL HIDDEN';
//Company Modal
export const SHOW_ADD_COMPANY_MODAL = 'ADD COMPANY MODAL DISPLAYED';
export const HIDE_ADD_COMPANY_MODAL = 'ADD COMPANY MODAL HIDDEN';
//Sticky Notes Modal
export const SHOW_STICKY_NOTES_MODAL = 'SHOW STICKY NOTES MODAL';
export const HIDE_STICKY_NOTES_MODAL = 'HIDE STICKY NOTES MODAL';

export const RESET_APP = 'APP INITIALISED';

//Dispatcher notices
export const ADDED_DATA_TO_STORE = 'ADDED DATA TO STORE'
export const ADD_NEW_PROJECT = 'ADDED A NEW PROJECT';
export const ADD_NEW_USER = 'ADDED A NEW USER';
export const REGSTER_COMPANY = 'REGISTERED TO A COMPANY';
export const UPGRADE_PLAN = 'UPGRADED USER SUBSCRIPTION';
export const ADD_DATA_MODEL = 'ADDED A NEW DATA MODEL';

// ACTION HANDLER TYPES
export const CLEAR_ACTION_HANDLER_HISTORY = "CLEAR ACTION HANDLER HISTORY";
export const ADD_ACTION = "ADDED ACTION";
export const REMOVE_ACTION = "REMOVED ACTION";
export const SET_ACTIONS = "SET ACTIONS";
export const UPDATE_ACTION = "UPDATED ACTION";
export const UPDATE_ACTION_ITEM = "UPDATED ACTION ITEM";
export const RESET_ACTIONS = "RESET ACTIONS";
export const DEFAULT_ACTIONS = "DEFAULT ACTIONS";
export const CLEAR_ACTIONS = "CLEAR ACTIONS";