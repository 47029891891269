import React, { useState, useCallback, useEffect, memo, forwardRef, useImperativeHandle } from 'react';
import { View, Text, Image, Pressable, Platform, ViewStyle } from 'react-native';
import { GoogleMap, useJsApiLoader, Marker, OverlayView, Circle } from '@react-google-maps/api';
import SearchComponent from '@components/Global/SearchComponent/SearchBar';
import LottieView from '@utils/lottieAnimation';
import { LinearGradient } from 'expo-linear-gradient';
import styles from '@stylesheet';
import * as Animatable from 'react-native-animatable';
import Button from '@components/UI/Button/Button';
import { AntDesign } from '@expo/vector-icons';
import * as Location from 'expo-location';
import { SmartSaaSIcon } from '@assets/--native';

type MapViewProps = {
  companyDetails?: any,
  /**
   * Show a search bar on the map
   * @default false
   */ 
  searchBar?: boolean,
  /**
   * Location details for the map
   * @default undefined
   * @example { lat: 51.496600, lng: -0.381150 }
   * @type { lat: number, lng: number }
   * @required
   * @description The location details for the map
   */ 
  locationDetails?: { lat: number, lng: number },
  /**
   * Show a marker on the map
   */
  marker?: boolean,
  /**
   * Render a heatmap on the map
   * @param map - The map object
   * @returns JSX.Element
   * @default undefined
   */
  renderHeatmap?: (map: any) => JSX.Element,
  heatmapData?: any[],
  noFeatures?: boolean,
  style?: ViewStyle
}

type GMap = {
  panTo: (location: any) => void,
  forceUpdate: () => void
}

const MapView = forwardRef<GMap, MapViewProps>((props, ref) => {
  const { companyDetails, searchBar, locationDetails, marker, renderHeatmap, heatmapData, noFeatures, style } = props;
  const [map, setMap] = useState(null)
  const view: React.CSSProperties = { width: '100%', height: '100%', borderRadius: 20, ...style as React.CSSProperties };
  const [chatOpen, setChatOpen] = useState(Platform.OS === 'web' ? true : false);
  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  let logo = companyDetails?.companyLogo?.img || <SmartSaaSIcon />;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAznGh_h4mH0meG5V6hlTlLVhVithzlUJM'
  })

  useImperativeHandle(ref, () => ({
    panTo: (location) => {
      map.panTo(location);
      map.zoom = 15;
    },
    forceUpdate: () => {
      map.forceUpdate();
    }
  }));

  useEffect(()=>{
    locationDetails && map.panTo(locationDetails)
  }),[locationDetails]

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  let center = {
    lat: 51.496600,
    lng: -0.381150
  }



  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, []);

  const MarkerLoad = ({ mapItem }) => {
    return  (
      <Animatable.View animation={'bounceInDown'} style={[styles.centerEverything, { padding: 10, borderRadius: 20, shadowOpacity: 0.4, elevation: 1, shadowRadius: 20, height: 200, width: 250, left: -125, top: -200, backgroundColor: 'rgba(90, 192, 234, 0.7)', }]}>
        <LinearGradient colors={['white', 'transparent']} style={{ backgroundColor: '#ececec', borderRadius: 20, width: 240, height: 190, padding: 10, marginTop: -5, flexDirection: 'row' }}>
          <View style={{ flex: .8, elevation: 1, shadowRadius: 20, shadowOpacity: 0.2, borderRadius: 20 }}>
            <SmartSaaSIcon style={[styles.centerEverything, { minWidth: 100, flex: 1 }]} />
            {
              companyDetails ?
                <Text adjustsFontSizeToFit style={{ fontWeight: 'bold' }}>{companyDetails.companyName.toUpperCase()}</Text>
                :
                null
            }
          </View>
          <View style={[styles.centerEverything, { flex: 0.2, gap: 10, paddingLeft: 5, justifyContent: "space-around", alignSelf: 'center' }]}>
            <LottieView source={require('@assets/animations/achievementTrophy.json')} style={{ width: '100%' }} autoPlay />
            <Text adjustsFontSizeToFit style={{ fontWeight: 'bold' }}>6794</Text>
            <View style={{ flex: 0.1 }} />
            <Pressable onPress={() => { console.log('get in touch pressed') }}>
              <LottieView source={require('@assets/animations/LordIcons/forum.json')} style={{ width: '80%' }} autoPlay />
            </Pressable>
            <Pressable onPress={() => { console.log('storefront pressed') }}>
              <LottieView source={require('@assets/animations/LordIcons/store.json')} style={{ width: '80%' }} autoPlay />
            </Pressable>
          </View>
        </LinearGradient>
        <View style={{ transform: [{ rotateZ: '45deg' }], width: 50, height: 50, backgroundColor: '#ececec', marginTop: -40, zIndex: -1 }}></View>
      </Animatable.View >
    )
  }

  const circleOptions = {
    strokeColor: '#5ac0eab3',
    strokeOpacity: 0.8,
    strokeWeight: 50,
    fillColor: '#5ac0ea',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 10,
    zIndex: 1
  }

  return isLoaded && (
    <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'column-reverse', minHeight: 180 }} >
      <GoogleMap
        mapContainerStyle={view}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ fullscreenControl: false, zoomControl: false, styles: [{ elementType: "labels", featureType: "poi.all", stylers: [{ visibility: noFeatures ? "off" : "on", }], }], mapTypeControl: false, streetViewControl: false, minZoom: 8, maxZoom: 17 }}
      >
        {heatmapData && renderHeatmap && renderHeatmap(map)}
        <Circle center={center} options={circleOptions} />
        {
          marker &&
        <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <MarkerLoad mapItem={{ logo: logo }} />
        </OverlayView>
        }
        {
          locationDetails &&
          <Circle center={locationDetails} options={circleOptions} />
        }
      </GoogleMap>
      {
        searchBar &&
      <View style={{ position: 'absolute', minWidth: view.width, top: 0, flexDirection: 'row' }}>
        <SearchComponent minWidth={600} key={'userSearch'} placeHolder='Search for users' searchState={undefined} setSearchState={undefined}
          customIcons={[
            { functionName: 'addUser', function: () => undefined, icon: <AntDesign name="adduser" size={24} color="black" /> },
            { functionName: 'chat', function: () => setChatOpen(!chatOpen), icon: <AntDesign name="wechat" size={24} color="black" /> },
            { functionName: 'settings', function: () => setChatOpen(!chatOpen), icon: <AntDesign name="setting" size={24} color="black" /> },
          ]} />
        <Button buttonText="" icon={'wechat'} onPress={() => setChatOpen(!chatOpen)} customStyle={{ top: 0, right: 0, maxWidth: 50 }} />
          </View>
      }
    </View>
  )
})
export default MapView;