import React, { memo, useMemo } from 'react';
import { Pressable, Platform } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { View, Text } from '@constants/Themed';
import parseByDelimiter from '@utils/parseByDelimiter';
import returnIcon from '../utils/returnIcon';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import toTitleCase from '@hooks/toTitleCase';
import { store } from '@reduxLocal/persistState';
import { SHOW_NOTIFICATIONS_PANEL } from '@reduxLocal/actions/types';


const NotificationItem = ({ item }: { item: any }) => {
  const theme = themeSelector();

  return item && (
    <Pressable onPress={() => store.dispatch({ type: SHOW_NOTIFICATIONS_PANEL })} style={{ backgroundColor: theme ? "white" : "#333333", paddingHorizontal: 50, borderRadius: 15, position: "absolute", top: -4, shadowRadius: 20, shadowOpacity: .2, shadowColor: theme ? undefined : "white", maxHeight: 60, flexDirection: 'row', gap: 15, flex: 1, overflow: 'hidden' }}>
      <Animatable.View animation={'slideInLeft'} style={{ flex: 1, maxWidth: 60, minWidth: 60, left: Platform.OS === 'web' ? 20 : -3 }}>
        {
          item.postedBy ?
            <Animatable.Image animation={'slideInLeft'} source={{ uri: item.postedBy.profile_information.avatar.img }} style={{ left: -5, top: -2, width: 30, height: 30, borderRadius: 20 }} />
            :
            <>
              {returnIcon(item.operationType)}
            </>
        }
      </Animatable.View>
      <Animatable.Text numberOfLines={1} animation={'slideInDown'} style={{ userSelect: "none", verticalAlign: "center", flex: 15, minWidth: '90%', paddingVertical: 15, color: theme ? "black" : "white" }}>
        {
          item.postedBy ?
            `${toTitleCase(item.postedBy.profile_information.name)} ${toTitleCase(item.postedBy.profile_information.surname)} ${item.operationType === 'delete' ? 'deleted' : item.operationType} ${parseByDelimiter(item.title ? item.title : "")}` :
            `Detected a${item.operationType === 'delete' ? '' : 'n'} ${item.operationType} event in ${parseByDelimiter(item?.ns.coll)}`
        }
      </Animatable.Text>
    </Pressable>
  );
};

export default memo(NotificationItem)