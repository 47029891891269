import { useCallback, useEffect, useState } from 'react';
import { View, Image, Text, FlatList } from 'react-native';
import React from 'react';
import styles from '@stylesheet';
import apis from '@api/api';
import LoadingIndicator from '../Spinner';
import SearchListUserItem from './components/SearchListUserItem';
import { set } from 'lodash';
import useGlobalUser from '@hooks/getGlobalUser';
import { DropDownPicker } from '@constants/Themed';
import UserListItem from '@navigation/screens/MidLevel/UsersScreen/Components/UserListItem';
import { useQueryClient } from 'react-query';

interface UserFinder {
  setCallback: Function,
  disabled?: boolean,
  selected?: [],
  forceRow: boolean,
  removePermission?: boolean;
  forceSingle?: boolean;
  onOpen?: Function;
  onClose?: Function;
  team?: boolean;
  hideList?: boolean;
}

const UserFinder = ({ hideList, team, onOpen, onClose, forceSingle, setCallback, disabled, selected, removePermission, forceRow = false }: UserFinder) => {
  const [value, setValue] = useState(selected);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = useGlobalUser();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [height, setHeight] = useState(50);

  const teamMembers = team ? useQueryClient().getQueryData('companyMembers') : []; 

  const handleItemSelect = (items) => {
    setSelectedUsers(items);
    if (forceSingle) {
      return setCallback?.(items)
    } else {
    let newItems = [];
    if (Array.isArray(items)) {
      newItems = items.map(item => { return { ...item, editPermission: false, id: item.value } })
      setCallback?.(newItems)
    }
    }
  };

  return (
    <View style={{ flex: 1, minHeight: height, borderRadius: 20, maxWidth: '100%' }}>
      <View style={{ flex: 1, zIndex:10, maxHeight:80, borderBottomWidth: 2,  borderColor:"rgb(90,192,234)" }}>
      <DropDownPicker
        setOpen={() => { setOpen(true); setHeight(300); onOpen && onOpen?.(); }}
        open={open}
        key={selected}
        multiple={forceSingle ? false : true}
        loading={loading}
        onOpen={async () => {
          setLoading(true);
          onOpen && onOpen?.();
          await apis.searchUsers("")
            .then((items: any) => {
              const filteredItems = items.filter(item => item.value !== userId);
              setItems(filteredItems)
            })
            .catch(err => { console.error(err) })
            .finally(() => {
              setLoading(false)
            })
        }}
          style={[styles.dropdownInput, { borderBottomWidth: 2, borderColor: "rgb(90,192,234)" }]}
        mode='SIMPLE'
        items={team ? teamMembers : items}
        onClose={() => { setHeight(50); setOpen(false); onClose && onClose?.() }}
        onChangeSearchText={async (text) => {
          setLoading(true);
          await apis.searchUsers(text)
            .then((items: any) => {
              // const filteredItems = items.filter(item => item.value !== userId);
              setItems(items)
            })
            .catch(err => { console.error(err) })
            .finally(() => {
              setLoading(false)
            })
        }}
        setItems={setItems}
        debounce
        disabled={disabled}
        value={value}
        setValue={setValue}
        showTickIcon
        badgeDotColors={["#e76f51", "#00b4d8", "#e9c46a", "#e76f51", "#8ac926", "#00b4d8", "#e9c46a"]}
        selectedItemContainerStyle={{ backgroundColor: 'rgb(90,192,234)' }}
        closeAfterSelecting={false}
        dropDownContainerStyle={{ top: 70 }}
        placeholder='Select users'
        searchable={true}
        modalProps={{ animationType: 'slide' }}
        renderListItem={(props) => (<SearchListUserItem {...props} />)}
        addCustomItem={true}
        onSelectItem={(item) => { handleItemSelect(item) }}
        searchPlaceholder='Enter an email address'
      />
      </View>
      {
        !hideList &&
      <FlatList
        contentHeight={forceRow ? 50 : undefined}
          style={{ flex: 6, minHeight:"90%", marginVertical: 30 }}
        showsVerticalScrollIndicator={true}
        data={selectedUsers}
        horizontal={forceRow}
        contentContainerStyle={{ flex: 1 }}
        renderItem={({ item, index }) => <UserListItem removePermission={removePermission} row={forceRow} item={item} index={index} filterUser={function (index: number): void {
          throw new Error('Function not implemented.');
        }} />}
      />
      }
    </View>
  )
}
export default UserFinder;