import { createStore, applyMiddleware } from 'redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
// Import your existing rootReducer from './reducers'
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { Platform } from 'react-native';
import { SHOW_TOOLBAR } from './actions/types';

export const fetchLogs = async () => {
  if (Platform.OS != 'web') return;  
  const db = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('logs', 'readonly');
    const store = transaction.objectStore('logs');
    const request = store.getAll();

    request.onsuccess = () => resolve(request.result);
    request.onerror = (event) => reject(event.target.error);
    transaction.oncomplete = () => db.close();
  });
};

// Initialize IndexedDB
const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('LogDB', 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('logs', { autoIncrement: true });
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

// Save log to IndexedDB with a maximum of 300 entries
export const saveLog = async (log) => {
  const db = await initDB();
  const transaction = db.transaction('logs', 'readwrite');
  const store = transaction.objectStore('logs');

  // Add the new log entry
  store.add(log);

  // Check the number of entries and delete the oldest if necessary
  const countRequest = store.count();
  countRequest.onsuccess = () => {
    if (countRequest.result > 300) {
      const getAllKeysRequest = store.getAllKeys();
      getAllKeysRequest.onsuccess = () => {
        const keys = getAllKeysRequest.result;
        store.delete(keys[0]); // Delete the oldest entry
      };
    }
  };

  transaction.oncomplete = () => db.close();
};

export const clearDB = async () => {
  const db = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('logs', 'readwrite');
    const store = transaction.objectStore('logs');
    const request = store.clear();

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };

    transaction.oncomplete = () => db.close();
  });
};


const apiLogger = store => next => action => {
  //If action contains any functions, references or other non-serializable data, it will throw an error so we need to handle it
  if (action.type === FLUSH || action.type === PAUSE || action.type === PERSIST || action.type === PURGE || action.type === REGISTER || action.type === REHYDRATE || action.type === "persist/PERSIST" || action.type === "persist/REHYDRATE") {
    return next(action);
  }

  if (typeof action === 'function') {
    const log = {
      type: 'API_CALL',
      action: action.name,
      duration: undefined,
      response: undefined,
      error: undefined,
      timestamp: new Date().toISOString(),
    };

    action(store.dispatch, store.getState)
      .then(response => {
      if (Platform.OS === 'web') {
        log.response = response;
        log.duration = new Date().getTime() - new Date(log.timestamp).getTime();
        //IGNORE TOOLBAR_DISPLAYED ACTION
        if (log.action !== 'TOOLBAR_DISPLAYED') {
          saveLog(log);
        }
      }
    }).catch(error => {
      if (Platform.OS === 'web') {
        log.error = error;
        log.duration = new Date().getTime() - new Date(log.timestamp).getTime();
        saveLog(log);
      }
    });

    return next(action);
  } else {
    try {
    const log = {
      type: 'ACTION',
      action: action.type,
      payload: action.payload,
      timestamp: new Date().toISOString(),
      duration: undefined,
    };

      log.duration = new Date().getTime() - new Date(log.timestamp).getTime();
      saveLog(log);
    } catch (e) {
      console.error(e);
  }
  return next(action);
  }
};

// Define your middleware, including Redux Flipper in development
const middlewares = Platform.OS != 'web' ? [] : [
  apiLogger
];

// if (__DEV__) {
//   const createDebugger = require('redux-flipper').default;
//   middlewares.push(createDebugger());
// }

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['modal', 'actionMaker', 'lastAction', 'uploadPreview', 'calendar', 'notifications', 'toolbar', 'modals', 'report'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const initialState = rootReducer(undefined, {type: 'INITIAL_STATE'})
export type DefaultRootState = ReturnType<typeof store.getState>
// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, SHOW_TOOLBAR, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      ignoredPaths: ['modal', 'actionMaker', 'lastAction', 'uploadPreview', 'calendar', 'notifications', 'toolbar', 'modals', 'report'],
    }
  }).concat(middlewares),
  devTools: __DEV__ ? true : false
});

// Persistor for use with Redux Persist
const persistor = persistStore(store);

export { store, persistor };
