import React, { useState, useCallback, useRef, useEffect } from 'react';
import DefaultScreen from '../../components/Global/DefaultScreenView';
import { Text, View, Pressable, Platform } from 'react-native'
import GenerateSchemaForm from '@hooks/generateSchemaForm';
import apis from '@api/api';
import { useQuery } from 'react-query';
import getId from '@api/getId';
import AnimatedLottieView from '@utils/lottieAnimation';
import SearchComponent from '../../components/Global/SearchComponent/SearchBar';
import styles from '@stylesheet';
import FlipCard from 'react-native-flip-card';
import LottieView from '@utils/lottieAnimation';
import Button from '../../components/UI/Button/Button';
import { useNavigation } from '@react-navigation/native';
import AchievementBackground from '@assets/--native/AchievementBackground';
import parseByDelimiter from '@utils/parseByDelimiter';
import { Title } from '@constants/Themed';


const FormScreen = ({ route }) => {
  const { id } = route.params;
  const [schema, setSchema] = useState([]);
  const [flip, setFlip] = useState(false);
  const navigation = useNavigation();
  const [error, setError] = useState(false);
  //TODO check if platform is a device and suggest to download the app
  if (Platform.OS === 'web') {

  }

  const { status, data, error: fetchError, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['formSchema'],
    queryFn: async () => {
      const data = await apis.getDataSchema(getId(), id)
        .then(res => {
          return res.data.data
        })
        .catch(err => {
          console.log(err);
          setError(true);
        })
      setSchema(data?.dataSchema);
      return data
    },
  });

  const obj = {};

  useEffect(() => {
    if (!error)
    schema?.forEach((item) => {
      console.log('item is', item);
      const entries = Object.entries(item);
      entries.forEach(([key, value]) => {
        obj[key] = value;
      });
    });

    console.log('this is the obj', obj);
  }, [schema]);


  return (
    <View style={{ flex: 1 }}>
      <AchievementBackground style={{ opacity: 1, zIndex: -2, position: 'absolute', transform: [{ scale: 1.5 }] }} />
      <View style={{ marginVertical: 50, margin: 10, borderRadius: 20, flex: 1 }}>
        <FlipCard flip={flip} clickable={false} flipHorizontal={true} flipVertical={false}>
          <View style={[styles.face, { flex: 1 }]}>
            <SearchComponent backButton showSearch={false} extraComponents={(<Pressable style={{ flex: 1 }}><Text>HI</Text></Pressable>)} />
            {
              isLoading ?
                <AnimatedLottieView source={require('@assets/animations/leaf_Loader.json')} style={{ width: '100%' }} loop autoPlay />
                :
                <>
                  {
                    !error &&
                    <View style={{ marginTop: 80, padding: 20, flex: 1, margin: 10, backgroundColor: "white", borderRadius: 20, overflow: 'hidden' }}>
                      {
                        schema?.length > 0 ?
                  <GenerateSchemaForm hasTitle customStyling={{ borderRadius: 20, overflow: 'hidden' }} schema={schema} submitFunction={apis.addDataItem} callback={{ state: flip, setState: setFlip }} />
                            :
                            <Title style={{ justifyContent: 'center', alignSelf: 'center' }}>An error occurred obtaining the form data</Title>
                        }
                </View>
                  }
                </>
            }
          </View>
          <View style={[styles.back, { flex: 1 }]}>
            <View style={{ flex: 1, height: 500 }}>
              <View style={{ flex: 1, alignSelf: 'center', backgroundColor: '#00000022', borderRadius: 20, padding: 40, gap: 40 }}>
                <LottieView style={{ flex: 1, alignSelf: 'center', alignItems: 'center', maxWidth: '100%', overflow: "hidden" }} source={require('@assets/animations/landing_Animation.json')} autoPlay loop={false} ></LottieView>
                <Text style={{ textAlign: 'center', color: 'white', fontSize: 24 }}>Thank you for completing {parseByDelimiter(data?.dataTitle)}</Text>
                {!getId() &&
                  <>
                    <Text>Why not join us at SmartSaaS and turbo charge your business?</Text>
                  </>
                }
                <Button buttonText={!getId() ? 'To Login' : 'Back'} customStyle={{ flex: 1 }} onPress={() => { navigation.navigate('Login') }} />
              </View>
            </View>
          </View>
        </FlipCard>
      </View>
    </View>
  )
}
export default FormScreen;
