import React from "react";
import { Image, ImageStyle, StyleSheet } from "react-native";
import UserIcon from "@assets/icons/UserIcon";
import styles from "@stylesheet";


interface AvatarHandlerProps {
  /**@property Requires either avatar or avatar.img to ensure that it is a correct http */
  avatar: string | { img: string };
  /**@property Avatar image style applied to view */
  avatarImageStyle?: ImageStyle;
  /**@property replacement image style */
  replacementImageStyle?: ImageStyle;
  /**@property withShadow */
  withShadow?: boolean;
}

const AvatarHandler = ({ withShadow, replacementImageStyle, avatar, avatarImageStyle }: AvatarHandlerProps) => {

  return (
    <>
      {avatar && (
        (typeof avatar === "string" && avatar.includes("http")) ||
        (typeof avatar?.img === "string" && avatar?.img.includes("http"))
      ) ? (
        <Image
          source={{ uri: typeof avatar === "string" ? avatar : avatar?.img }}
          style={[
            styles.centerEverything,
            {
              aspectRatio: 1,
              borderColor: "white",
              shadowRadius: withShadow ? 20 : 0,
              shadowOpacity: withShadow ? 0.2 : 0,
              width: 50,
              height: 50,
              borderRadius: avatarImageStyle?.width
                ? avatarImageStyle?.width / 2
                : 50,
            },
            avatarImageStyle,
          ]}
        />
      ) : (
        <UserIcon style={[{ minHeight: 50, maxHeight: 50 }, replacementImageStyle]} />
      )}
    </>
  );
};

export default AvatarHandler;