import React from 'react';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useSelector } from 'react-redux';
import { View, Text } from 'react-native';
import { FlatList } from 'react-native';
import SecondaryMenu from '../Components/SecondaryMenu';
import { uniqueId } from 'lodash';

const SecondaryMenuList = () => {
  const secondaryMenuArray = useSelector(
    (state: DefaultRootState) => state.UI.secondaryMenuContext
  );


  return (
    <View style={{ flex: 10, borderRadius: 20, overflow: 'hidden', zIndex: 5 }}>
      <FlatList showsVerticalScrollIndicator={false} contentContainerStyle={{ gap: 20 }} data={secondaryMenuArray.content} keyExtractor={(item, index) => uniqueId(item.id + index + 'secondary')} renderItem={({ item, index }) => (
        <SecondaryMenu item={item} index={index} />
      )} />
    </View>
  )
};


export default SecondaryMenuList;
