import React, { useState, useMemo, useRef, useCallback } from 'react';
import { View, Pressable, Platform, Text, ScrollView } from 'react-native'
import searchStyles from './searchStyles';
import GenerateArray from './Components/GenerateArray';
import { SearchComponentTypes } from './Types';
import InteractiveSearch from './Components/InteractiveSearch';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import styles from '@stylesheet';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import lodash from 'lodash';
import * as Animatable from 'react-native-animatable';
import LoadingIndicator from '../Spinner';

const SearchComponent = ({aiCallback, placeHolder, aiSearch = false, searchState, setSearchState, minWidth, customIcons, disableFilter, otherComponents, backButton, showSearch = true, actionButton, action, searchParams, customStyle, callback, helpComponent, fetchingState, extraComponents, inverse, disableBurger }: SearchComponentTypes) => {
  const [extendedMenu, setExtendedMenu] = useState('');
  const [showExtendedMenu, setShowExtendedMenu] = useState(extendedMenu);
  const buttonRef = useRef();
  const navigation = useNavigation();
  const [customIconWidth, setCustomIconWidth] = useState(0);

    const ArrayGenerator = () => {
      if (customIcons) {
        return <GenerateArray disableBurger={disableBurger} customIcons={customIcons} setShowExtendedMenu={setShowExtendedMenu} width={customIconWidth} />;
      }
      return null;
    };

    //Default return JSX component
    return (
      <View style={{ marginBottom: 20, alignSelf: "stretch", minWidth: "97%", flex: 1, minHeight: 40, maxHeight: 40, position: 'absolute', maxWidth: '100%', zIndex: 10, ...customStyle }}>
        <Animatable.View style={{ flex: 20, flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
          <View style={[searchStyles.containerStyle, { flex: 10, paddingVertical: 4, overflow: 'visible', maxHeight: 60, gap: 10, minHeight: 50, paddingRight: extraComponents && 80, minWidth: minWidth || undefined, justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center', backgroundColor: themeSelector() ? 'white' : 'grey' }]}>
            {/* {
              backButton && Platform.OS != 'web' &&
              <View style={[styles.centerEverything, { flex: 1, maxWidth: 50, borderRadius: 20, left: -10, minWidth: '5%' }]}>
                <Pressable onPress={() => { if (navigation.canGoBack()) navigation.pop() }} style={{ flex: 1, justifyContent: "center", alignContent: "center" }}>
                  <AntDesign name="arrowleft" size={24} color="black" />
                </Pressable>
              </View>
            } */}
            <View style={{ flex: 1, flexDirection: inverse ? 'row-reverse' : 'row', paddingHorizontal: 10, minWidth: '80%', minHeight: '110%', alignContent: 'space-around', justifyContent: 'center', alignSelf: 'stretch', paddingVertical: 3 }}>
              {
                otherComponents ?
                  <View style={{ flex: 1, minHeight: 40 }}>
                    <ScrollView showsHorizontalScrollIndicator={false} horizontal style={{ minHeight: 30, alignSelf: "stretch", flexDirection: 'row', flex: 12, gap: 7, zIndex: 5, paddingVertical: 2, alignContent: 'center' }} contentContainerStyle={[styles.centerEverything, { justifyContent: 'space-between', minHeight: 30, paddingVertical: 6, gap: 30 }]}>
                      {otherComponents.map((component, index) => {
                        const uid = lodash.uniqueId();
                        if (component.functionName != 'showHelp' && typeof component.icon === 'string') {
                          return <Pressable key={component.functionName + uid} hitSlop={5} style={{ flex: 1, maxWidth: 60, minHeight: '100%' }} disabled={!component.function ? true : false} onPress={() => { setShowExtendedMenu?.(false); component.function() }}>{component.icon}</Pressable>
                        } else if (component.isIcon) {
                          return <Pressable key={component.functionName + uid} onPress={component.function} style={{ maxWidth: 60 }}>{component.icon}</Pressable>
                        } else return component.icon
                      })}
                    </ScrollView>
                    {
                      otherComponents.map((component, index) => component.functionName == 'showHelp' &&
                        (<Pressable key={component.functionName + index * 8} hitSlop={5} style={{ borderLeftWidth: 2, width: 50, borderColor: 'grey', flex: 1, minHeight: '100%', maxWidth: 35, justifyContent: 'center', alignContent: "center", alignItems: 'center', alignSelf: 'center', paddingVertical: 7 }} key={component.functionName} disabled={!component.function ? true : false} onPress={() => { setShowExtendedMenu(false); component.function() }}>{component.icon}</Pressable>)
                      )}
                  </View>
                  :
                  <View style={{ paddingLeft: backButton ? 30 : undefined, flexBasis: 15, flexGrow: 25, flexShrink: 5, minWidth: '70%', flexDirection: "row" }}>
                    {
                      showSearch &&
                      <View style={{ zIndex: 10, flexBasis: 15, flexGrow: 20, flexShrink: 5 }}>
                        <InteractiveSearch aiCallback={aiCallback} aiSearch={aiSearch} placeHolder={placeHolder} searchState={searchState} setSearchState={setSearchState} searchParams={searchParams} callback={callback} />
                      </View>
                    }
                  </View>
              }
              {
                customIcons?.length > 0 &&
                <View style={{ zIndex: -1, flexGrow: 1, paddingRight: 15, top: -2, flexShrink: 1, maxWidth: (customIcons?.length * 50) + 50 || undefined }} onLayout={e => setCustomIconWidth(e.nativeEvent.layout.width)}>
                  <ArrayGenerator />
                </View>
              }
              {
                extraComponents &&
                <View >
                  {extraComponents}
                </View>
              }
            </View>
          </View>
          {
            actionButton &&
            <Pressable ref={buttonRef} disabled={searchState ? false : true} onPress={() => { if (action) action() }} style={[styles.centerEverything, { position: 'relative', maxWidth: 40, flex: 1 }]}>
              <Feather name="send" size={24} color={searchState ? "rgb(90,192,234)" : 'grey'} />
            </Pressable>
          } 
        </Animatable.View>
        <Animatable.View transition={'flex'} style={{ right: -30, flex: fetchingState ? 1 : 0.001, justifyContent:"center", alignSelf:"flex-end" }}>
          {
            fetchingState &&
            <LoadingIndicator type={"indeterminate"} color={"rgb(90,192,234)"} />
          }
        </Animatable.View>
      </View>
    )
  }

export default SearchComponent;