import React, { useState, useCallback, useRef, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Pressable } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome, FontAwesome6, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import LottieView from '@utils/lottieAnimation';
import { Audio } from 'expo-av';
import { CHANGE_THEME, STATUSBAR_HEIGHT, SHOW_FEEDBACK_MODAL, SHOW_NOTIFICATIONS_MODAL, HIDE_NOTIFICATIONS_MODAL, TOGGLE_NOTIFICATIONS_PANEL, NEW_NOTIFICATION, CLEAR_NOTIFICATIONS, HIDE_USER_CONTEXT_MODAL, TOGGLE_CONTEXT, CONTEXT_SIZE_TOGGLE, LOGGED_OUT } from '@reduxLocal/actions/types';
import SocketManager from '@utils/socket';
import LanguagePopover from '@navigation/HeaderBar/components/Langauge-popover';
import { DefaultRootState, persistor } from '@reduxLocal/persistState';
import useGlobalUser from '@hooks/getGlobalUser';
import NotificationItem from '@components/modals/NotificationModal/Components/NotificationItem';
import { Badge } from "@mui/material";
import { Appearance } from 'react-native';
const socketManager = new SocketManager('data');
import { useColorScheme } from '../../../App';
import GetAvatarImage from '@hooks/getAvatarImage';
import { Button, H3, Popover, Separator, SizableText, YStack } from 'tamagui';
import { selectUser } from '@reduxLocal/selectors/userSelectors';
import { useNavigation } from '@react-navigation/native';
import apis from '@api/api';


const BORDERS = 20;


const StatusBar = () => {
  const theme = useSelector((state: DefaultRootState) => state.app.theme.lightTheme);
  const notificationCount = useSelector((state: DefaultRootState) => state.notifications.count);
  const notificationVisible = useSelector((state: DefaultRootState) => state.modals.notificationsPanel.modalDisplayed);
  const minimized = useSelector((state: DefaultRootState) => state.UI.contextMinimized);
  const notificationRef = useRef<LottieView>(null);
  const userId = useGlobalUser();
  const toolbarVisible = useSelector((state: DefaultRootState) => state.modals.toolbar.toolbarDisplayed);
  const socket = socketManager.getSocket(userId);
  const user = selectUser();
  const [tempList, setTempList] = useState([]);
  const dispatch = useDispatch();
  const [breakpoint, setBreakPoint] = useState(0);
  const [event, setEvent] = useState([]);
  const { colorScheme, setColorScheme } = useColorScheme();
  const navigation = useNavigation();
  const popRef = useRef<Popover>(null);

  const _handleLogout = useCallback(async () => {
    navigation.replace('LoadingScreen', { params: { flagHold: true } })
    await apis.logoutUser()
      .then(async () => {
        dispatch({ type: LOGGED_OUT }) //Clears redux store
        await persistor.purge().then(() => {
          navigation.replace('Login');
        });
      });
  }, [])

  useEffect(() => {
    notificationRef.current.play(0, 25);
    socket.connect();
    socket.emit('connection');
    return () => socketManager.disconnect();
  }, []);

  const playSound = async () => {
    const { sound } = await Audio.Sound.createAsync(require('@assets/sound/smartsaasping.wav'));
    // await sound.playAsync();
  };

  const eventHandler = useCallback(event => {
    dispatch({ type: NEW_NOTIFICATION, payload: event });
    setTempList(list => [...list, event]);
    playSound();
  }, []);

  useEffect(() => {
    socket.on('event', eventHandler);
    return () => {
      socket.off('event', eventHandler);
    };
  }, [socket, eventHandler]);

  useEffect(() => {
    if (tempList.length) {
      dispatch({ type: SHOW_NOTIFICATIONS_MODAL });

      const processNotifications = async () => {
        for (let i = 0; i < tempList.length; i++) {
          const item = tempList[i];
          setEvent(prevList => [...prevList, item]);
          setTempList(prevTempList => prevTempList.filter(tempItem => tempItem !== item));
          if (i < tempList.length - 1) {
            await new Promise(resolve => setTimeout(resolve, 5000));
          }
        }
      };

      processNotifications().then(() => dispatch({ type: HIDE_NOTIFICATIONS_MODAL }));
    }
  }, [tempList, dispatch]);

  const handleCloseModal = () => {
    tempList.forEach(item => {
      setEvent(prevList => [...prevList, item]);
      setTempList(prevTempList => prevTempList.filter(tempItem => tempItem !== item));
    });
  };


  return (
    <Animatable.View
      transition={['backgroundColor', 'paddingRight']}
      style={[styles.statusBarContainer, { paddingRight: toolbarVisible ? 50 : undefined }]}>
      <View style={{ position: "relative", left: -500, top: -20 }}>
        <NotificationItem item={event[event.length - 1]} />
      </View>
      <View style={styles.innerContainer} onLayout={e => setBreakPoint(e.nativeEvent.layout.width)}>
        <View style={styles.buttonContainer}>
          <View style={styles.iconWrapper}>
            <Animatable.View transition={[]} direction='reverse' style={[styles.iconContainer, { backgroundColor: theme ? "white" : "black" }]}>
              <LanguagePopover />
            </Animatable.View>
          </View>
          <Pressable style={styles.iconWrapper} onPress={() => { notificationRef.current.play(0, 25); if (!notificationVisible) { dispatch({ type: CLEAR_NOTIFICATIONS }) } dispatch({ type: TOGGLE_NOTIFICATIONS_PANEL }); if(!minimized)dispatch({ type: CONTEXT_SIZE_TOGGLE }) }}>
            <Badge overlap="circular" anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} color="error" max={999} badgeContent={notificationCount} >
              <Animatable.View transition={[]} direction='reverse' style={[styles.iconContainer, { backgroundColor: theme ? "white" : "black" }]}>
                <LottieView ProgressBar={0.5} ref={notificationRef} source={require('@assets/animations/19114-notification-bell.json')} resizeMode='contain' style={styles.lottieIcon} loop={false} />
            </Animatable.View>
            </Badge>
          </Pressable>
          <Pressable style={styles.iconWrapper} onPress={() => dispatch({ type: SHOW_FEEDBACK_MODAL })}>
            <Animatable.View transition={[]} direction='reverse' style={[styles.iconContainer, { backgroundColor: theme ? "white" : "black" }]}>
              <LottieView source={require('@assets/animations/emojis/sad-emoji.json')} resizeMode='contain' style={styles.feedbackIcon} loop={false} />
            </Animatable.View>
          </Pressable>
          <Pressable style={styles.iconWrapper} onPress={() => {
            setColorScheme(colorScheme === 'light' ? 'dark' : 'light');
            dispatch({ type: CHANGE_THEME, payload: { lightTheme: !theme, themeColour: theme ? 'light' : 'dark' } })
          }}>
            <Animatable.View transition={[]} direction='reverse' style={[styles.iconContainer, { backgroundColor: theme ? "white" : "black" }]}>
              <MaterialCommunityIcons name={'theme-light-dark'} style={styles.themeIcon} size={30} color={theme ? 'black' : 'white'} />
            </Animatable.View>
          </Pressable>
          <Pressable>
            <Popover placement='bottom' ref={popRef}>
              <Popover.Trigger>
                <Badge overlap="circular" badgeContent={0} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} variant="dot">
                  <GetAvatarImage style={[{ flex: 1, backgroundColor: theme ? 'white' : 'black', margin: 5, minHeight: 40, minWidth: 40, borderRadius: 200, aspectRatio: 1 }]} />
                </Badge>
              </Popover.Trigger>
              <Popover.Content overflow='visible' backgroundColor={"$background"} minWidth={400} style={{ borderRadius: 20, left: -90, shadowRadius: 20, shadowOpacity: .2, gap: 30, alignContent: "stretch", alignItems: "stretch" }}>
                <Popover.Arrow borderWidth={10} borderColor="rgb(90,192,234)" />
                <View style={[styles.userProfileContainer]}>
                  <View style={styles.userNameContainer}>
                    <H3 style={styles.userText}>{user?.name}</H3>
                    <H3 style={styles.userText}>{user?.surname}</H3>
                  </View>
                  <SizableText style={styles.userText}>{user?.email}</SizableText>
                </View>
                <Separator />
                <YStack style={{ flex: 2, gap: 15 }}>
                  <Button color="white" onPress={() => {navigation.navigate('Settings'); popRef.current?.close()}} icon={<FontAwesome name="user" color="white" />}>
                    Profile
                  </Button>
                  <Button color="white" onPress={() => { navigation.navigate("Settings", { screen: "Upgrade" }); popRef.current?.close() }} icon={<FontAwesome name="upload" color="white" />}>
                    Upgrade / Billing
                  </Button>
                  <Button color="white" onPress={() => { navigation.navigate('Settings'); popRef.current?.close() }} icon={<FontAwesome name="gear" color="white" />}>
                    Settings
                  </Button>
                  <Button color="white" onPress={_handleLogout} icon={<MaterialIcons name="logout" color="white" />}>
                    Logout
                  </Button>
                </YStack>
              </Popover.Content>
            </Popover>
          </Pressable>
        </View>
      </View>
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  statusBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flex: 2,
    overflow: 'visible',
    zIndex: 2,
  },
  innerContainer: {
    flex: 7,
    flexShrink: 2.5,
    marginHorizontal: 20,
    paddingHorizontal: 50,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'space-between',
    gap: 10,
  },
  iconWrapper: {
    maxWidth: 50,
  },
  iconContainer: {
    borderRadius: BORDERS,
    elevation: 1,
    backgroundColor: 'white',
    flex: 1,
    margin: 5,
  },
  lottieIcon: {
    flex: 1,
    height: 40,
  },
  feedbackIcon: {
    flex: 1,
    height: 30,
    margin: 5,
  },
  themeIcon: {
    flex: 1,
    alignSelf: 'center',
    elevation: 1,
    borderRadius: 5,
    margin: 5,
  },
  userProfileContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    flexWrap: 'nowrap',
  },
  userNameContainer: {
    flex: 1,
    flexDirection: 'row',
    gap: 5,
  },
  userText: {
    flex: 1,
    fontSize: 14,
    textAlign: 'left',
  },
});

export default StatusBar;
