import { useEffect, useState } from 'react';
import { View, Pressable, Text, Alert } from 'react-native';
import * as WebBrowser from "expo-web-browser";
import { makeRedirectUri, useAuthRequest, useAutoDiscovery } from 'expo-auth-session';
import { MicrosoftIcon } from '@assets/--native';
import React from 'react';
import styles from '@stylesheet';
import { createURL } from 'expo-linking';
import * as Animatable from 'react-native-animatable';

WebBrowser.maybeCompleteAuthSession();

const MicrosoftButton = () => {
  //Google Authentication Vars
  const [token, setToken] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  const discovery = useAutoDiscovery('https://login.microsoftonline.com/cbf48daa-58ec-49a9-9032-8b7077454562/v2.0');

  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: 'd9317384-abec-4b01-bcdd-db0da0803901',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      redirectUri: createURL(''),
    },
    discovery
  );

  useEffect(() => {
    if (response?.type === "success") {
      setToken(response.authentication.accessToken);
      console.log(response);
    }
  }, [token, response]);


  return (
    <Pressable onPress={() => {
      promptAsync().then(res => {
        console.log(res);
      })
    }} style={{ height: 50, maxHeight: 50, flex: 1, elevation: 1, shadowRadius: 10, shadowOpacity: .2, backgroundColor: 'white', borderRadius: 30, flexDirection: 'row', overflow:"hidden", justifyContent: 'center', alignContent: 'center', alignItems: 'stretch', }}>
      {({ hovered }) => (
        <Animatable.View style={{ flex: 1, paddingHorizontal: 10, flexDirection: "row", backgroundColor: hovered ? "rgba(90,192,234,0.5)" : "rgba(0,0,0,0)" }} >
          <View style={[styles.centerEverything, { top: 5, flex: 1, maxWidth: 40 }]}>
            <MicrosoftIcon style={{ left: -10 }} />
          </View>
          <View style={{ flex: 4, justifyContent: 'center', alignItems: 'center', }}>
            <Text adjustsFontSizeToFit style={{ color: 'black', opacity: .7, fontWeight: '600' }}>Sign in with Microsoft</Text>
          </View>
        </Animatable.View>
      )}
    </Pressable>
  );
}

export default MicrosoftButton;