import React, { useState, useEffect } from "react";
import { View } from 'react-native';
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
// import "./PaymentIntent.css";
import apis from '../../../api/api';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

const PaymentIntent = ({ item }) => {
  return (
    <View style={{ flex: 1, alignSelf: "stretch", minWidth: 400 }}>
      <CheckoutForm product={item} />
    </View>
  )
}
export default PaymentIntent;