import React, { useEffect } from "react";
import { View, ScrollView } from "react-native";
import { Text, StyledModal, StyledModalHandle, Title } from "@constants/Themed";
import Markdown from "react-native-markdown-display";
import { rules } from "@utils/stringUtils/markDownRules";
import AnimatedLottieView from '@utils/lottieAnimation';
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "@reduxLocal/persistState";
import { HIDE_UPDATE_MODAL } from "@reduxLocal/actions/types";
import ChangeLogFetcher from "@navigation/logFetch";

const UpdateMessageModal = ({ hasUpdatePending, callback }: { hasUpdatePending: boolean, callback: () => void }) => {
  const updateModal = useSelector((state: DefaultRootState) => state.modals.updateModal.modalDisplayed);
  const modalRef = React.useRef<StyledModalHandle>(null);
  ChangeLogFetcher();
  const queryClient = useQueryClient();
  const changeLogData = queryClient.getQueryData('changeLogData');
  const dispatch = useDispatch();

  useEffect(() => {
    hasUpdatePending && modalRef.current?.openModal();
    console.log(updateModal, 'updateModal');
    if (updateModal) {
      modalRef.current?.openModal?.();
    }
  }, [updateModal])

  return (
    <StyledModal onModalHide={() => {
      callback?.();
      dispatch({ type: HIDE_UPDATE_MODAL })
    }} ref={modalRef} customContent={(
      <View style={{ flex: 1,  overflow:"hidden" }}>
        <AnimatedLottieView autoPlay style={{ flex: 1, width: "100%", height: 150, maxHeight: "25%" }} source={require("@assets/animations/landing_Animation_light.json")} />
        <View style={{ flex: 4, height: 600, gap: 30 }}>
          <Title style={{ height: 40, textAlign: "center", color: "white" }}>Latest update Ahoy!</Title>
          <Text style={{ color: "white", textAlign: "center" }}>We've updated the app with some more features, just for you!</Text>
          <Text style={{ textAlign: "center", color: "white" }}>As always, don't be afraid to reach out for new feature requests, as we love hearing from our customers.</Text>
        </View>
      </View>
    )}>
      <ScrollView style={{ flex: 1, maxWidth: 700, marginVertical: 60 }} contentContainerStyle={{ paddingRight: 20 }}>
        {
          changeLogData &&
          <Markdown rules={rules}>{changeLogData?.toString?.()}</Markdown>
        }
      </ScrollView>
    </StyledModal>
  )
}
export default UpdateMessageModal;