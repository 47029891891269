import React, { useState, useCallback } from 'react';
import { View, Text, Pressable, Platform, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import HorizontalCarousel from '@components/Global/Carousel/HorizontalCarousel';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import { Title } from '@constants/Themed';
import AchievementBackground from '@assets/--native/AchievementBackground';
import { ADD_BASKET_ITEM, CLEAR_BASKET, REMOVE_BASKET_ITEM } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDispatch, useSelector } from 'react-redux';
import styles from '@stylesheet';
import * as Animatable from 'react-native-animatable';
import MaskedView from '@react-native-masked-view/masked-view';
import apis from '@api/api';
import { Card, H3, H4, Paragraph, Separator, SizableStack, SizableText, XStack, YStack } from 'tamagui';

const ProductItem = ({ item, index, type, interaction = false }) => {
  const basketItems = useSelector((state: DefaultRootState) => state.store.basketItems);
  const [fullView, setFullView] = useState(false);
  const dispatch = useDispatch();

  const getDescription = () => {
    //Find any key that contains the word description
    const descriptionKey = Object.keys(item.data).filter(key => /description|desc|product_description|product_desc/i.test(key));
    if (descriptionKey.length > 0) {
      return item.data[descriptionKey[0]];
    }
    else {
      return item.data.product_description || item.data.description || item.description || item.data.product_description || item.data.description || item.data.product_description || item.data.description;
    }
  }

  const _handleAddBasket = useCallback((item) => {
    dispatch({ type: ADD_BASKET_ITEM, payload: item })
  }, []);

  const _removeItemFromBasket = () => {
    console.log(item);
    dispatch({ type: REMOVE_BASKET_ITEM, payload: item })
  }

  const [quantity, setQuantity] = useState(0);

  const checkQuantity = useCallback(() => {
    const itemInBasket = basketItems.filter(basketItem => basketItem._id === item._id);
    if (itemInBasket.length > 0) {
      return itemInBasket[0].basketQuantity;
    }
    else {
      return 0;
    }
  }, [basketItems]);

  return (
    <Animatable.View transition={'height'} style={{ backgroundColor: '#ececec', flex: 1, margin: Platform.OS === 'web' ? 20 : undefined, maxWidth: Platform.select({ web: "30%", default: "100%" }), borderRadius: 30, height: fullView ? 550 : 380, shadowRadius: 20, shadowOpacity: .2, overflow: 'hidden' }}>
      <Card animation={'bouncy'} flex={1}>
        <Card.Header>
          <H3 adjustsFontSizeToFit numberOfLines={1} style={{ minHeight: 30, color: "white" }}>{item.data.product_name || item.data.event_name}</H3>
          <Separator style={{ top: 2 }} borderColor={"white"} />
          <XStack flex={1} minHeight={30} minWidth={"100%"} alignItems={"space-around"} alignContent={"space-around"} alignSelf={"stretch"}>
            <H4 style={{ flex: 1, color: "white", textAlign: 'left' }}>£ {parseFloat(item.data.price || item.data.RRP || item.data.ticket_price || 0).toFixed(2)}</H4>
            <MaskedView
              style={{ top: 10, flex: 1 }}
              maskElement={
                (
                  <View style={{ flex: 1, gap: 10, alignSelf: "flex-end", backgroundColor: "transparent", flexDirection: "row" }}>
                    <FontAwesome name="star" style={{ textAlign: "center" }} size={16} color="white" />
                    <FontAwesome name="star" style={{ textAlign: "center" }} size={16} color="white" />
                    <FontAwesome name="star" style={{ textAlign: "center" }} size={16} color="white" />
                    <FontAwesome name="star" style={{ textAlign: "center" }} size={16} color="white" />
                    <FontAwesome name="star" style={{ textAlign: "center" }} size={16} color="white" />
                  </View>
                )
              }>
              <LinearGradient colors={['yellow', '#ececec20']} start={{ x: 0.5, y: 0.9 }} style={{ minHeight: '100%', minWidth: '100%', opacity: 0.8 }} />
            </MaskedView>
          </XStack>
        </Card.Header>
        <Card.Background>
          <AchievementBackground style={{ margin: -50, position: 'absolute', overflow: 'hidden', opacity: 1, zIndex: -1, transform: [{ scale: 4 }] }} />
        </Card.Background>
        <SizableStack style={{ flex: 5, gap: 10, flexDirection: 'column' }}>
          <View style={{ flex: 7, top: 20, minWidth: "30%", backgroundColor: "white" }}>
            <HorizontalCarousel imageStyle={{ maxHeight: Platform.select({ web: undefined, default: 190 }) }} images={item.dataImages} height={Platform.OS === 'web' ? 150 : 230} />
          </View>
          <View style={{top: 10, borderRadius: 20, minHeight: 30, flex: 2.8, paddingHorizontal: 10, paddingVertical: 4 }}>
            <Paragraph numberOfLines={3} style={{ fontSize: 16, color: "white", textAlign: "left", }}>{getDescription()}</Paragraph>
          </View>
        </SizableStack>
        <View style={{ flex: 1, minWidth: "100%", alignSelf: 'stretch', bottom: 3, position: 'absolute', shadowRadius: 20, shadowOpacity: .2, justifyContent: 'center', borderTopRightRadius: 0, overflow: 'hidden', borderBottomRightRadius: 20, borderBottomLeftRadius: 20, paddingBottom: 5, flexDirection: 'row', maxHeight: 40 }}>
          <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center', minHeight: 50 }]} onPress={(item) => { _removeItemFromBasket(item) }}>
            <Entypo name="squared-minus" size={24} color="white" />
          </Pressable>
          <View style={[styles.centerEverything, { flex: 1, maxHeight: 30 }]}>
            <SizableText style={{ color: "white", fontWeight: 'bold', fontSize: 24, textAlign: 'center', verticalAlign: 'middle', justifyContent: 'center' }}>{checkQuantity()}</SizableText>
          </View>
          <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center', minHeight: 50 }]} onPress={() => { _handleAddBasket(item) }}>
            <Entypo name="squared-plus" size={24} color="white" />
          </Pressable>
        </View>
      </Card>

    </Animatable.View>
  )
}

export default ProductItem;