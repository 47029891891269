import React, { useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import Button from '../UI/Button/Button';

type ImagePickerComponentProps = {
  onImageSelected: (image: string) => void;
  allowMultiple?: boolean;
  selected?: boolean;
};

export type ImagePickerHandle = {
  pickImage: () => void;
}

const ImagePickerComponent = forwardRef<ImagePickerHandle, ImagePickerComponentProps>((props, ref) => {
  const { onImageSelected } = props;

  useImperativeHandle(ref, () => ({
    pickImage: () => handleImagePick(),
  }));

  useEffect(() => {
    if (Platform.OS !== 'web') {
      (async () => {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Camera roll permissions required');
        }
      })();
    }
  }, []);

  const pickImage = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this app to access your photos!");
      return null;
    }

    return await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
      allowsMultipleSelection: props.allowMultiple,
    });
  };

  const handleImagePick = async () => {
    const result = await pickImage();

    if (result && result.assets && result.assets.length > 0) {
      const selectedImages = await Promise.all(
        result.assets.map(async (asset) => {
          try {
            const manipResultImg = await manipulateAsync(asset.uri, [{ resize: { height: 500 } }], {
              format: SaveFormat.PNG,
              compress: 1,
              base64: true,
            });
            return manipResultImg; // Return manipulated image URI
          } catch (error) {
            console.error('Error during image manipulation:', error);
            return null;
          }
        })
      );

      if (typeof onImageSelected === 'function') {
        console.log('callback for image selection', selectedImages);
        // Only call onImageSelected if there are valid images
        const validImages = selectedImages.filter((uri) => uri !== null);
        onImageSelected(validImages);
      }
    } else {
      alert('Upload canceled or no images selected');
    }
  };


  return <Button icon="picture" buttonText={props.selected && props.allowMultiple ? "Change Image" : "Select Image"} onPress={handleImagePick} customStyle={{ minHeight: 40, minWidth: 150, borderRadius: 5 }} />;
});

export default ImagePickerComponent;
