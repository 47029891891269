import React, { useEffect, useState, useCallback, useRef } from 'react';
import { TextInput, Switch, Pressable, ScrollView, Image, Platform, Linking, Alert } from 'react-native';
import { Text, Title, View } from '@constants/Themed';
import DefaultScreen from '@components/Global/DefaultScreenView';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import Button from '@components/UI/Button/Button';
import apis from '@api/api';
import toTitleCase from '@hooks/toTitleCase';
import AnimatedLottieView from '@utils/lottieAnimation';
import { CountUp } from 'use-count-up';
import { SmartSaaSIcon } from '@assets/--native';
import { use } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import { BottomSheetModal, BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import DropDownView from '@components/Global/DropDownView';
import * as Animatable from 'react-native-animatable';
import MapComponent from '@components/Global/MapComponent/MapComponent';
import { Entypo, MaterialIcons } from '@expo/vector-icons';
import * as Contacts from 'expo-contacts';
import { SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import { UserType } from '@types/userTypes';

const UserProfileScreen = ({ route, navigation }) => {
  const userData = useSelector((state: DefaultRootState) => state.promiseData?.data?.userData);
  const theme = themeSelector();
  const [user, setUser] = useState<UserType>({});
  const [showMap, setShowMap] = useState(false);
  const bookingRef = useRef<BottomSheetModal>(null);
  const params = route.params;
  const dispatch = useDispatch();

  const handleBCardRequest = useCallback(async () => {
    if (userData) {
      navigation.navigate('Settings', { screen: 'Profile' })
    } else {
      navigation.navigate('Login')
    }
  }, [userData])

  const handleOpenMap = () => {
    setShowMap(!showMap);
  }

  const handleAddContact = async () => {
    if (!user.profile_information.mobile) return Alert.alert('No mobile number found');

    const contact = {
      [Contacts.Fields.FirstName]: `${toTitleCase(user.profile_information.name)}`,
      [Contacts.Fields.LastName]: `${toTitleCase(user.profile_information.surname)}`,
      [Contacts.Fields.PhoneNumbers]: [
        {
          label: 'mobile',
          number: user.profile_information.mobile, // Fix typo here: 'user.profile_informatio?.mobile' to 'user.profile_information.mobile'
          isPrimary: true,
          digits: "1234567890",
          countryCode: "gb",
        }
      ],
      [Contacts.Fields.Emails]: [
        {
          label: 'email',
          email: user.email,
        },
      ],
      // [Contacts.Fields.Company]: user.profile_information.company,
      // [Contacts.Fields.JobTitle]: user.profile_information.job_title,
      // [Contacts.Fields.Addresses]: [
      //   {
      //     label: 'address',
      //     street: user.profile_information.address,
      //     city: user.profile_information.city,
      //     country: user.profile_information.country,
      //     postalCode: user.profile_information.postal_code,
      //   },
      // ],
    };

    try {
      await Contacts.addContactAsync(contact).then(
        (res) => dispatch({ type: SHOW_AUTH_MODAL, data: { content: `Added ${toTitleCase(user.profile_information.name)} ${toTitleCase(user.profile_information.surname)} to your contacts`, type: 'success' } })
      ).catch(e => {
        Alert.alert('Could not add contact', e.message);
      });
      console.log(contact);
    } catch (e) {
      console.log(e);
    }
  };


  //TODO replace for api route that can monitor stats 
  const getUserById = async (id) => {
    await apis.getUserById(id).then
      (res => {
        setUser(res);
      })
  }

  useEffect(() => {
    if (params.userId) {
      getUserById(params.userId);
    }
  }, [route])
  //TODO get profile settings from user

  const generateSocials = () => {

    return (
      <>
      </>
    )
  }


  return (
    <View style={{ flex: 1, paddingTop: 30, maxWidth: 600, alignSelf: 'center' }}>
      <View style={{ gap: 15, flexDirection: 'row', height: 60, position: "absolute", top: Platform.OS == 'web' ? 0 : 50, paddingHorizontal: 20, flex: 1, alignContent: 'stretch', alignItems: "stretch", minWidth: '100%' }}>
        {
          !userData &&
          <View style={{ flex: 1, backgroundColor: 'rgb(90,192,234)', borderRadius: 20, minWidth: 150, height: 50 }}>
            <Pressable onPress={() => handleBCardRequest()} style={{ flexDirection: 'row' }}>
                <AnimatedLottieView key={2} loop={false} style={[{ width: 50, alignSelf: 'center' }]} source={require('@assets/animations/star.json')} autoPlay ></AnimatedLottieView>
              <Text style={{ flex: 1, paddingVertical: 7 }}>Make your own business card</Text>
            </Pressable>
          </View>
        }
        {
          Platform.OS != 'web' &&
          <View style={{ flexDirection: 'row', gap: 15, flex: 1, minWidth: 150, justifyContent: 'flex-end' }}>
            <SmartSaaSIcon />
            <Title style={{ top: 5 }}>SmartSaaS</Title>
          </View>
        }
      </View>
      <DefaultScreen scrollDisabled style={{ flex: 1, width: '105%', paddingTop: 65 }}>
        <ScrollView style={{ flex: 1, borderRadius: 20, backgroundColor: theme ? '#ececec' : '#333333', padding: 15 }} contentContainerStyle={{ paddingBottom: 150 }}>
          {
            user?._id ?
              <View style={{ backgroundColor: 'transparent', gap: 15 }}>
                <View style={{ flex: 1, backgroundColor: 'transparent', flexDirection: "row", gap: 15, minHeight: '20%' }}>
                  <View style={{ flex: 2, borderRadius: 20, padding: 10 }}>
                    {
                      user.profile_information.avatar?.img
                        ?
                        <View style={{ backgroundColor: 'transparent', flex: 3, alignContent: "center", justifyContent: 'center' }}>
                          <Image key={user.profile_information.avatar.img} source={{ uri: user.profile_information.avatar.img }} style={{ backgroundColor: '#ffffff66', shadowRadius: 20, alignSelf: "center", shadowOpacity: .4, flex: 1, borderRadius: 20, aspectRatio: 1 }} />
                        </View>
                        :
                        <Text adjustsFontSizeToFit numberOfLines={2} style={{ textAlign: 'center', marginHorizontal: 10, color: "white" }}>Tap here to upload your profile image</Text>
                    }
                    <Title style={{ textAlign: 'center' }}>{toTitleCase(user?.profile_information.name)} {toTitleCase(user?.profile_information.surname)}</Title>
                    <Text style={{ textAlign: 'center' }}>{user?.email}</Text>
                  </View>
                  <View style={{ padding: 10, paddingBottom: 10, flex: 1, borderRadius: 20 }}>
                    <AnimatedLottieView autoPlay loop={false} style={{ flex: 2, height: Platform.OS === 'web' ? '100%' : '10%' }} source={require('@assets/animations/107653-trophy.json')} />
                    <Text style={{ fontSize: 30, flex: 3, color: theme ? 'black' : 'white', alignSelf: 'center', paddingTop: 10 }}>
                      <CountUp isCounting={true} start={0} end={user.achievements?.achievement_points} />
                      <Text adjustsFontSizeToFit style={{ fontSize: 12 }}>pts</Text>
                    </Text>
                  </View>
                </View>
                <ScrollView horizontal contentContainerStyle={{ gap: 15 }} style={{ borderRadius: 20, height: 80, padding: 10, backgroundColor: theme ? 'white' : 'black', maxHeight: 80 }}>
                  <Pressable
                    onPress={() => { Linking.openURL(`tel:${user.profile_information.phone || user.profile_information.mobile}`) }}
                    style={{ flex: 2, padding: -5, height: 60, width: 60, borderRadius: 150, overflow: 'visible', backgroundColor: theme ? '#58BFEA' : '#3a8099' }}>
                    <AnimatedLottieView key={2} loop={true} style={[{ width: '100%', alignSelf: 'center' }]} source={require('@assets/animations/85569-phone-call.json')} autoPlay ></AnimatedLottieView>
                  </Pressable>
                  <Pressable
                    onPress={() => { Linking.openURL(`mailto:${user.email}`) }}
                    style={{ flex: 2, padding: -5, height: 60, width: 60, borderRadius: 150, overflow: 'visible', backgroundColor: theme ? '#58BFEA' : '#3a8099' }}>
                    <AnimatedLottieView key={2} loop={true} style={[{ width: '90%', alignSelf: 'center', top: '1%' }]} source={require('@assets/animations/email.json')} autoPlay ></AnimatedLottieView>
                  </Pressable>
                  {
                    user.profile_information.website &&
                    <Pressable
                      onPress={() => { Linking.openURL(`${user.profile_information.website}`) }}
                      style={{ flex: 2, padding: -5, height: 60, width: 60, borderRadius: 150, overflow: 'visible', backgroundColor: theme ? '#58BFEA' : '#3a8099' }}>
                        <AnimatedLottieView key={2} loop={false} style={[{ width: '90%', alignSelf: 'center', top: '1%' }]} source={require('@assets/animations/wired-flat-27-globe.json')} autoPlay ></AnimatedLottieView>
                    </Pressable>
                  }
                  <Pressable
                    onPress={() => { handleOpenMap() }}
                    style={{ flex: 2, padding: -5, height: 60, width: 60, borderRadius: 150, overflow: 'visible', backgroundColor: theme ? (showMap ? 'green' : '#58BFEA') : (showMap ? 'green' : '#3a8099') }}>
                    <AnimatedLottieView key={2} loop={true} style={[{ width: '100%', alignSelf: 'center' }]} source={require('@assets/animations/pin.json')} autoPlay ></AnimatedLottieView>
                  </Pressable>
                  <Pressable
                    onPress={() => { navigation.replace('Store', { screen: 'StoreLoading' }) }}
                    style={{ flex: 2, padding: -5, height: 60, width: 60, borderRadius: 150, overflow: 'visible', backgroundColor: theme ? '#58BFEA' : '#3a8099' }}>
                    <AnimatedLottieView key={2} loop={false} style={[{ width: '150%', alignSelf: 'center', top: '-7%' }]} source={require('@assets/animations/78018-store-icon.json')} autoPlay ></AnimatedLottieView>
                  </Pressable>
                </ScrollView>
                {
                  showMap &&
                  <Animatable.View animation={'fadeInDown'} style={{ overflow: 'hidden', padding: 10, borderRadius: 20, height: 200, backgroundColor: theme ? 'white' : 'black' }}>
                    <MapComponent providedLocation={undefined} />
                  </Animatable.View>
                }
                <DropDownView style={{ borderRadius: 20 }} cardTitle={(<Title>About me</Title>)} hideInitial>
                  <ScrollView nestedScrollEnabled style={{ backgroundColor: theme ? 'white' : 'black', margin: -10, marginHorizontal: -20, padding: 10 }}>
                    <Text>
                      {user.profile_information.summary}
                    </Text>
                  </ScrollView>
                </DropDownView>
                <DropDownView cardTitle={(<Title>Social Media</Title>)} hideInitial>
                  <ScrollView nestedScrollEnabled style={{ backgroundColor: theme ? 'white' : 'black', margin: -10, marginHorizontal: -20, padding: 10 }}>
                    {generateSocials()}
                  </ScrollView>
                </DropDownView>
                <View style={{ flexDirection: 'row', height: 200, gap: 15, backgroundColor: 'transparent' }}>
                  <Button buttonText={'Add to contacts'} icon={<MaterialIcons name="contact-phone" size={40} color="black" />} onPress={async () => handleAddContact()} portrait defaultColor='#346be9' customStyle={{ minHeight: 200, width: '100%', borderRadius: 10, marginRight: 20 }} />
                  <View style={{ flex: 1, gap: 15, backgroundColor: 'transparent' }}>
                    <Button buttonText={'Book meeting'} icon={<Entypo name="calendar" size={24} color="black" />} onPress={() => { bookingRef.current?.present() }} portrait defaultColor='#346be9' customStyle={{ minHeight: '100%', minWidth: '100%', borderRadius: 10 }} />
                    <Button buttonText={'Add to SmartSaaS'} icon={(<SmartSaaSIcon />)} portrait onPress={() => getUserById(params.userId)} defaultColor='#346be9' customStyle={{ minHeight: '100%', minWidth: '100%', borderRadius: 10 }} />
                  </View>
                </View>
              </View>
              :
              <View style={{ flex: 1, margin: 10, backgroundColor: 'transparent' }}>
                <Title style={{ textAlign: 'center' }}>Oops!</Title>
                <Title style={{ textAlign: 'center' }}>No user found {'\b'} {'\n'}</Title>
                <Button buttonText={'Back'} onPress={() => navigation.navigate('Login')} customStyle={{ minWidth: 220 }} />
                {
                  __DEV__ &&
                  <Button buttonText={'Refresh'} onPress={() => getUserById(params.userId)} customStyle={{ minWidth: 220 }} />
                }
              </View>
          }
        </ScrollView>
        <View style={{ position: 'absolute', bottom: 0, backgroundColor: 'transparent', minWidth: '100%' }}>
          <Button buttonText={'Back to SmartSaaS'} icon={'back'} onPress={() => navigation.navigate('Login')} customStyle={{ minHeight: 60, paddingBottom: 10, minWidth: '100%', alignSelf: 'stretch' }} />
        </View>
      </DefaultScreen>
      <BottomSheetModalProvider>
        <BottomSheetModal ref={bookingRef} snapPoints={['10%', '69%']} style={{ marginHorizontal: '3%' }}>
          <View style={{ flex: 1, padding: 10 }}>
            {/* <CalendarComponent calendarId /> */}
          </View>
        </BottomSheetModal>
      </BottomSheetModalProvider>
    </View>
  )
}
export default UserProfileScreen;