import { WEATHER_API_KEY, USER_API_BASE_URL, NEWS_API_KEY, COMPANIESHOUSE_API_KEY, LINKEDIN_CLIENT_ID, SESSION_API } from './constant';
import { AVATAR_UPDATED, GET_ERRORS, USER_LOADING, NEW_USER_ADDED, DATA_QUERY, LOGGED_OUT, SUCCESS, HISTORY_RETRIEVED, WEATHER_RETRIEVED, DOWNLOAD_AVATAR, UPDATE_GOOGLE_MAIL_TOKEN, SHOW_AUTH_MODAL, FAILED, REQUEST, REGISTER_SUCCESS, DEFAULT_AVATAR, NEWS_UPDATED, HIDE_LOCK_MODAL, SHOW_REGISTER_MODAL, GOOGLE_AUTH_FAILED, MAIL_RETRIEVED, UPDATE_MAIL_COUNT, UPDATE_ACHIEVEMENTS, BACK_TO_LOGIN, SWITCH_COMPANY_FAILED, SWITCH_COMPANY_SUCCESSFUL, HIDE_PASSWORD_RESET_MODAL, UPDATE_PRODUCTS, HIDE_LOADING_MODAL, SHOW_LOADING_MODAL, PENDING_REFRESH, SET_STICKY_NOTES, SHOW_OTP, UPDATED_INVITATIONS, UPDATED_MESSAGES, UPDATED_CALENDAR_ENTRIES, HIDE_ALL_MODALS, HANDLE_FAVOURITE, REFRESH, UPDATE_MAIL_ACCOUNT, CLEAR_MAIL_AUTH, UPDATE_GOOGLE_ACCOUNT, REMOVE_INTEGRATION } from "@reduxLocal/actions/types";
import { store } from '@reduxLocal/persistState';
import { PURGE } from 'redux-persist';
import axios from 'axios';
import Constants from 'expo-constants';
import getGlobalUser from '@hooks/getGlobalUser';
import { GoogleCalTypes } from '../src/components/Global/types';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { saveLog } from '@reduxLocal/persistState';
import { addToQueue, getQueue, clearQueue, removeProcessedRequest } from './utils/requestQueue';
import { monitorNetworkStatus } from './utils/networkManager';

//TODO create decentralised redux dispatches for all types
//TODO remove named exports or remove default exports!

let isOnline = true;

// Monitor network status
monitorNetworkStatus((status) => {
	isOnline = status;
	if (isOnline) {
		replayQueue();
	}
});

function dispatch(action) {
	store.dispatch(action)
}

const sessionApi = axios.create({
	withCredentials: true,
	baseURL: SESSION_API,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
})

const api = axios.create({
	withCredentials: true,
	baseURL: USER_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
})

const noCreds = axios.create({
	baseURL: USER_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
})

const monitoredRequest = axios.create({
	withCredentials: true,
	baseURL: USER_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
})

const logOut = axios.create({
	withCredentials: true,
	baseURL: USER_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	},
	timeout: 5000
})

const monitorFail = axios.create({
	withCredentials: true,
	baseURL: USER_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
})

const noMonitor = axios.create({
	withCredentials: true,
	baseURL: USER_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	},
	timeout: 10000
});

noMonitor.interceptors.request.use(async function (config) {
	const log = {
		type: 'API_REQUEST',
		method: config.method,
		url: config.url,
		timestamp: new Date().toISOString(),
	};
	if (Platform.OS === 'web') {
		await saveLog(log);
	}
	return config;
}, function (error) {
	dispatch({ type: HIDE_ALL_MODALS })
	setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: `${error.response.data.error}`, type: 'error' } }), 1000)
	return Promise.reject(error)
})

//TODO create decentralised redux dispatches for all types

api.interceptors.request.use(async function (config) {
	const log = {
		type: 'API_REQUEST',
		method: config.method,
		url: config.url,
		timestamp: new Date().toISOString(),
	};
	if (Platform.OS === 'web') {
		await saveLog(log);
	}
	if (!isOnline) {
		await addToQueue(config);
		return Promise.reject({ message: 'Request queued. Offline mode.' });
	}
	return config;
}, function (error) {
	dispatch({ type: HIDE_ALL_MODALS })
	setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: `${error.response.data?.error}`, type: 'error' } }), 1000)
	return Promise.reject(error)
})

monitorFail.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	dispatch({ type: HIDE_ALL_MODALS })
	if (error.response.status === 402) {
		dispatch({ type: "PAYMENT_REQUIRED" });
	}
	if (error && error.response) {
		console.error('some error', error);
		setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: `Error: ${error.response.data?.error}`, type: 'error', details: error.response.data?.details || undefined } }), 100)
	} else {
		setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Error', type: 'error' } }), 100)

	}
	return Promise.reject(error)
})

monitoredRequest.interceptors.request.use(async function (config) {
	const log = {
		type: 'API_REQUEST',
		method: config.method,
		url: config.url,
		timestamp: new Date().toISOString(),
	};
	if (Platform.OS === 'web') {
		await saveLog(log);
	}
	if (!isOnline) {
		await addToQueue(config);
		return Promise.reject({ message: 'Request queued. Offline mode.' });
	}
	dispatch({ type: SHOW_LOADING_MODAL });
	return config;
}, function (error) {
	dispatch({ type: HIDE_ALL_MODALS })
	setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: `${error.response.data.error}`, type: 'error' } }), 1000)
	return Promise.reject(error)
})

monitoredRequest.interceptors.response.use(function (response) {
	const message = response.data.message;
	dispatch({ type: HIDE_LOADING_MODAL })
	setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: message, type: 'success' } }), 1000);
	console.log(response);
	return response;
}, function (error) {
	console.error(error);
	dispatch({ type: HIDE_ALL_MODALS })
	setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: `${error.response?.data?.error || "Path not found"}`, type: 'error', details: error.response?.data?.details || undefined } }), 1000)
	return Promise.reject(error)
}
);

api.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error) {
		dispatch({ type: HIDE_ALL_MODALS })
		setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: `${error?.response?.error}`, type: 'error', details: error?.response?.data?.details || undefined } }), 300)
		return Promise.reject(error)
	}
}
);

// Replay queued requests
const replayQueue = async () => {
	const queue = await getQueue();

	for (const request of queue) {
		try {
			await axios(request); // Replay the request
			await removeProcessedRequest();
		} catch (err) {
			console.error('Failed to process queued request:', err);
			break;
		}
	}
};


//External APIs______________________________________________________________________________________________________________________________________________
const weatherApi = axios.create({
	baseURL: 'https://api.openweathermap.org/data/2.5',
	withCredentials: false
})


const options = {
	method: 'GET',
	url: 'https://newsapi.org/v2/top-headlines?country=gb&apiKey=97b1c15122cc4974baa5b31c69a08490'
};

const companiesHouseAPI = axios.create({
	baseURL: 'https://api.company-information.service.gov.uk',
	auth: {
		username: COMPANIESHOUSE_API_KEY,
		password: ''
	}
})

const linkedInAPI = axios.create({
	baseURL: 'https://www.linkedin.com/oauth/v2',
	params: {
		response_type: 'code',
		client_id: LINKEDIN_CLIENT_ID,
		redirect_uri: 'https://smartsaas.pro',
		scope: 'r_liteprofile%20r_emailaddress%20w_member_social'
	}
})

// const quoteAPI = axios.create({
//     baseURL: 'https://zenquotes.io/api'
// })

export const authoriseLinkedIn = async () => {
	await linkedInAPI
		.get('/authorization').then(res => console.log(res))
};

export const getRemainingProfile = async () => {
	try {
		const invitations = await noMonitor.get('/login/get-new-invitations');
		console.log(invitations.data);

		dispatch({ type: UPDATED_INVITATIONS, payload: invitations.data.count });

		const messages = await noMonitor.get('/login/get-new-messages');

		dispatch({ type: UPDATED_MESSAGES, payload: messages.data.count });

		const calendarEntries = await noMonitor.get('/login/get-new-calendar-entries');

		dispatch({ type: UPDATED_CALENDAR_ENTRIES, payload: calendarEntries.data.count });

		return true;
	} catch (err) {
		throw err; // Re-throw the error to be caught by the caller if needed.
	}
};

function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}


// export const quoteToday = async () => {
//     await quoteAPI
//         .get('/today')
// }

export const getCompanyInformation = async (companyNumber) => {
	await companiesHouseAPI
		.get(`/company/${companyNumber}`)
}


export const getCompanyInformationName = async (company) => {
	await companiesHouseAPI
		.get(`/search/companies`, { params: { q: company } })
		.then(res => console.log(res))
}

//Obtain weather information
export const getLocalWeather = async (userLocation) => {
	if (!userLocation) {
		return
	}
	await weatherApi
		.get(`/weather?q=${userLocation}&appId=${WEATHER_API_KEY}&units=metric`)
		.then(res => dispatch({ type: WEATHER_RETRIEVED, payload: res.data }))
		.catch(err => console.log(err))
}

export const refreshUser = async () => {
	await noMonitor.get('/user/refresh-user').then(res => {
		console.log(res);
		dispatch({ type: REFRESH, payload: res.data.data })
	})
}

export const getLocalNews = async (language, country) => {
	await axios
		.request(options)
		.then(res => { dispatch({ type: NEWS_UPDATED, payload: res.data }) })
		.catch(err => console.log(err))
}

function newAbortSignal(timeoutMs) {
	const abortController = new AbortController();
	setTimeout(() => abortController.abort(), timeoutMs || 0);

	return abortController.signal;
}

export const getSession = async (id: Object) => {
	return await sessionApi.post(null, id, {
		signal: newAbortSignal(3000)
	});
}

//Google APIs______________________________________________________________________________________________________________________________________________
const googleAnalyticsAPI = axios.create({
	baseURL: 'https://analyticsdata.googleapis.com/v1beta/properties',
})

const googleMailApi = axios.create({
	baseURL: 'https://gmail.googleapis.com'
})

googleMailApi.interceptors.response.use(function (response) {
	return response;
}, async function (error) {
	if (error.response.status === 401) {
		await renewToken().then((res) => {
		}).catch(e => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to re-authenticate Google Account, services such as Google Mail and Google Drive may not be available. \n Try signing in again', type: 'error' } });
		})
	}
	return Promise.reject(error)
}
);

googleAnalyticsAPI.interceptors.response.use(function (response) {
	return response;
}, async function (error) {
	if (error.response.status === 401) {
		await renewToken().then((res) => {
		}).catch(e => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to re-authenticate Google Account, services such as Google Mail and Google Drive may not be available. \n Try signing in again', type: 'error' } });
		})
	}
	return Promise.reject(error)
}
);

const googleMaps = axios.create({
	baseURL: 'https://maps.googleapis.com/maps/api'
})

const googleMailCount = axios.create({
	baseURL: 'https://gmail.googleapis.com',
})

const googleAPI = axios.create({
	baseURL: 'https://www.googleapis.com/'
})

const googleMailBatch = axios.create({
	baseURL: 'https://www.googleapis.com/batch',
	headers: {
		'Content-Type': 'multipart/mixed; boundary="foo_bar"'
	}
})

const googleOAuthEndpoint = axios.create({
	baseURL: 'https://oauth2.googleapis.com/token'
})

const googleDriveAPi = axios.create({
	baseURL: 'https://www.googleapis.com/drive/v3'
})

googleDriveAPi.interceptors.response.use(function (response) {
	return response;
}, async function (error) {
	if (error.response.status === 401) {
		await renewToken().then((res) => {
		}).catch(e => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to re-authenticate Google Account, services such as Google Mail and Google Drive may not be available. \n Try signing in again', type: 'error' } });
		})
	}
	return Promise.reject(error)
}
);

const googleSendApi = axios.create({ baseURL: 'https://www.googleapis.com/upload/gmail/v1/users/me/messages/send' })

const renewToken = async () => {
	await noMonitor.get('/google/refresh-token').then(res => {
		if (res.status === 200) {
			dispatch({ type: UPDATE_GOOGLE_MAIL_TOKEN, payload: res.data.newToken })
		}
	})
}

export const getDefaultWebStats = async (accessToken, payload, propertyId, reportType) => {
	const headers = {
		"Authorization": `Bearer ${accessToken}`,
		"Content-Type": "application/json"
	}
	const googleWebAnalytics = await googleAnalyticsAPI
		.post(`/${propertyId}:${reportType}`, payload, { headers })
		.then(res => {
			if (res.status != 200) {
				dispatch({ type: GOOGLE_AUTH_FAILED })
			}
			return res
		}).catch()
	return googleWebAnalytics.data.rows
}

//GMAIL parse function
async function parseBatchResponse(response) {
	// Not the same delimiter in the response as we specify ourselves in the request,
	// so we have to extract it.
	const delimiter = response.substr(0, response.indexOf('\n'));
	const parts = response.split(delimiter);
	// The first part will always be an empty string. Just remove it.
	parts.shift();
	// The last part will be the "--". Just remove it.
	parts.pop();
	const result = [];
	for (let i = 0; i < parts.length; i++) {
		let part = parts[i];
		let p = part.substring(part.indexOf("{"), part.lastIndexOf("}") + 1);
		try {
			result.push(JSON.parse(p));
		} catch (e) {
			continue;
		}
	}
	return result;
}

export const searchMail = async (accessToken, query, filter) => {
	let dateFilter = ''; // Initialize date filter string

	// Check if filter object has date information
	if (filter && filter.startDate && filter.endDate) {
		// Format dates in RFC 3339 format

		// Add date filter to query string
		dateFilter = `after:${filter.startDate} before:${filter.endDate}`;
	}

	// Concatenate date filter with original query
	const fullQuery = `${query} ${dateFilter}`;
	return await googleMailApi.get(`/gmail/v1/users/me/messages?q=${fullQuery}&access_token=${accessToken}`)
		.then(async res => {
			let boundary = 'foo_bar'
			let messageIdArr = res.data.messages;
			let header = '';
			messageIdArr.forEach((element: { id: any; }) => {
				header += '\n' + `--${boundary}` + '\n' + `Content-Type: application/http` + '\n' + '\n' + `GET /gmail/v1/users/me/messages/${element.id}?access_token=${accessToken}`
			});
			header += '\n' + `--${boundary}--`;
			return googleMailBatch.post(`/gmail/v1`, header)
				.then(async res => {
					const parsedResponse = await parseBatchResponse(res.data);
					// dispatch({ type: MAIL_RETRIEVED })
					return parsedResponse;
				})
		})
}

export const geoCodeAddress = async (address) => {
	const url = '/geocode/json';
	const params = {
		address: address,
		key: 'AIzaSyAznGh_h4mH0meG5V6hlTlLVhVithzlUJM'
	}
	try {
		const response = await googleMaps.get(url, { params });
		return response;
	} catch (error) {
		console.error("Error fetching data from Google Maps API", error);
		throw error;
	}
}

export const googleMapSearch = async (query) => {
	const url = 'https://places.googleapis.com/v1/places:searchText';
	const headers = {
		'Content-Type': 'application/json',
		'X-Goog-Api-Key': 'AIzaSyAznGh_h4mH0meG5V6hlTlLVhVithzlUJM',
		'X-Goog-FieldMask': 'places.businessStatus,places.iconBackgroundColor,places.types,places.iconMaskBaseUri,places.rating,places.displayName,places.formattedAddress,places.websiteUri'
	};
	const data = {
		textQuery: query
	}
	try {
		const response = await axios.post(url, data, { headers });
		return response.data;
	} catch (error) {
		console.error("Error fetching data from Google Maps API", error);
		throw error;
	}
}


export const requestNextPage = async (accessToken, nextPageToken) => {
	return await googleMailApi.get(`/gmail/v1/users/me/messages?access_token=${accessToken}&pageToken=${nextPageToken}`)
		.then(async res => {
			console.log(res.data, 'page token');
			let boundary = 'foo_bar'
			let messageIdArr = res.data.messages;
			let pageToken = res.data.nextPageToken;
			let header = '';
			messageIdArr.forEach((element: { id: any; }) => {
				header += '\n' + `--${boundary}` + '\n' + `Content-Type: application/http` + '\n' + '\n' + `GET /gmail/v1/users/me/messages/${element.id}?access_token=${accessToken}`
			});
			header += '\n' + `--${boundary}--`;
			return googleMailBatch.post(`/gmail/v1`, header)
				.then(async res => {
					const parsedResponse = await parseBatchResponse(res.data);
					dispatch({ type: MAIL_RETRIEVED })
					return { messages: parsedResponse, nextPageToken: pageToken };
				})
		})
}

export const requestLastPage = async (accessToken, lastPageToken) => {
	return await googleMailApi.get(`/gmail/v1/users/me/messages?access_token=${accessToken}&pageToken=${lastPageToken}`)
		.then(async res => {
			console.log(res.data, 'page token');
			let boundary = 'foo_bar'
			let messageIdArr = res.data.messages;
			let pageToken = res.data.nextPageToken;
			let header = '';
			messageIdArr.forEach((element: { id: any; }) => {
				header += '\n' + `--${boundary}` + '\n' + `Content-Type: application/http` + '\n' + '\n' + `GET /gmail/v1/users/me/messages/${element.id}?access_token=${accessToken}`
			});
			header += '\n' + `--${boundary}--`;
			return googleMailBatch.post(`/gmail/v1`, header)
				.then(async res => {
					const parsedResponse = await parseBatchResponse(res.data);
					dispatch({ type: MAIL_RETRIEVED })
					return { messages: parsedResponse, nextPageToken:pageToken };
				})
		})
}

export const searchGmail = async (accessToken, query) => {
	return await googleMailApi.get(`/gmail/v1/users/me/messages?q=${query}&access_token=${accessToken}`)
		.then(res => {
			let boundary = 'foo_bar'
			let messageIdArr = res.data.messages;
			let header = '';
			messageIdArr.forEach((element: { id: any; }) => {
				header += '\n' + `--${boundary}` + '\n' + `Content-Type: application/http` + '\n' + '\n' + `GET /gmail/v1/users/me/messages/${element.id}?access_token=${accessToken}`
			});
			header += '\n' + `--${boundary}--`;
			return googleMailBatch.post(`/gmail/v1`, header)
				.then(async res => {
					const parsedResponse = await parseBatchResponse(res.data);
					dispatch({ type: MAIL_RETRIEVED })
					return parsedResponse;
				})
		})
}

export const getAllGmail = async (gId, accessToken, tag) => {
	return await googleMailApi.get(`/gmail/v1/users/me/messages?access_token=${accessToken}`, { params: tag ? { q: `label:${tag}` } : {} })
		.then(res => {
			let boundary = 'foo_bar'
			let messageIdArr = res.data.messages;
			let nextPageToken = res.data.nextPageToken;
			let estimateSize = res.data.resultSizeEstimate;
			let header = '';
			try {
				messageIdArr.forEach((element: { id: any; }) => {
					header += '\n' + `--${boundary}` + '\n' + `Content-Type: application/http` + '\n' + '\n' + `GET /gmail/v1/users/me/messages/${element.id}?access_token=${accessToken}`
				});
				header += '\n' + `--${boundary}--`;
				return googleMailBatch.post(`/gmail/v1`, header)
					.then(async res => {
						const parsedResponse = await parseBatchResponse(res.data);
						dispatch({ type: MAIL_RETRIEVED })
						return { messages: parsedResponse, nextPageToken, estimateSize };
					})
			} catch (e) {
				console.log(e);
				return { messages: [], nextPageToken, estimateSize };
			}
		})
}

export const sendGoogleMail = async (token: string, mail: {}) => {
	const headers = {
		'Authorization': `Bearer ${token}`,
		'Content-Type': "message/rfc822"
	}
	return await googleMailCount.post(`/gmail/v1/users/me/messages/send`, mail, { headers })
}

export const markRead = async (mailId: string, accessToken: string) => {

	const headers = {
		'Authorization': `Bearer ${accessToken}`,
		'Content-Type': 'application/json'
	};

	return await googleMailCount.post(`/gmail/v1/users/me/threads/${mailId}/modify`, { addLabelIds: [], removeLabelIds: ['UNREAD'] }, { headers }).then(res => { return res })
}

export const refreshGoogleAccess = async (refreshToken, userId, urlScheme) => {
	return await googleOAuthEndpoint.post(``, { "client_secret": "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", "client_id": "530616870665-sn6qm81jg6eh4pr6dm2ov2n6guovrgna.apps.googleusercontent.com", "refresh_token": refreshToken, "grant_type": 'refresh_token' })
		.then(res => { 
			dispatch({ type: UPDATE_GOOGLE_ACCOUNT, payload: res.data })
			return res.data
		 })
		.catch(e => {
			console.error(e);
			dispatch({ type: CLEAR_MAIL_AUTH })
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: "Failed to re-authenticate Google Account, services such as Google Mail and Google Drive may not be available. \n Try signing in again by going to the mail screen", type: 'error' } });
			return false
		})
}

export const googleOAuth = async (googleAuth) => {
	return await monitoredRequest.post('/google/code', googleAuth)
}

export const getGmailCount = async (gId, accessToken) => {
	if (!gId || !accessToken) {
		throw new Error('No user ID or access token provided');
	}
	const headers = {
		'Authorization': `Bearer ${accessToken}`,
		'Content-Type': 'application/json'
	};
	return await googleMailApi.get(`/gmail/v1/users/${gId}/labels/INBOX?access_token=${accessToken}`, { headers: headers })
		.catch(err => {
			console.error('error in getting gmail count', err)
			return false
		})
}

export const getGDriveItemsList = async (accessToken: string) => {
	const header = { 'authorization': 'Bearer ' + accessToken }
	return await googleDriveAPi.get(`/files`, { headers: header })
		.then(res => {
			if (res.status != 200) {
				console.log('Failed to retrieve Google Drive File List')
			}
			else
				return res
		})
		.catch(err => {
			console.log(err)
		})
}

export const getGDriveFolderContent = async (accessToken: string, file: string) => {
	const header = { 'authorization': 'Bearer ' + accessToken }
	return await googleDriveAPi.get(`/files?q=%27${file}%27%20in%20parents`, { headers: header })
		.then(res => {
			if (res.status != 200) {
				console.log('Failed to retrieve Google Drive File List')
			} else
				return res
		})
		.catch(err => {
			console.log(err)
		})
}


export const getGDriveItem = async (accessToken: string, file: string, mimeType: string) => {
	const header = { 'authorization': 'Bearer ' + accessToken }
	return await googleDriveAPi.get(`/files/${file}`, { headers: header, params: { alt: 'media' } })
		.then(res => {
			if (res.status != 200) {
				console.log(`Failed to retrieve item "${file}"`)
			}
			return res
		})
		.catch(err => console.log(err))
}

//Get list of calendar events


export const getGoogleCalendarList = async (accessToken: string, calendarIds: string[], params: GoogleCalTypes) => {
	const header = { 'authorization': 'Bearer ' + accessToken };


	const responses = await Promise.all(
		calendarIds.map(async calendarId => {
			try {
				const response = await googleAPI.get(`/calendar/v3/calendars/${calendarId}/events`, { headers: header, params: params });
				return response.data;
			} catch (error) {
				console.error(`Error fetching events for calendar ID ${calendarId}:`, error);
				return null; // Return null for failed requests
			}
		})
	);

	return responses;
}



//Stripe APIs
//Generate Stripe APIs

export const createPaymentIntent = async (body) => {
	return await api.post(`/payments/create-payment-intent`, body, { withCredentials: true })
		.then(res => {
			if (res.status != 200) {
				console.log('FAILED!!', res)
			}
			else return res
		})
		.catch((err) => {
			console.log(err)
		})
}
//Internal APIs______________________________________________________________________________________________________________________________________________
//Retrieve user query history
export const retrieveQueriesByID = async (id) => {
	await api.get(`/userActionHistory/${id}`, { withCredentials: true })
		.then(res => {
			const queryHistory = res.data.data;
			dispatch({ type: HISTORY_RETRIEVED, payload: queryHistory });
		})
		.catch(err => (dispatch({ type: GET_ERRORS, payload: err?.response || 'Query history not retrieved' })))
}
export const insertQueryHistory = (id, payload) => { api.put(`/userActionHistory/${id}`, payload) }

//Get User Queries
export const quoteToday = () => api.get('/quotes')
export const getSystemNotes = pco => api.get(`/systems/${pco}`)
export const addTwofa = (id: String, payload) => api.post(`/${id}/add2fa`, payload, { withCredentials: true }).catch(err => dispatch({ type: GET_ERRORS, payload: err.response }))



export const retrieveData = responseData => {
	return {
		type: DATA_QUERY,
		payload: responseData
	};
};

//Check if company exists
export const companyCheck = async (companyData, formValues) => {
	await noCreds.post('/companyCheck', companyData)
		.then(async (res) => {
			if (res.status == 204) {
				await noCreds.get('/get-product-list').then(res => {
					dispatch({ type: UPDATE_PRODUCTS, payload: res.data.data })
				})
					.catch(e => {
						dispatch({ type: SHOW_AUTH_MODAL, data: { content: e.data.data, type: 'error' } })
					});
				dispatch({ type: SHOW_REGISTER_MODAL, payload: formValues });
				return
			} else {
				dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data, type: 'error' } })
				return
			}
		})
}

export const getTags = async (id) => {
	console.log('requested tags')
	return await noMonitor.get(`/get-tags/${id}`)
}

export const generateBusinessCard = async (data) => {
	console.log(data);
	return await noMonitor.post('/user/generate-business-card', { barcodeValue: data })
}

//Register User
export const registerUser = async (userData: any) => {
	return await api
		.post("/register", userData, { withCredentials: true })
		.then((res) => {
			if (res.status !== 201) {
				console.log(res);
				return () => {
					(
						dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data.error, type: 'error' } })
					)
				}
			} else {
				dispatch({ type: BACK_TO_LOGIN })
				dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data.message, type: 'success' } })
			}
		})
		.catch((err) => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: err?.response.data, type: 'error' } })
		});
}

export const addUserTags = async (payload) => {
	return await noMonitor
		.post('/user/add-tags', payload).then(() => { return { success: true } })
}

export const addFavourite = async (id, type, body) => {
	let url = body?.content;
	return await monitorFail.post(`/set-favourite/${type}/${id}`, body).then(async res => {
		console.log(url);
	}).catch(()=>{
		dispatch({ type: HANDLE_FAVOURITE, payload: { id, type, body: url } })
	})
}

export const getDataFavorites = async () => {
	return await noMonitor.get('/data/get-favourites').then(res => {
		if (res.status === 200) {
			return res.data.data
		}
	}
	)
}

export const getProjectFavorites = async () => {
	return await noMonitor.get('/projects/get-favourites').then(res => {
		if (res.status === 200) {
			return res.data.data
		}
	}
	)
}


export const updateUserProfile = async (payload) => {
	const body = { content: payload }
	return await monitorFail
		.post('/user/update-profile', body).then(res => { return { success: true } })
		//TODO Must dispatch the new user data to the store
}

export const handleOAuth = async (request: any) => {
	return await monitorFail
		.post('/register/oauth', request)
}

export const authoriseGMail = async (gmailUser) => {
	return await monitorFail
		.post(`/authorisations/gmail`, gmailUser)
		.then(res => {
					return res
				})
}

export const updateNotificationToken = async (payload, userId) => {
	const body = { userId: userId, token: payload }
	await noMonitor.post('/user/update-notification-token', body)
}

export const passwordReset = async (formValues) => {
	const request = formValues.toLowerCase()
	return await monitoredRequest.post(`/password-reset/${request}`)
		.then((res => {
			if (res.status !== 201) {
				dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Oh snap!', type: 'error' } })
				return;
			}
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data.message, type: 'success' } })
			return { success: true };
		}))
		.catch(e => {
			console.error(e)
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Please check the provided email address', type: 'error' } })
		})
};

export const validatePasswordReset = async (code: { code: string, newPassword: string }) => {
	if (code.code.length > 6) {
		return dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Please ensure that you have entered the correct code', type: 'error' } })
	}
	const payload = JSON.stringify({ code: code.code, newPassword: code.newPassword });
	return await api.post(`/validatePasswordReset`, payload)
		.then(res => {
			if (res.status !== 200) {
				console.error(res)
				dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data.error, type: 'error' } })
			} else {
				dispatch({ type: HIDE_PASSWORD_RESET_MODAL })
				dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data.message, type: 'success' } })
			}
		})
}

export const unlockUser = async (formValues) => {
	await api.post('/unlock', formValues)
		.then((res => {
			if (res.status !== 200) {
				dispatch({ type: FAILED, data: 'Request failed, please check your password and try again' })
				dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data, type: 'error' } });
			}
			else {
				dispatch({ type: HIDE_LOCK_MODAL });
			}
		}))
		.catch((err => {
			console.log(err);
		}))
};


//Set logged in user
export const setCurrentUser = decoded => {
	dispatch({
		type: SUCCESS,
		loading: false,
		error: null,
		data: decoded,
		isAuthenticated: true
	});
};

//Messaging APIs
export const getAllUsers = async (company: string | undefined) => { return await noMonitor.get(`/users/${company}`).then(res => { return res.data.data }) }
export const getUserHierarchy = async (company: string | undefined) => { return await noMonitor.get(`/users/hierarchy/${company}`).then(res => { return res.data }) }
export const getLargeProfile = async (selectedId) => await api.post(`/users/profile`, selectedId).then(res => { if (res.status !== 200) { return null } let avatar = res.data.data.toString(); let prepend = 'data:image/png;base64,'; let selectedAvatar = prepend + avatar; return selectedAvatar });
export const getUserConversations = async () => await api.get(`/conversations/recent-conversations`).then(res=> res.data.data);
export const getConversation = async (data) => await api.post(`/conversations/messages`, data);
export const postMessage = async (userId, data) => await api.post(`/conversations/messages/${userId}/${data.participantId}`, data);

// User loading
export const setUserLoading = () => {
	return {
		type: USER_LOADING
	};
};

// @PaymentIntents
export const paymentSheet = async (req) => { return await noMonitor.post('/payments/payment-sheet', req).then(res => { return res.data.data }).catch(() => { throw new Error("No response") }) }

export const switchCompany = async (userId, companyNumber) => {
	await api.get(`/switchcompany/${userId}/${companyNumber}`)
		.then(res => {
			if (res.status == 200) {
				dispatch({ type: SWITCH_COMPANY_SUCCESSFUL, payload: { companyData: res.data } })
			} else dispatch({ type: SWITCH_COMPANY_FAILED })
		})
}

export const postAvatar = async (userId, image) => { return await monitoredRequest.post(`/upload/user/${userId}/avatar`, image) }
export const postDeskImage = async (userId, image) => await monitoredRequest.post(`/upload/user/${userId}/deskImage`, image);
export const postLogo = async (companyId, image) => await api.post(`/upload/company/${companyId}`, image);

export const sendUserReg = async (userDetails) => {
	await api.post(`/adduser`, userDetails, { withCredentials: true })
		.then(res => {
			return
		})
}

export const logoutUser = async () => {
	return await logOut.get('/logout')
}

export const addData = async (formValues, companyId, userId, dataId) => {
	console.log(formValues);
	await api.post(`/${companyId}/${userId}/post-data/${dataId}`, formValues, { withCredentials: true })
		.then(() => {
			dispatch({ type: PENDING_REFRESH })
		})
}

export const refreshAchievements = async (userId) => {
	return await api.get(`/achievements/${userId}`, { withCredentials: true })
		.then(res => {
			return res.data.data
		})
}

export const getProducts = async () => {
	return await api.get('/get-product-list')
		.then(res => {
			console.log(res.status)
			if (res.status != 200) {
				dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data.error, type: 'error' } })
			} else {
				dispatch({ type: UPDATE_PRODUCTS, payload: res.data.data })
				return res.data.data
			}
		})
}

export const getSubscriptions = async (subscriptionInfo: { billingId: String, subscriptionId: String }) => {
	return await noMonitor.post('/payments/get-subscriptions', subscriptionInfo)
		.then(res => {
			if (res.status == 204) {
				return 'No subscriptions active'
			} else {
				return res.data
			}

		})
}

export const mailLogin = async (formValues, id) => {
	await api.post('/mail')
		.then(res => { console.log(res) })
}

export const getStream = async (companyId, userId) => {
	return await api.get(`/${companyId}/${userId}/eventStream`)
}

export const getActivityStreamLog = async (companyId, userId) => {
	return await api.get(`/${companyId}/${userId}/eventLog`)
}

export const getDataTypes = async (id) => {
	return await noMonitor.get(`/user/get-data-count`)
}
export const getCompanySchema = async (companyId) => {
	return await noMonitor.post(`/company/company-schema`, { companyId: companyId })
}

export const retrieveCompanyBillingInfo = async (companyId) => {
	return await api.get(`/billing/company/${companyId}`);
}

export const retrieveUserBillingInfo = async (userId) => {
	return await api.get(`/billing/${userId}`)
}

export const getIntegrations = async () => {
	return await api.get('/integrations').then(res => { return res.data.data })
}

export const addCompany = async (payload) => {
	const res = await monitoredRequest.post(`/add-new-company`, payload);
	if (res.status == 201) {
		return true
	}
}

export const getCalendarSchema = async () => {
	return await api.get('/calendar/get-calendar-schema')
		.then(res => {
			if (res.status != 203) {
				return dispatch({ type: SHOW_AUTH_MODAL, data: { error: 'Could not retrieve schema', type: 'error' } })
			} else {
				return res.data.data;
			}
		})
		.catch(e => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { error: e, type: 'error' } })
		})
}

export const getProjects = async (id) => {
	if (!id) {
		return dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'No project data in request ', type: 'error' } })
	}
	return await noMonitor.get(`/projects/${id}/get-projects`)
}

export const addProjects = async (project, id) => {
	console.log(project, 'project');
	const dateRanges = project.date_range;
	const newProj = { ...project, start_date: dateRanges.startDate, end_date: dateRanges.endDate }
	return await monitoredRequest.post('/projects/add-project', { userId: id, project: newProj }).then(() => { return { success: true } })
}

export const addLocalCalendars = async (calendars) => {
	return await noMonitor.post('/user/set-local-calendars', calendars)
}

export const getCalendarContent = async (id, date) => {
	return await noMonitor.post('/calendar/get-calendar', { userId: id, date: date })
}

export const addCalendarEntry = async (calendarId, id) => {
	if (!calendarId) {
		return dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Please ensure you have selected a calendar first', type: 'error' } })
	}
	return await monitoredRequest.post('/calendar/add-calendar-entry', { userId: id, calendar: calendarId }).then(() => { return { success: true } })
}

export const updateProject = async (project) => {
	if (!project) {
		return dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'No project data in request ', type: 'error ' } })
	}
}

export const getProjectSchema = async () => {
	console.log('requesting project schema');
	return await noMonitor.get('/projects/get-project-schema')
		.then(res => {
			return res.data.data;
		})
}

export const getAddNetworkSchema = async () => {
	return await noMonitor.get('/user/get-networking-schema')
		.then(res => {
			return res.data.data;
		})
}

export const changeWPStatus = async (id, status) => {
	return await api.post('/projects/change-wp-status', { userId: id.globalId, projectId: id.dataId, workPackageId: id.extraId, status: status })
		.then(res => {
			if (!res) {
				return dispatch({ type: SHOW_AUTH_MODAL, data: { error: 'No project data in request ', type: 'error ' } })
			} else return { success: true }
		})
		.catch(e => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { error: e, type: 'error ' } })
		})
}

export const changeWorkPackageTitle = async (wpId, projectId, title) => {
	return await noMonitor.post('/projects/change-wp-title', { projectId, wpId, title })
}


export const changeTaskTitle = async (id, title) => {
	return await noMonitor.post('/projects/change-task-title', { userId: id.globalId, projectId: id.dataId, workPackageId: id.extraId, taskId: id.taskId, title: title })
}

export const changeProjectTitle = async (projectId, value) => {
	return await noMonitor.post('/projects/change-project-title', { projectId: projectId, title: value })
}

export const changeProjectDescription = async (projectId, value) => {
	return await noMonitor.post('/projects/change-project-description', { projectId: projectId, description: value })
}

export const changeTaskDescription = async (id, description) => {
	return await noMonitor.post('/projects/change-task-description', { userId: id.globalId, projectId: id.dataId, workPackageId: id.extraId, taskId: id.taskId, description: description })
		.then(res => {
			if (!res) {
				return dispatch({ type: SHOW_AUTH_MODAL, data: { error: 'No project data in request ', type: 'error ' } })
			} else return { success: true }
		})
		.catch(e => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { error: e, type: 'error ' } })
		})
}

export const deleteTask = async (id) => {
	return await noMonitor.post(`/projects/delete-task`, id)
}

export const duplicateTask = async (id) => {
	return await noMonitor.post(`/projects/duplicate-task`, id)
}

export const duplicateEmailTemplate = async (id) => {
	return await monitorFail.post(`/templates/email/duplicate-template`, id)
}

export const duplicateChildTemplate = async (id: string, title: string) => {
	return await monitorFail.post(`/templates/email/duplicate-child-template`, { id, title })
}

export const saveEmailTemplateCopy = async (template) => {
	return await monitorFail.post(`/templates/email/save-template-copy`, template)
}

export const changeTaskStatus = async (id, status) => {
	return await api.post('/projects/change-task-status', { id, status })
}

export const changeTaskNotes = async (id, notes) => {
	return await api.post('/projects/change-task-notes', { id, notes })
}

export const deleteWorkPackage = async (id) => {
	return await monitoredRequest.post(`/projects/delete-wp`, id)
}

export const addTask = async (taskData, id) => {
	const { dateRange, ...rest } = taskData;
	rest.start_date = dateRange?.startDate;
	rest.end_date = dateRange?.endDate;

	const newTask = {
		...rest,
		dateRange: undefined
	}

	return await api.post('/projects/add-task', { userId: id.globalId, projectId: id.dataId, workPackageId: id.extraId, task: newTask })
}

export const addWorkPackage = async (workPackage, id) => {
	const { dateRange, ...rest } = workPackage;
	rest.start_date = dateRange?.startDate;
	rest.end_date = dateRange?.endDate;

	return await monitorFail.post('/projects/add-workpackage', { userId: id.globalId, projectId: id.dataId, workPackage: rest })
		.then(res => {
			return { data: { _id: res.data.data, ...rest, start_date: new Date(rest.start_date).toLocaleString(), end_date: new Date(rest.end_date).toLocaleDateString(), task_list: [], team: [] } }
		})
}

export const getTaskSchema = async () => {
	return await api.get('/projects/get-task-schema')
		.then(res => {
			if (!res) {
				return dispatch({ type: SHOW_AUTH_MODAL, data: { error: 'No project data in request ', type: 'error ' } })
			} else {
				return res.data.data;
			}
		})
		.catch(e => {
			dispatch({ type: SHOW_AUTH_MODAL, data: { error: e, type: 'error ' } })
		})
}

export const getWPSchema = async () => {
	return await api.get('/projects/get-workpackage-schema')
		.then(res => {
			return res.data.data;
		})
}

export const dispatchAppAction = async (action) => {
	//TODO Destructure userId and determine if user is logged into a company, then it would be best to assign company values and user values independently, i.e. increment user count individually to company account. Hide details of the action from the user and instad use vague metrics to give them more useful information

	let payload = { actionType: action.type, payload: action.payload, appVersion: Constants.manifest.version }

	await noMonitor.put(`/userAppHistory/${'uhkj'}`, payload)
		.then((res) => {
			if (!res) {
				//TODO add to local storage until connection is established
				return;
			}
			dispatch({ type: UPDATE_ACHIEVEMENTS, payload: res.data.points })
		}).catch(e => {
			console.error(e)
		});
	return;
}

export const getLoginMessages = async () => {
	return await noMonitor.get(`/login-messages`).then(res => {
		if (res.status != 200) {
			console.error(res)
		}
		return res.data.data
	})
}

export const getData = async (userId) => {
	return await noMonitor.post(`/user/get-data`, { id: userId }).then(res => {
		return res.data.data
	})
}

type DataFolderType = {
	userId: string,
	dataFilter?: string
}

export const getDataFolders = async ({userId, dataFilter}: DataFolderType) => {
	return await noMonitor.post(`/user/get-data-folders`, { id: userId, dataFilter }, { timeout: 5000 }).then(res => {
		return res.data.data
	})
}

export const getFolderStats = async (folderId) => {
	return await noMonitor.post(`/user/get-folder-stats`, { id: folderId }).then(res => {
		return res.data
	})
}

export const getAllFolderStats = async () => {
	return await noMonitor.get(`/user/get-all-folder-stats`).then(res => {
		return res.data.data
	})
}

export const getProductList = async (userId) => {
	return await api.get(`/get-product-list/${userId}`, { withCredentials: true }).then(res => {
		if (res.status != 200) {
			dispatch({ type: SHOW_AUTH_MODAL, data: { content: res.data.error, type: 'error' } })
			return;
		}
		return res.data.data
	})
}

export const getSampleSchemas = async () => {
	return await noMonitor.get('/get-schemas', { withCredentials: true }).then(res => {
		return res.data.data
	})
}

export const saveData = async (payload) => {
	const { id } = payload;
	return await monitoredRequest.post(`/add-new-data/${id}`, payload)
		.then(() => {
			return { success: true }
		})
}

export const uploadData = async (payload) => {
	const { id } = payload;
	return await monitoredRequest.post(`/upload-new-data/${id}`, payload)
		.then(() => {
			return { success: true }
		})
}

export const getDataContent = async (globalId, dataId) => {
	return await monitorFail.get(`/get-data-content/${globalId}/${dataId}`)
}
export const addDataItem = async (payload, ids) => {
	const { globalId, dataId } = ids;
	return await monitoredRequest.post(`/add-data-content/${globalId}/${dataId}`, payload).then(res => {
		dispatch({ type: "UPDATE" }); return res
	})
}

export const getDataSchema = async (userId, dataId) => {
	return await noMonitor.get(`/get-data-schema/${userId}/${dataId}`)
}

export const getProfileSchema = async (globalId) => {
	return await monitorFail.post('/user/get-profile-schema', { globalId: globalId })
}

export const getAddInvoiceTemplateSchema = async () => {
	return await noMonitor.get('/templates/invoices/add-template-schema');
}

export const addTagsToData = async (dataId, tag) => {
	return await noMonitor.post('/data/add-tag', { dataId: dataId, tags: tag })
}

export const searchUsers = async (text) => {
	return await api.post('/search-users', { text: text }).then(res => { return res.data.records })
}

export const getReport = async (dataId) => {
	return await api.post('/get-report-data', { dataId: dataId })
}

export const deleteDataItem = async (globalId, dataId) => {
	return await noMonitor.delete(`/delete-data/${globalId}/${dataId}`)
}

export const deleteProject = async (globalId, projectId) => {
	return await monitoredRequest.delete(`/projects/delete-project/${globalId}/${projectId}`)
}

export const getProfileCompletion = async (globalId) => {
	return await noMonitor.get(`/user/profile-calculator`)
}

export const getProjectCount = async (globalId) => {
	return await noMonitor.get(`/${globalId}/get-project-count`)
}

export const getDataCount = async () => {
	return await noMonitor.get(`/user/get-data-count`)
}

export const saveNewsItem = async (globalId, news) => {
	return await noMonitor.post(`/${globalId}/post-saved-news`, news)
}

export const getSavedNews = async (globalId) => {
	return await noMonitor.get(`/${globalId}/get-saved-news`);
}

export const getProject = async (projectId, globalId) => {
	return await noMonitor.get(`/projects/${globalId}/get-project/${projectId}`)
}

export const addCalendar = async (globalId, calendar) => {
	return await monitoredRequest.post(`/calendar/${globalId}/add-calendar`, calendar)
}

export const addStickyNote = async (note) => {
	return await noMonitor.post(`/notes/post-sticky-note`, note)
}

export const pinNote = async (noteId) => {
	return await noMonitor.post(`/notes/pin-note/${noteId}`)
}

export const unPinNote = async (noteId) => {
	return await noMonitor.post(`/notes/unpin-note/${noteId}`)
}

export const modifyStickyNote = async (note) => {
	return await noMonitor.post(`/notes/modify-sticky-note/${note._id}`, note)
}


export const removeStickyNote = async (globalId, note) => {
	return await noMonitor.delete(`/notes/remove-sticky-note/${note}`)
}

export const getStickyNotes = async () => {
	return await noMonitor.get(`/notes/get-sticky-notes`).then(res => {
		dispatch({ type: SET_STICKY_NOTES, payload: res.data.data.sticky_notes })
	})
}

export const markNoteComplete = async (globalId, note) => {
	return await noMonitor.post(`/notes/mark-note-complete/${note._id}`, note)
}

export const setNoteColor = async (globalId, note) => {
	return await noMonitor.post(`/notes/change-note-color/${note._id || note.id}`, note)
}

export const getInitialModels = async (req) => {
	return await noMonitor.post(`/get-initial-models`, req);
}

export const postInitialModel = async (modelList, userId) => {
	return await noMonitor.post(`/${userId}/add-initial-data`, modelList);
}

export const getStandardisedReports = async (userId, previousList) => {
	return await noMonitor.post(`/post-standardised-reports/${userId}`, previousList)
}

export const validate2FA = async (userId, token) => {
	return await monitoredRequest.post(`/security/${userId}/validate-two-fa`, { token: token })

}

export const validateOTP = async (storedCred) => {
	return await monitoredRequest.post(`/validate-otp`, storedCred)
}

export const setTutorial = async () => {
	return await noMonitor.get(`/user/set-tutorial-modal`)
}

export const setPublicDataSwipeTutorial = async () => {
	return await noMonitor.get(`/user/set-public-swipe`)
}
export const setInvitationSwipeTutorial = async () => {
	return await noMonitor.get('/user/set-invite-swipe')
}
export const getAvatarById = async (id) => {
	return await noMonitor.get(`/get-avatar-image/${id}`).then(res => { return res.data.data.profile_information.avatar.img })
}

export const getRoomCounts = async () => {
	return await noMonitor.get('/chat-rooms')
}
export const getRoom = async (room) => {
	return await noMonitor.get(`/networking-room/${room}`)
}

export const updateTC = async (userId, tcVersion) => {
	return await noMonitor.post('/user/terms/update', { user: userId, version: tcVersion })
}

export const getActivityLog = async (userId) => {
	return await noMonitor.get(`/activity-log/${userId}`);
}

export const addLocation = async (location) => {
	return await noMonitor.post('/user/add-networking', location)
}

export const getAchievementHistory = async () => {
	return await noMonitor.get(`/achievements-history`).then(res => { return res.data.data })
}

export const shareDataItem = async (body) => {
	return await monitoredRequest.post(`/share/data/invite`, body).then(res => res.data.data)
}

export const shareProject = async (body) => {
	return await monitoredRequest.post(`/share/project/invite`, body).then(res => res.data.data)
};

export const acceptCompanyInvitation = async (invitationId) => {
	return await monitoredRequest.post(`/company/accept-invitation`, invitationId).then(res => res.data.data)
}

export const addUserToCompany = async (data) => {
	return await monitoredRequest.post('/users/invite-user', data).then(res => res.data.data)
}

export const getUser = async (userId) => {
	return await noMonitor.get(`/user/${userId}/get-small-profile`).then(res => res.data.data)
}

export const getUserById = async (userId) => {
	console.log('requesting user', userId)
	return await noMonitor.get(`/users/${userId}/getUserById`).then(res => res.data).catch(e => console.error(e))
}


export const getInvitations = async () => {
	return await noMonitor.get(`/share/data/invitations`).then(res => res.data.data)
}

export const getInvitationCount = async () => {
	return await noMonitor.get(`/share/data/invitation-count`).then(res => res.data.data)
}

export const acceptInvitation = async (invitationId) => {
	return await monitoredRequest.post(`/share/data/accept-invitation/${invitationId}`).then(res => res.data.data)
}

export const rejectInvitation = async (invitationId) => {
	return await noMonitor.post(`/share/data/reject-invitation/${invitationId}`).then(res => res.data.data)
}

export const revokeInvitation = async (invitationId) => {
	return await monitoredRequest.post(`/share/data/revoke-invitation/${invitationId}`).then(res => res.data.data)
}

export const getPublicData = async (sort) => {
	return await noMonitor.get(`/public-data/${sort}`).then(res => res.data.data)
}

export const getCompanyList = async () => {
	return await noMonitor.get(`/get-company-list`).then(res => res.data.data)
}

export const updateDataItem = async (dataId, values) => {
	return await noMonitor.post(`/data/update-item`, {dataId, values}).then(res => res.data.data)
}

export const hideFirstTutorial = async () => {
	await noMonitor.get(`/user/hide-first-tutorial`)
}

export const getPreviewData = async () => {
	await noMonitor.post('/get-preview-data',)
}
export const saveEmailTemplate = async (payload) => {
	return await monitoredRequest.post('/templates/post-email-template', payload).then(() => { return { success: true } })
}

export const updateEmailTemplate = async (payload) => {
	return await monitoredRequest.post('/templates/update-email-template', payload)
}

export const getInitialTemplates = async () => {

}

export const getEmailTemplates = async () => {
	return await noMonitor.get('/templates/get-email-templates').then(res => { return res.data.data })
}

export const getInvoiceTemplates = async () => {
	return await noMonitor.get('/templates/invoices/get-templates').then(res => { return res.data.data })
}

export const getDocumentTemplates = async () => {
	return await noMonitor.get('/templates/get-document-templates').then(res => { return res.data.data })
}

export const getEmailTemplate = async (templateId) => {
	return await noMonitor.get(`/templates/get-single-email-template/${templateId}`).then(res => { return res.data.data })
}

export const editEmailTemplate = async (templatedId, content) => {
	return await noMonitor.post(`/templates/edit-email-template/${templatedId}`, content)
}

export const deleteEmailTemplate = async (templateId) => {
	console.log(templateId)
	return await noMonitor.delete(`/templates/delete-email-template/${templateId}`)
}

export const getServiceList = async (userId) => {
	return await noCreds.get(`/get-service-list/${userId}`).then(res => { return res.data.data })
}

export const getStoreInfo = async (storeId) => {
	return await noCreds.get(`/get-store-info/${storeId}`).then(res => { return res.data.data })
} 

export const addDataImage = async (dataId: string, contentId: string, image: any) => {
	return await monitorFail.post(`/data/add-data-image/${dataId}/${contentId}`, { img: image })
}

export const getDataContentById = async (dataId, contentId) => {
	return await noMonitor.get(`/data/content/${dataId}/${contentId}`).then(res => res.data.data)
}

export const postInvoiceTemplate = async (data) => {
	return await noMonitor.post('/templates/invoices/post-template', data).then((res) => { if (res.status === 200) { return { success: true } } });
}

export const postDocumentTemplate = async (data) => {
	return await noMonitor.post('/templates/documents/post-template', data);
}

export const getFeedbackSchema = async () => {
	return await noMonitor.get('/feedback/get-feedback-schema').then(res => { return res.data.data })
}

export const getFeedback = async (userId) => {
	return await noMonitor.get(`/feedback/get-feedback/${userId}`).then(res => { return res.data.data })
}

export const postFeedback = async (data) => {
	return await noMonitor.post('/feedback/post-feedback', data);
}

export const getProductServiceList = async () => {
	return await noMonitor.get('/data/product-and-service').then(res => { return res.data })
}

export const searchRegEx = async (text) => {
	console.log(text)
	return await noMonitor.get('/user/searchall?q=' + text).then(res => { return res.data })
}

export const getIntegrationApiAction = async (action) => {
	return await noMonitor.post(`${action}`, { redirectUrl: Linking.createURL('/integrations'), refreshUrl: Linking.createURL('/integrations') })
}

export const createStorePaymentIntent = async () => {
	return await noMonitor.post('/stripe/connect/create-payment-intent').then((res) => { console.log(res); return res })
}

export const getStripeId = async () => {
	return await noMonitor.post('/stripe/get-stripe-id').then(res => { return res.data.stripeId });
}

export const registerStripeLocation = async (data) => {
	return await noMonitor.post('/stripe/update-user-location', data)
}

export const registerStripeReader = async (data) => {
	return await noMonitor.post('/stripe/register-reader', data).then(res => { return res.data })
}

export const confirmPaymentIntent = async (data) => {
	return await noMonitor.post('/stripe/confirm-intent', data).then(res => { return res.data })
}

export const getAIForm = async (data) => {
	return await monitoredRequest.post('/ai/get-form', data).then(res => { return res.data })
}

export const getFeed = async (distance, lat?: string, lon?: string, pageParam?: number) => {
	return await noMonitor.get(`/feed?distance=${distance}&lat=${lat}&lon=${lon}&page=${pageParam}`).then(res => { return res.data })
}

export const postToFeed = async (data) => {
	return await monitoredRequest.post('/feed/post', data).then(res => { return res.data })
}

export const deleteComment = async (commentId, feedId) => {
	return await noMonitor.post('/feed/remove-comment', { commentId: commentId, feedId: feedId })
}

export const addLikeToPost = async (postId) => {
	console.log('liking post', postId)
	return await noMonitor.post(`/feed/like/${postId}`).then(res => { return res.data })
}

export const addShareToPost = async (postId) => {
	return await noMonitor.post(`/feed/share/${postId}`).then(res => { return res.data })
}

export const removeLikeFromPost = async (postId) => {
	return await noMonitor.post(`/feed/unlike/${postId}`).then(res => { return res.data })
}

export const removeShareFromPost = async (postId) => {
	return await monitoredRequest.post(`/feed/unshare/${postId}`).then(res => { return res.data })
}

export const addCommentToPost = async (postId, comment) => {
	return await noMonitor.post(`/feed/comment/${postId}`, comment).then(res => { return res.data })
}

export const addNestedComment = async (feedId, commentId, comment) => {
	return await noMonitor.post(`/feed/comment/add-comment/${feedId}`, { comment, commentId: commentId }).then(res => { return res.data })
}

export const getAICommentAnalysis = async (comment) => {
	return await noMonitor.get(`/ai/comment-analysis/${comment}`).then(res => { return res.data })
}

export const getExtendedData = async (dataId, localUserId) => {
	console.log('getting extended data')
	return await noMonitor.post(`/data/extended/${dataId}`, { localUserId: localUserId }).then(res => { return res.data })
}

export const storeDeviceToken = async (token) => {
	await noMonitor.post('/user/store-device-token', token)
}

export const replaceSchema = async (schema) => {
	const { id } = schema;
	console.log(schema)
	return await monitoredRequest.post(`/data/replace-schema/${id}`, schema)
}

export const getDataItemById = async (dataId) => {
	return await noMonitor.get(`/data/item/getItem/${dataId}`).then(res => { return res.data })
}

export const retrieveProduct = async (productId) => {
	return await noMonitor.get(`/stripe/product/${productId}`).then(res => { return res.data })
}

export const getCustomerPortal = async (accountId) => {
	return await noMonitor.post(`/stripe/customer/portal`, accountId).then(res => { return res.data })
}

export const getCheckoutSession = async (productDetails: { billingId: string, productId: string }) => {
	console.log(productDetails, 'is from');
	return await noMonitor.post(`/stripe/checkouts/session`, productDetails).then(res => { console.log(res, 'is from'); return res.data })
}

export const syncCalendars = async (calendars, deviceInfo) => {
	return await monitoredRequest.post('/calendar/sync-calendars', { calendars, deviceInfo })
}
export const syncCalendarEvents = async (events) => {
	return await monitorFail.post('/calendar/sync-events', events)
}

export const getCalendars = async (userId: Object) => {
	return await noMonitor.post('/calendar/get-calendars', userId)
}

export const makeDataPublic = async (dataId: string) => {
	return await noMonitor.post(`/data/make-public/${dataId}`)
}
export const updateWorkPackage = async (id, workPackage) => {
	return await monitoredRequest.post(`/projects/update-workpackage/${id}`, workPackage)
}

export const getKnowledgeBase = async (linkedId) => {
	return await noMonitor.get(`/knowledge`)
}

export const genEmail = async (data) => {
	return await noMonitor.post(`/ai/generate-email`, data)
}

export const getContributionChart = async (reportId) => {
	return await noMonitor.get(`/reports/contribution-chart/${reportId}`).then(res => res.data)
}

export const getAIReportQuery = async (reportId, query) => {
	return await noMonitor.post(`/ai/report-query/${reportId}`, { query: query }).then(res => res.data)
}

export const googleAuthCallback = async (code) => {
	return await monitoredRequest.post(`/authorisations/oauth2callback`, { code: code })
}

export const getAddRoomSchema = async () => {
	return await noMonitor.get('/messaging/get-room-schema').then(res => res.data.schema)
}

export const buildLink = async (data: {
	targetUser: string,
	sourceUser: string,
	linkType: string,
	direction: string
}) => {
	return await monitoredRequest.post('/company/build-link', { targetUser: data.targetUser, sourceUser: data.sourceUser, linkType: data.linkType, direction: data.direction })
}

export const getSalesStats = () => {
	return noMonitor.get('/sales/get-stats')
}

export const sendInvoice = (invoice: any, mail: { message: string, subject: string }, target: any[] | string) => {
	//Attach invoice to email and send to target

}

export const getInventoryData = async (dataId) => {
	return await noMonitor.get(`/data/inventory/${dataId}`).then(res => res.data)
}

export const getCrmCount = async (user: string) => {
	return await noMonitor.post('/sales/get-crm-count', { user }).then(res => res.data)
}

export const getActiveCampaigns = async (id) => {
	return await monitorFail.post('/sales/active-campaigns', { id }).then(res => res.data)
}

export const postNewCampaign = async (campaign, id) => {
	return await noMonitor.post('/sales/create-campaign', { campaign: campaign, id }).then(res => res.data)
}

export const getDefaultEmailTemplates = async () => {
	return await noMonitor.get('/templates/get-default-email-templates').then(res => res.data)
}

export const postInternalKnowledge = async (values) => {
	return await noMonitor.post('/knowledge/add', values).then(res => res.data._id)
}

export const postExternalKnowledge = async (values) => {
	return await noMonitor.post('/knowledge/add', values).then(res => res.data._id)
}

export const getInternalKnowledge = async () => {
	return await noMonitor.get('/knowledge/get-internal').then(res => res.data)
}

export const deleteKnowledgeArticle = async (id) => {
	return await noMonitor.delete(`/knowledge/delete/${id}`)
}

export const saveKnowledgeContent = async (id: string, content: string) => {
	await monitoredRequest.post(`/knowledge/update/${id}`, { knowledge: content })
}

export const publishKnowledgeArticle = async (id) => {
	await monitoredRequest.post(`/knowledge/publish/${id}`)
}

export const getKnowledgeContent = async (id) => {
	return await noMonitor.get(`/knowledge/get/${id}`).then(res => res.data)
}

export const getTeam = async (companyId) => {
	return await apis.getAllUsers(companyId).then(res => res.users)
}

export const getCRMFolders = async () => {
	return await noMonitor.get('/sales/get-crm-folders').then(res => res.data)
}

export const getTemplates = async () => {
	return await noMonitor.get('/templates/get-templates').then(res => res.data)
}

export const getTemplateById = async (type, id) => {
	return await noMonitor.get(`/templates/get-template/${type}/${id}`).then(res => res.data)
}

export const updateWorkPackageDue = async (wpId: string, projectId: string, date: Object) => {
	return await noMonitor.post(`/projects/${projectId}/change-task-due-date`, { wpId: wpId, date })
}

export const getCampaign = async (campaignId: string) => {
	return await noMonitor.post(`/sales/get-campaign`, { campaignId }).then(res => res.data)
}

export const searchDataRegEx = async (dataId, query: string, keys) => {
	return await noMonitor.post('/data/search-folder', { dataId, query: query, keys })
}

export const handleChangeTaskDescription = async (id, description) => {
	return await noMonitor.post(`/projects/change-task-description`, { id, description })
}

export const handleChangeTaskTitle = async (id, title) => {
	return await noMonitor.post(`/projects/change-task-title`, { id, title })
}

export const changeTaskPosition = async (id, status) => {
	return await monitorFail.post(`/projects/change-task-position`, { id, status })
}

export const modifyTask = async (id, task) => {
	const { dateRange, ...rest } = task;
	rest.start_date = dateRange?.startDate;
	rest.end_date = dateRange?.endDate;
	const newTask = {
		...rest,
		dateRange: undefined
	}

	return await monitorFail.post(`/projects/modify-task`, { id, newTask })
}

export const getDataTemplateCount = async () =>{
	return await noMonitor.get('/templates/count').then(res => res.data)
}

export const setEmailGlobal = async (id: string) => {
	return await monitorFail.post('/templates/make-email-global', { id: id })
}

export const getGlobalAssets = async () => {
	return await monitorFail.get('/templates/get-media').then(res => res.data)
}

export const uploadGlobalAsset = async (asset) => {
	return await monitorFail.post('/templates/upload-media', { files: [asset] })
}

export const deletePost = async (id) => {
	return await monitoredRequest.delete(`/feed/${id}`)
}

export const getTargetAudience = async (id) => {
}

export const revokeGoogleScope = async (scope: string) => {
	return await monitorFail.post(`/google/revoke-access`, { revokeTarget: scope }).then(res => {
		console.log(res.data, 'from revoke');
		//Open authURL in new window
		window.location.href = res.data.authURL;
		
	})
}

export const authoriseInstagram = async (data) => {
	return await monitorFail.post(`/instagram/authorise`, data)
}

export const authCallback = async (code: string, integration: string, scopes: string[]) => {
	return await monitoredRequest.post('/integrations/auth/callback', { scopes, code: code, integration: integration }).then(res => res.data)
}

export const searchCompanies = async (query: string) => {
	return await monitorFail.get(`/companies/search/${query}`).then(res => res.data)
}

export const getUserRecents = async () => {
	return await monitorFail.get('/user/recents').then(res => res.data)
}

export const addRecent = async (id, dataType) => {
	return await noMonitor.post('/user/recent/add', { itemId: id, itemType: dataType })
}

export const getTrending = async () => {
	return await noMonitor.get('/trends/GB').then(res => res.data)
}

export const aiCommentAnalysis = async (comments, postContent) => {
	return await noMonitor.post('/ai/comment-analysis', { comments, postContent })
}

export const getUserStats = async () => {
	return await noMonitor.get('/user/get-all-statistics').then(res => res.data);
}

export const getUserStatsData = async (routeType, startDate, endDate, filterType) => {
	return await noMonitor.get(`/user/get-statistics?routeType=${routeType}&startDate=${startDate}&endDate=${endDate}&filterType=${filterType}`).then(res => res.data);
}

export const getKnowledgeByViews = async () => {
	return await noMonitor.get('/knowledge/by-views').then(res => res.data)
}

export const cloneData = async (dataId, targetId) => {
	return await noMonitor.post(`/data/clone`, { dataId, targetId }).then(res => res.data)
}

export const transferData = async (dataId, targetId) => {
	return await noMonitor.post(`/data/transfer`, { dataId, targetId }).then(res => res.data)
}

export const cancelUserCreation = async (userId: string) => {
	return await noMonitor.post("/user/cancel-creation", { userId })
}

export const userLoginHistory = async (userId: string) => {
	return await noMonitor.post("/user/login-history", { userId }).then(res => res.data)
}

export const getCrmItems = async (id: string) => {
	return await noMonitor.post(`/sales/crm/individual`, { id }).then(res => res.data)
}

export const uploadDataImage = async () => {
	return await noMonitor.post(`/data/upload-image`).then(res => res.data)
}

export const getMainCharts = async (startDate, endDate) => {
	return await noMonitor.get(`/reports/combined-data?startDate=${startDate}&endDate=${endDate}`).then(res => res.data)
}

export const requestDataDownload = async (dataId: string) => {
	return await monitoredRequest.post(`/data/request-download`, { dataId })
		//Interpret pipe
		.then(res => {
			//Open download link
			console.log(res.data, 'from download');
		}).catch(e => console.error(e))
}

export const getStartupSchemas = async () => {
	return await noMonitor.get('/company/startup-schemas').then(res => { console.log(res.data); return res.data })
}

export const startCampaign = async (campaignId: string) => {
	return await noMonitor.post(`/campaigns/schedule/start-campaign`, { campaignId }).then(res => res.data)
}

export const changeDataTitle = async (dataId: string, title: string) => {
	return await noMonitor.post(`/data/change-title`, { dataId, title }).then(res => res.data)
}

export const createAffiliateCode = async () => {
	return await noMonitor.post('/affiliate/create-code').then(res => res.data)
}

export const getAffiliateHistory = async (code, dateRange) => {
	return await noMonitor.post('/affiliate/history', { code, dateRange }).then(res => res.data)
}

export const deleteAccount = async (userId: string) => {
	console.log("Deleting account");
	return true;
}

export const addDepartment = async (data) => {
	return await noMonitor.post('/company/add-department', data).then(res => res.data)
}

export const addUserToDepartment = async (data: any, departmentId: string) => {
	return await noMonitor.post('/company/assign-department', {data, departmentId}).then(res => res.data)
}

export const addDataDescription = async (dataId: string, description: string) => {
	return await noMonitor.post(`/data/add-description`, { dataId, description }).then(res => res.data)
}

export const updateCalendarSettings = async (data: any) => {
	return await noMonitor.post('/calendar/update-settings', data)
}

export const postDataNote = async (dataId: any, message: any) => {
	return await noMonitor.post('/data/add-notes', { dataId, message }).then(res => {
		console.log(res.data)
		return res
	})
}

export const updateDataActions = async (dataId: any, actions: any) => {
	monitoredRequest.post(`/data/update-actions/${dataId}`, { actions })
}

export const deleteDataNote = async (data: any) => {
}

export const getDataNotes = async (dataId: any) => {

}

export const removeIntegration = async (integration: string) => {
	await monitoredRequest.post(`/user/integrations/remove`, { integration: integration }).then(() => {
		//Remove integration from user object in store
		dispatch({ type: REMOVE_INTEGRATION, payload: integration })
	})
}

export const retrieveCompanyUserStats = async (companyId: any) => {
	return await noMonitor.post(`/company/user-stats`, companyId).then(res => res.data)
}

const apis = {
	addDataDescription,
	deleteAccount,
	addUserToDepartment,
	createAffiliateCode,
	aiCommentAnalysis,
	uploadGlobalAsset,
	getCustomerPortal,
	getStoreInfo,
	addUserToCompany,
	getCompanyList,
	revokeInvitation,
	acceptInvitation,
	rejectInvitation,
	addCompany,
	getDataTypes,
	switchCompany,
	getActivityStreamLog,
	getAllUsers,
	getStream,
	logoutUser,
	registerUser,
	setCurrentUser,
	setUserLoading,
	getSystemNotes,
	retrieveQueriesByID,
	insertQueryHistory,
	getLocalWeather,
	postAvatar,
	sendUserReg,
	getLocalNews,
	getUserConversations,
	getConversation,
	postMessage,
	unlockUser,
	getCompanyInformation,
	getCompanyInformationName,
	authoriseLinkedIn,
	companyCheck,
	getLargeProfile,
	postLogo,
	quoteToday,
	mailLogin,
	getProject,
	getDefaultWebStats,
	getAllGmail,
	getGmailCount,
	getGDriveItemsList,
	getGDriveFolderContent,
	postDeskImage,
	createPaymentIntent,
	refreshAchievements,
	addTwofa,
	retrieveCompanyBillingInfo,
	passwordReset,
	dispatchAppAction,
	validatePasswordReset,
	getLoginMessages,
	getData,
	getProjects,
	addProjects,
	updateProject,
	getProjectSchema,
	getAddNetworkSchema,
	getTaskSchema,
	getCalendarSchema,
	retrieveUserBillingInfo,
	getProducts,
	getSession,
	getSampleSchemas,
	saveData,
	getDataContent,
	handleOAuth,
	paymentSheet,
	getSubscriptions,
	getIntegrations,
	authoriseGMail,
	addDataItem,
	getProfileSchema,
	addUserTags,
	updateUserProfile,
	updateNotificationToken,
	getAvatarById,
	addTask,
	searchUsers,
	getReport,
	markRead,
	getTags,
	addCalendarEntry,
	getCalendarContent,
	getProductList,
	getWPSchema,
	addWorkPackage,
	deleteDataItem,
	deleteProject,
	getProfileCompletion,
	getProjectCount,
	getDataCount,
	saveNewsItem,
	getSavedNews,
	addCalendar,
	sendGoogleMail,
	addStickyNote,
	modifyStickyNote,
	removeStickyNote,
	getStickyNotes,
	markNoteComplete,
	setNoteColor,
	getInitialModels,
	postInitialModel,
	getGoogleCalendarList,
	getStandardisedReports,
	validate2FA,
	validateOTP,
	getDataSchema,
	setTutorial,
	getRoomCounts,
	getRoom,
	updateTC,
	getActivityLog,
	getAchievementHistory,
	shareDataItem,
	getUser,
	getInvitations,
	getInvitationCount,
	getRemainingProfile,
	getPublicData,
	acceptCompanyInvitation,
	updateDataItem,
	uploadData,
	setPublicDataSwipeTutorial,
	setInvitationSwipeTutorial,
	addLocation,
	hideFirstTutorial,
	saveEmailTemplate,
	getEmailTemplates,
	getInvoiceTemplates,
	getDocumentTemplates,
	getInitialTemplates,
	deletePost,
	getEmailTemplate,
	editEmailTemplate,
	deleteEmailTemplate,
	getServiceList,
	getUserById,
	changeWPStatus,
	changeWorkPackageTitle,
	deleteWorkPackage,
	changeTaskTitle,
	changeTaskDescription,
	deleteTask,
	changeTaskStatus,
	changeProjectTitle,
	changeProjectDescription,
	shareProject,
	addFavourite,
	getDataFavorites,
	refreshUser,
	addTagsToData,
	generateBusinessCard,
	addLocalCalendars,
	addDataImage,
	getDataContentById,
	getAddInvoiceTemplateSchema,
	postInvoiceTemplate,
	getFeedbackSchema,
	getFeedback,
	postFeedback,
	getProductServiceList,
	searchRegEx,
	getIntegrationApiAction,
	createStorePaymentIntent,
	getStripeId,
	registerStripeLocation,
	registerStripeReader,
	confirmPaymentIntent,
	getAIForm,
	getFeed,
	postToFeed,
	addLikeToPost,
	addShareToPost,
	removeLikeFromPost,
	removeShareFromPost,
	addCommentToPost,
	getAICommentAnalysis,
	getExtendedData,
	getUserHierarchy,
	getCompanySchema,
	storeDeviceToken,
	getDataFolders,
	getFolderStats,
	replaceSchema,
	getDataItemById,
	retrieveProduct,
	getCheckoutSession,
	syncCalendars,
	syncCalendarEvents,
	getCalendars,
	makeDataPublic,
	updateWorkPackage,
	getKnowledgeBase,
	genEmail,
	getContributionChart,
	getAIReportQuery,
	searchMail,
	googleAuthCallback,
	buildLink,
	sendInvoice,
	getInventoryData,
	getAddRoomSchema,
	getSalesStats,
	getCrmCount,
	getActiveCampaigns,
	postNewCampaign,
	getDefaultEmailTemplates,
	postInternalKnowledge,
	postExternalKnowledge,
	getInternalKnowledge,
	deleteKnowledgeArticle,
	saveKnowledgeContent,
	publishKnowledgeArticle,
	getKnowledgeContent,
	getTeam,
	getCRMFolders,
	getTemplates,
	updateWorkPackageDue,
	getCampaign,
	changeTaskNotes,
	searchDataRegEx,
	handleChangeTaskDescription,
	handleChangeTaskTitle,
	changeTaskPosition,
	modifyTask,
	googleOAuth,
	getDataTemplateCount,
	updateEmailTemplate,
	setEmailGlobal,
	getGlobalAssets,
	getTemplateById,
	getTargetAudience,
	revokeGoogleScope,
	searchCompanies,
	googleMapSearch,
	getUserRecents,
	addRecent,
	getTrending,
	getUserStats,
	getUserStatsData,
	getKnowledgeByViews,
	cloneData,
	transferData,
	cancelUserCreation,
	userLoginHistory,
	getCrmItems,
	uploadDataImage,
	getAllFolderStats,
	getMainCharts,
	requestDataDownload,
	getStartupSchemas,
	startCampaign,
	changeDataTitle,
	requestLastPage,
	requestNextPage,
	searchGmail,
	addDepartment,
	updateCalendarSettings,
	postDataNote,
	deleteDataNote,
	updateDataActions,
	removeIntegration,
	retrieveCompanyUserStats,
	getAffiliateHistory,
}
export default apis;

