import { Pressable, KeyboardAvoidingView, Platform, Keyboard, Alert } from "react-native"
import { Formik, FormikProps, Field, setNestedObjectValues, FormikTouched, FormikHelpers, useField, FastField, FieldProps } from "formik";
import { Text, View } from "@constants/Themed";
import React, { forwardRef, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import styles from "@stylesheet";
import { ScrollView } from "react-native-gesture-handler";
import useGlobalUser from "./getGlobalUser";
import { ProgressBar, TextInput } from "react-native-paper";
import Button from "@components/UI/Button/Button";
import HandleFieldType from "./handleFieldType";
import AuthModal from "@components/modals/ErrorModal/AuthModal";
import { FormValues } from "@components/Global/types";
import parseByDelimiter from "@utils/parseByDelimiter";
import generateYupValidation from "./generateYupValidation";
import * as Animatable from 'react-native-animatable';
import { findIndex, initial, set } from "lodash";
import AnimatedLottieView from "@utils/lottieAnimation";
import LottieView from "@utils/lottieAnimation";
import Row from './rowComp';

interface SchemaObject {
  /**
   * @param schema - The schema object to be generated
   */
  schema: object,
  /**
   * @param staged - Whether the form is staged, introduce one field at a time
   */
  staged? : boolean,
  /**
   * @param submitFunction - The function to be called when the form is submitted
   */
  submitFunction?: Function,
  /**
   * @param nullify - The fields to be nullified
   */
  nullify?: Array<string>,
  /**
   * @param sheetRef - The reference to the bottom sheet
   */
  sheetRef?: RefObject<FormikHelpers<any>>,
  /**
   * @param other - The id of the data to be submitted, this should be the _id of the document
   */
  other?: any,
  /**
   * @param otherVals - The other values to be passed into special fields
   */
  otherVals?: {}
  /**
   * @param callback - The callback function to be called after submission
   * @requires state - The state to be updated after submission
   * @requires setState - The function to update the state
   */
  callback?: { state: boolean, setState: Function },
  /**
   * @param dispatch - The dispatch function to be called after submission
   */
  dispatch?: (_id: string)=> void,
  /**
   * @param internalButton - Whether the button is internal
   */
  internalButton?: boolean,
  /**
   * @param buttonLabel - The label of the button
   */
  buttonLabel?: string,
  /**
   * @param buttonStyle - The style of the button
   */
  buttonStyle?: any,
  /**
   * @param avoidKeyboardDisabled - Whether the keyboard should be avoided
   * @description This is only applicable for mobile devices
   * @default false
   */
  avoidKeyboardDisabled?: boolean,
  /**
   * @param Progress - Whether to display the ProgressBar bar
   * @default false
   */
  Progress?: boolean,
  /**
   * @param noBackground - Whether to display the background
   * @default false
   */
  noBackground?: boolean,
  /**
   * @param retainInitialValues - Should passed values be kept after submission
   * @default false
   */
  retainInitialValues?: boolean,
  /**
   * @param initialData - The initial data passed to the form in the form of an object
   */
  initialData?: any,
  addOffset?: number,
  /**
   * @param hasTitle - Whether the schema has a title
   * @default false
   */
  hasTitle?: boolean,
  /**
   * @param columns - The number of columns to be displayed
   */
  columns?: number,
  /**
   * @param extraFunction - The extra function to be called after submission
   * @default null
   * */
  extraFunction?: Function,
  customStyling?: Object,
  addPadding?: boolean,
  textColor?: string,
  extraCallBack?: Function,
  extraId?: string,
  buttonColor?: string,
  hideButton?: boolean,
  removeSubmit?: boolean,
  bottomPadding?: number,
  containerWidth?: number,
  /**
   * @param extraValues - The extra values to be passed to the submit function
   * @default {}
   */
  extraValues?: Object,
  /**
   * @param resetOnSubmit - Whether to reset the form on submit
   * @default false
   */
  resetOnSubmit?: boolean
}

const GenerateSchemaForm = forwardRef<FormikProps<any>, SchemaObject>((props, ref) => {
  const [schemaArray, setSchemaArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(props.initialData || {});
  const id = useGlobalUser();
  const combinedIds = { globalId: id, dataId: props.other, extraId: props.extraId || null };
  const inputRefs = useRef({});
  const isDevice = Platform.OS !== 'web';
  const [columns, setColumns] = useState(1);
  const [reset, setReset] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  console.log(initialValues, 'initialValues');
  // useEffect(() => {
  //   if (props.initialData) {
  //     setInitialValues(props.initialData)
  //   }
  // }, [props.initialData, props.schema])

  useLayoutEffect(() => {
    if (props.containerWidth && props.columns > 1)
      calculateColumns(props.containerWidth);
  }, [])

  const calculateColumns = (containerWidth = 420) => {
    if(props.columns && containerWidth > 350){
      //Try to force columns unless container width is less than 350
      setColumns(props.columns);
      return;
    }
    const maxColumnWidth = 420; // Maximum width for each column
    if (Platform.OS === 'web') {
      setColumns(Math.floor(containerWidth / maxColumnWidth));
    } else {
      setColumns(1);
    }
  };

  const RenderRows = ({ otherVals, schemaArray, nextFieldFunction, setInputRef }) => {
    if (columns <= 1) {
      return <Row otherVals={otherVals} column allItems={schemaArray} items={schemaArray} index={0} nextFieldFunction={nextFieldFunction} setInputRef={setInputRef} />
    }
    const rows = [];

    for (let i = 0; i < schemaArray?.length; i += columns) {
      rows.push(<Row otherVals={otherVals} column={false} key={i} allItems={schemaArray} items={schemaArray.slice(i, i + columns)} index={i} nextFieldFunction={undefined} setInputRef={undefined} />);
    }
    return rows;
  }


  useEffect(() => {
    const processSchema = () => {
      return new Promise((resolve, reject) => {
//Rearrange schema array if it contains images or image to bring that to the top: 
        let moddedSchema = [];

        
        //Add check to see if schemaArray already exists
        if (schemaArray.length > 0) {
          resolve(initialValues);
          return;
        }

        let initVals: any = {};
        let newSchemaArray: any = [];
        Object?.keys(props.schema)?.forEach((key) => {
          if (!key) return;
          try {
            const value = props.schema[key];
            if (!value) {
              return;
            }
            const newItem = value;
            if (newItem.title) {
              if (props?.nullify && props?.nullify?.includes(newItem?.title)) {
                return;
              } else {
                //If item does not exist in the schema array, add it
                if (!schemaArray.find((item) => item.title === newItem?.title)) {
                  newSchemaArray.push(newItem);
                }
                if (newItem?.type === 'number') {
                  initVals[newItem?.title] = 0;
                }
                if (newItem?.type === 'date') {
                  initVals[newItem?.title] = new Date();
                }
                if (newItem?.type === 'dateTime') {
                  initVals[newItem?.title] = new Date();
                }
                if (newItem?.type === 'dropDown') {
                  initVals[newItem?.title] = '';
                }
                if (newItem?.type === 'image') {
                  initVals[newItem?.title] = '';
                }
                if (newItem?.type === 'array') {
                  initVals[newItem?.title] = [];
                }
                if (newItem?.type === 'object') {
                  initVals[newItem?.title] = {};
                }
                if (newItem?.type === 'boolean') {
                  initVals[newItem?.title] = false;
                } else {
                  initVals[newItem?.title] = '';
                }
              }
            } else {
              //Check if key exists in nullify array
              if (props?.nullify && props?.nullify?.includes(key)) {
                return;
              } else {
                //If item does not exist in the schema array, add it
                if (!schemaArray.find(item => item.title === newItem.title)) {
                  newSchemaArray.push({ title: key, type: newItem.type || newItem.ref || "string", ref: newItem.ref || undefined, validation: newItem.validation, content: newItem.content });
                }
                if (newItem?.type === 'number') {
                  initVals[key] = 0;
                } else if (newItem?.type === 'date') {
                  initVals[key] = new Date();
                } else if (newItem?.type === 'dateTime') {
                  initVals[key] = new Date();
                } else if (newItem?.type === 'dropDown') {
                  initVals[key] = '';
                } else if (newItem?.type === 'image') {
                  initVals[key] = '';
                } else if (newItem?.type === 'array') {
                  initVals[key] = [];
                } else if (newItem?.type === 'object') {
                  initVals[key] = {};
                } else if (newItem?.type === 'boolean') {
                  initVals[key] = false;
                } else if (newItem?.type === 'tags') {
                  initVals[key] = [];
                } else {
                  initVals[key] = '';
                }
              }

            }
          } catch (error) {
          }
        });
        setSchemaArray(newSchemaArray);
        // setInitialValues(initVals);
        resolve(initVals);
      });
    };

    const initializeSchema = async () => {
      setLoading(true);
      try {
        if (props.retainInitialValues && props.initialData) {
          setInitialValues(props.initialData);
        } else {
          await processSchema().then(res => {
            if (!props.retainInitialValues) {
            }
          })
        }
      }
      catch (error) {
        console.error('Error initializing schema', error);
      } finally {
        setTimeout(() => { setLoading(false) }, 1000);
      }
    };
    processSchema();
    initializeSchema();
  }, []);

  // Generate Yup validation schema
  const validationSchema = generateYupValidation(schemaArray);

  // Display form errors
  const displayErrors = (errors) => {
    let errorString = '';
    Object.keys(errors).forEach((key) => {
      const value = errors[key];
      errorString += parseByDelimiter(key).toString() + ' ' + value + '\n';
    });
    return errorString;
  };

  // Focus on next field
  const handleFocusNextField = useCallback((nextField, index, length) => {
    const currentRef = inputRefs.current[nextField];
    if (currentRef && index < length - 1) {
      currentRef.current?.focus();
    } else {
      Keyboard.dismiss();
    }
  }, [inputRefs]);

  // Set input reference
  const setInputRef = (fieldName, ref) => {
    inputRefs.current[fieldName] = ref;
  };

  return useMemo(() => (
    <View style={[{ backgroundColor: 'transparent', flex: 1, minHeight: Platform.OS !== 'web' ? "80%" : undefined, minWidth: '100%', alignSelf: "stretch", paddingBottom: props.addPadding ? 250 : undefined }, props.customStyling]}>
      <AuthModal />
      <Formik
        initialValues={initialValues}
        key={'form'+initialValues?.length}
        innerRef={ref}
        enableReinitialize
        validateOnChange={schemaArray.length > 15 ? false: true}
        validationSchema={validationSchema}
        onSubmit={async (values, helpers) => {
          try {
            setSubmitDisabled(true);
            if (props.extraFunction) {
              props.extraFunction(values);
            }
            const submitFunction = props.submitFunction;
            const hasCallback = !!props.callback;
            const hasSheetRef = !!props.sheetRef;
            await submitFunction?.({...values, ...props.extraValues}, props.other ? combinedIds : id).then((res) => {
              if (hasCallback) {
                props.callback.setState(!props.callback.state);
              }
              if (hasSheetRef && props.sheetRef?.current) {
                props.sheetRef.current?.snapToIndex(0);
              }
              if (props.extraCallBack) {
                props.extraCallBack(res.data);
              }
              if (props.resetOnSubmit) {
                try {
                  helpers.resetForm();
                  helpers.setValues({});
                  if (props.resetOnSubmit) {
                    setReset(!reset);
                    helpers.setFormikState(prevState => ({ ...prevState, values: {} }))
                  }
                } catch (e) {
                  console.error(e);
                }
              }
            }).finally(() => {
              setSubmitDisabled(false);
              if (props.resetOnSubmit)
                ref.current!.resetForm?.()
            });
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {({ validateForm, resetForm, handleChange, setFormikState, setSubmitting, handleSubmit, errors, values }) => {
          const handleUpdateProgressBar = () => {
            const singleValue = 1 / schemaArray?.length;
            let ProgressBar = 0;
            if (!values) return;
            Object.entries(values).forEach(([key, value]) => {
              if (value !== 'null' && !props.nullify?.includes(key)) {
                if (Array.isArray(value)) {
                  if (value?.length < 1) {
                    return;
                  } else {
                    ProgressBar += singleValue;
                  }
                }
                if (value == null || value == '' || !value) {
                  return;
                } else {
                  ProgressBar += singleValue;
                }
              }
            });
            return ProgressBar;
          };

          return (
            <View style={{ flex: 1, backgroundColor: "transparent", gap: 20 }}>
              {props.Progress && Platform.OS === 'web' &&
                <View style={{ flex: 1, maxHeight: 15, alignSelf: "center", flexDirection: 'row', maxWidth: '70%', justifyContent: 'center', gap: 20, marginVertical: 10 }}>
                  <View style={{ minHeight: 10, maxWidth: '100%' }}>
                    <ProgressBar progress={handleUpdateProgressBar()} style={{ minHeight: 10, minWidth: isDevice ? '100%' : 250, borderRadius: 10, top: 5, flex: 1 }} color={"rgb(90,192,234)"} />
                  </View>
                </View>
              }
              <KeyboardAvoidingView style={{ overflow:"visible", gap: 30, flex: 12, minHeight: '90%', backgroundColor: 'transparent' }} keyboardVerticalOffset={120} behavior="padding" enabled={isDevice && props.avoidKeyboardDisabled} >
                <ScrollView 
                
                showsVerticalScrollIndicator={false} contentContainerStyle={{
                  backgroundColor: 'transparent',
                  gap: 20,
                  flexGrow: 1,
                  overflow:"visible",
                  alignItems: 'flex-start', // Center items horizontally
                  justifyContent: 'flex-start', // Center items vertically if needed
                }} showsHorizontalScrollIndicator keyboardShouldPersistTaps='handled' nestedScrollEnabled style={{ flex: 3, alignSelf: 'center', minWidth: '100%', maxHeight: '90%' }}>
                  <RenderRows otherVals={props.otherVals} nextFieldFunction={handleFocusNextField} setInputRef={setInputRef} schemaArray={schemaArray} />
                </ScrollView>
                {!props.internalButton && !props.removeSubmit && !props.hideButton &&
                  <Animatable.View animation={"fadeIn"} style={[styles.centerEverything, { maxHeight: 80, marginVertical: 10, flex: 1, backgroundColor: 'transparent' }]}>
                    <Button disabled={submitDisabled} customStyle={{ minHeight: 40, minWidth: 200 }} defaultColor={props.buttonColor ? (Object.keys(errors)?.length != 0 ? 'grey' : props.buttonColor) : undefined} onPress={() => {
                      setSubmitting(true);
                      validateForm().then(res => {
                        if (res && Object.keys(res)?.length != 0) {
                          setFormikState(prevState => ({ ...prevState, touched: setNestedObjectValues<FormikTouched<FormValues>>(errors, true) }));
                          Alert.alert(displayErrors(res));
                        } else {
                          handleSubmit();
                        }
                      })
                    }} buttonText={props.buttonLabel || 'Submit'} />
                  </Animatable.View>
                }
              </KeyboardAvoidingView>
            </View>
          );
        }}
      </Formik>
    </View>
  ), [schemaArray, columns, loading, initialValues, props.initialData, validationSchema, ]);
});

export default GenerateSchemaForm;
