import React, { useEffect } from 'react'
import { AnimatePresence } from '@tamagui/animate-presence'

import { Button, H1, H2, Image, SizableStack, SizableText, View, XStack, XStackProps, YStack, styled } from 'tamagui'
import { AntDesign } from '@expo/vector-icons'
import { t } from 'i18next'
import { Title } from '@constants/Themed'

const GalleryItem = styled(YStack, {
  zIndex: 1,
  x: 0,
  opacity: 1,
  fullscreen: true,

  variants: {
    // 1 = right, 0 = nowhere, -1 = left
    going: {
      ':number': (going) => ({
        enterStyle: {
          x: going > 0 ? 1000 : -1000,
          opacity: 0,
        },
        exitStyle: {
          zIndex: 0,
          x: going < 0 ? 1000 : -1000,
          opacity: 0,
        },
      }),
    },
  } as const,
})

const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

function toSentenceCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

type CarouselContent = { title: string; descriptor: string }[];

export default function HorizontalTextCarousel({ content, height, viewStyle }: { content: CarouselContent, height?: number, viewStyle?: XStackProps }) {
  const [[page, going], setPage] = React.useState([0, 0])
  const [carouselContent, setCarouselContent] = React.useState(content)

  const contentIndex = wrap(0, content?.length || 0, page)
  const paginate = (going: number) => {
    setPage([page + going, going])
  }

  useEffect(() => {
    const interval = setInterval(() => {
      paginate(1)
    }, 6000) // Change the interval time as needed

    return () => clearInterval(interval)
  }, [page])

  return content && (
    <XStack
      overflow="hidden"
      position="relative"
      height={height || 300}
      width="100%"
      alignItems="center"
      flex={1}
      {...viewStyle}
    >
      {
        content?.length > 1 &&
        <View style={{ flex: 1 }}>
          <Button
            icon={<AntDesign name="arrowleft" size={24} color="white" />}
            size="$3"
            top="$7"
            zIndex={10}
            left="$4"
            circular
            elevate
            onPress={() => paginate(-1)}
          />
        </View>
      }
      <SizableStack flex={10} left={20} paddingHorizontal={40}>
        <AnimatePresence initial={false} custom={{ going }}>
          <GalleryItem key={page} animation="slow" going={going}>
            <Title selectable={false}>{toSentenceCase(t(content[contentIndex].title))}</Title>
            <SizableText textWrap='wrap' style={{ paddingRight: 20 }} userSelect='none'>{t(content[contentIndex].descriptor)}</SizableText>
          </GalleryItem>
        </AnimatePresence>
      </SizableStack>

      {
        content.length > 1 &&
        <View style={{ flex: 1 }}>
          <Button
            icon={<AntDesign name="arrowright" size={24} color="white" />}
            size="$3"
            top="$7"
            left="-$4"
            zIndex={10}
            circular
            elevate
            onPress={() => paginate(1)}
          />
        </View>
      }
    </XStack >
  )
}