import React, { memo, useState } from 'react';
import { Text } from '@constants/Themed';
import { useSelector } from 'react-redux';
import { Image, Pressable, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { LinearGradient } from 'expo-linear-gradient';
import LottieView from '@utils/lottieAnimation';
import styles from '@stylesheet';
import toTitleCase from '@hooks/toTitleCase';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { SmartSaaSIcon } from '@assets/--native';
import { Circle, Marker } from 'react-native-maps';

const MarkerLoad = ({ zoomLevel = 12, userData = { profile_information: { name: "Saf", surname: "Hussain", avatar: "https://storage.googleapis.com/smartsaas-avatar-store/Asset%202.png" }, achievements: { achievementPoints: 100 } } }) => {
  const [open, setOpen] = useState(false);
  const theme = themeSelector();


  const markerHeight = zoomLevel <= 3 ? 200 : 100;
  const markerTop = -1 * (markerHeight / 2);


  return (
   
      <Pressable onPress={() => { setOpen(!open); console.log('hi hi ') }} hitSlop={100} style={{ flex: 1, transform: [{ scale: open ? 1:  Math.max(0.3, 1 - zoomLevel / 10) }], position: 'relative', bottom: 0, alignSelf:'flex-end' }}>
        <Animatable.View transition={['height', 'width', 'top']} style={[styles.centerEverything, { padding: 4, paddingBottom: 0, borderRadius: 20, height: zoomLevel <= 3 || open ? 200 : 100, width: zoomLevel <= 3 || open ? 250 : 100, backgroundColor: 'rgba(90, 192, 234, 0.7)', top: markerTop }]}>
          <LinearGradient colors={[theme ? 'white' : 'black', theme ? 'transparent' : 'black']} style={{ backgroundColor: '#ececec', borderRadius: 20, flex: 1, alignSelf: 'stretch', flexDirection: 'row', padding: 5, paddingTop: 3,  pointerEvents:'none' }}>
            {zoomLevel <= 3 || open ?
              <>
                <View style={{ flex: 1, borderRadius: 20, padding: 10, top: 5 }}>
                  <Image
                    source={{ uri: userData.avatar?.img || "https://storage.googleapis.com/smartsaas-avatar-store/Asset%202.png" }}
                    style={{ height: 100, width: 75, resizeMode: 'contain', alignSelf: 'stretch' }}
                  />
                  <Text >{toTitleCase(userData.profile_information.name || "")}</Text>
                  <Text >{toTitleCase(userData.profile_information.surname || "")}</Text>
                </View>
                <View style={[styles.centerEverything, { flex: 0.4, alignSelf: 'center' }]}>
                <LottieView source={require('@assets/animations/achievementTrophy.json')} style={{ width: '100%' }} autoPlay loop={false} />
                  <Text adjustsFontSizeToFit style={{ fontWeight: 'bold' }}>{userData?.achievements.achievementPoints}</Text>
                  <View style={{ flex: 0.1 }} />
                  <Pressable onPress={() => { console.log('storefront pressed') }}>
                  <LottieView source={require('@assets/animations/85489-business-front.json')} style={{ width: '150%' }} autoPlay loop={false} />
                  </Pressable>
                </View>
              </>
              :
              <SmartSaaSIcon height={80} width={80} />
            }
          </LinearGradient>
          <View style={{ transform: [{ rotateZ: '45deg' }], width: 50, height: 50, backgroundColor: '#ececec', marginTop: -40, zIndex: -1, pointerEvents:'none' }}/>
        </Animatable.View>
      </Pressable>
  )
};

export default memo(MarkerLoad);
