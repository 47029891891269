import { FontAwesome } from "@expo/vector-icons"
import React, { useCallback, useEffect, useState } from "react"
import { Animated, Text, View } from "react-native"
import { Pressable } from 'react-native';
import { useDispatch, useSelector } from "react-redux"
import { CLOSE_TAB, SWITCH_TAB } from "@reduxLocal/actions/types"
import styles from "@stylesheet"
import * as Animatable from 'react-native-animatable';
import { LinearGradient } from "expo-linear-gradient";
import { DefaultRootState } from "@reduxLocal/persistState";
import { useNavigation, useRoute } from "@react-navigation/native";

const navigateToScreen = (navigation, route, screenObject) => {
  const { page } = screenObject;



  if (!page || !route) {
    console.error("Invalid screen object");
    return;
  }
  console.log(route, 'is the route');

  const { key, params } = route;

  navigation.navigate(page, {
    screen: key,
    params: params
  });
};

function splitByCapitals(string: string) {
  //Format to split string by each occurrence of a capital letter!
  return string.split(/(?=[A-Z])/).join(" ");
}

export default function Tabs({ tabs, activeTab, currentTab }) {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const currentScreen = useSelector((state: DefaultRootState) => state.navigation.page);
  const route = useRoute();

  const getParams = useCallback((screenObject) => {
    const { route } = screenObject;
    if (!route) {
      return;
    }
    return route.routes[0];
  }, [currentScreen]);

  const switchTab = () => {
    dispatch({ type: SWITCH_TAB, payload: tabs.id });
    navigateToScreen(navigation, route, tabs);
  };

  const closeTab = async () => {
    await dispatch({ type: CLOSE_TAB, payload: tabs.id });
    navigation.replace(activeTab[0].page);
  };

  const calculateFontSize = (tabName: string): number => {
    const baseFontSize = 20;
    const maxLength = 12;

    if (tabName.length <= maxLength) {
      return baseFontSize;
    }

    return baseFontSize * (maxLength / tabName.length);
  };


  return (
    <Pressable
      style={{ flex: 1, alignSelf: "stretch", minHeight: 40 }}
      onPress={switchTab}
      hitSlop={20}
      >
      {({ hovered }) => (
      <LinearGradient colors={['#1E90FF', 'transparent']} style={{ flex: 1, alignSelf: "stretch" }}>
          <Animatable.View transition={['marginTop']} style={[activeTab[0]?.id === tabs?.id ? (hovered ? styles.tabHovered : styles.activetab) : (hovered ? styles.tabHovered : styles.tab), { alignSelf: "stretch", flex: 1, paddingTop: 10 }]}>
            <View style={{ flex: 1, overflow: "hidden" }}>
              {
                getParams(tabs)?.state?.routes[currentTab]?.params &&
                <Animatable.Text animation={'slideInDown'} style={{ userSelect:"none", textAlign: "center", fontSize: calculateFontSize(getParams(tabs)?.state?.routes[currentTab]?.params?.title || 12) }}>
                  {('title' in getParams(tabs)?.state?.routes[currentTab]?.params) ?
                    splitByCapitals(getParams(tabs)?.state?.routes[currentTab]?.params.title) :
                    ""}
                </Animatable.Text>
              }
              <Text numberOfLines={1} style={{ userSelect:"none", fontWeight: "bold", textAlign: "center", fontSize: calculateFontSize(tabs.page), flex: 3 }}>{splitByCapitals(tabs.page)}</Text>
            </View>
          {currentTab > 1 && activeTab.length > 0 &&
            <Pressable style={{ flex: .3 }} onPress={closeTab}>
              <FontAwesome name={"close"} size={20} color={'rgba(0,0,0,0.25)'} />
            </Pressable>
            }
        </Animatable.View>
        </LinearGradient>
      )}
      </Pressable>
  );
}

