import React from "react";
import { View } from '@constants/Themed';
import { ActivityIndicator, Platform } from 'react-native';

const LoadingIndicator = ({ type = 'medium', color, ...props }: { type?: string, color: string}) => {
  if (type = 'processing') {
      return (
        <ActivityIndicator {...props} size={Platform.OS != 'web' ? "small" : 'large'} color={color || "#61c0ec"}></ActivityIndicator>
      )
  } else {
    return (
      <View></View>
    )
  }
}

export default LoadingIndicator;