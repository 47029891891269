import AsyncStorage from '@react-native-async-storage/async-storage';

const QUEUE_KEY = 'offlineQueue';

export const addToQueue = async (request) => {
	const currentQueue = await getQueue();
	currentQueue.push(request);
	await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(currentQueue));
};

export const getQueue = async () => {
	const queue = await AsyncStorage.getItem(QUEUE_KEY);
	return queue ? JSON.parse(queue) : [];
};

export const clearQueue = async () => {
	await AsyncStorage.removeItem(QUEUE_KEY);
};

export const removeProcessedRequest = async () => {
	const currentQueue = await getQueue();
	currentQueue.shift();
	await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(currentQueue));
};
