import styles from '@stylesheet';
import React from 'react';
import { View, Text, ScrollView, Platform } from 'react-native';
import { Card, H2, SizableText, Button } from 'tamagui';

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error('GlobalErrorBoundary caught an error:', error);
    this.setState({ hasError: true, error });
    // You can also log the error to an error reporting service here
  }

  makeStackTrace() {
    //Generate a stack trace and send it to the server

  }

  render() {
    if (this.state.hasError) {
      return Platform.OS === 'web' ? (
        <View style={[styles.centerEverything, { flex: 1, justifyContent: 'center', gap: 20 }]}>
          <Card>
            <View style={[styles.centerEverything, { gap: 20, borderRadius: 20, shadowOpacity: .2, shadowRadius: 20, padding: 30 }]}>
              <H2 style={{ fontFamily: "fantasy", fontSize: 36, fontWeight: 'bold', textAlign: "center" }}>Oops, looks like something went horribly wrong!</H2>
              <SizableText>We are sorry about that and appreciate your patience, reloading the application will help but rest assured we are on it!</SizableText>
              <ScrollView style={{ maxHeight: "60%" }}>
              <SizableText>Error: {this.state.error?.message}</SizableText>
              </ScrollView>
              <Button onPress={() => window.location.reload()}>Reload</Button>
            </View>
          </Card>
        </View>
      ) : (
        <View style={[styles.centerEverything, { flex: 1, justifyContent: 'center', gap: 20 }]}>
          <Card>
            <H2 style={{ fontFamily: "fantasy", fontSize: 36, fontWeight: 'bold', textAlign: "center" }}>Oops, looks like something went horribly wrong!</H2>
            <SizableText>An error occurred</SizableText>
          </Card>
        </View>
      )
    }
    return this.props.children;
  }
}
export default GlobalErrorBoundary;