import React, { forwardRef } from "react";
import { actions, RichToolbar } from "react-native-pell-rich-editor";
import { Text } from 'react-native';
import handleImageUpload from "@utils/handleCamera";
import { Platform } from "react-native";
import { backgroundColor } from "react-native-calendars/src/style";
import { borderTopStyle } from "html2canvas/dist/types/css/property-descriptors/border-style";
import { borderBottomLeftRadius } from "html2canvas/dist/types/css/property-descriptors/border-radius";

interface editorProps {
  vertical?: boolean;
  backgroundColor?: string;
  style?: object;
  actions?: any[];
  iconTint?: string;
  omitImage?: boolean;
  omitVideo?: boolean;
}

const ToolBarComponent = forwardRef((props: editorProps, ref) => {
  const { omitImage, vertical, omitVideo, actions: customActions, iconTint } = props;
  const handleHead = ({ tintColor }) => <Text style={{ color: tintColor }}>H1</Text>
  const handleHead2 = ({ tintColor }) => <Text style={{ color: tintColor }}>H2</Text>
const handleHead3 = ({ tintColor }) => <Text style={{ color: tintColor, fontSize: 20 }}>f</Text>

  return (
    <RichToolbar
      editor={ref}
      backgroundColor={"rgba(33,150,243,1)"}
      vertical={vertical || false}
      flatContainerStyle={{ gap: 10, borderTopStartRadius: 20, borderTopEndRadius: !vertical && 20, borderBottomLeftRadius: vertical && 20, backgroundColor: "rgba(33,150,243,1)" }}
      actions={customActions || [
        undefined,
        actions.undo,
        actions.setBold,
        actions.setItalic,
        actions.setUnderline,
        actions.fontSize,
        actions.fontName,
        actions.heading1,
        actions.heading2,
        actions.alignCenter,
        actions.insertBulletsList,
        actions.insertOrderedList,
        actions.insertLink,
        actions.code,
        actions.checkboxList,
        actions.setStrikethrough,
        !omitImage && actions.insertImage,
        !omitVideo && actions.insertVideo,
        undefined
      ]}
      iconTintColor={iconTint || "white"}
      iconMap={{ [actions.heading1]: handleHead, [actions.heading2]: handleHead2, [actions.fontName]: handleHead3 }}
      actionsConfig={{}}
      style={[{ flex: 1, maxWidth:40, flexDirection: 'column', backgroundColor: "rgba(33,150,243,1)", paddingVertical: Platform.select({ default: 20, web: undefined }) }, props.style]}
      onPressAddImage={() => handleImageUpload().then((image) => ref.current?.insertImage(image))}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    />
  )
});

export default ToolBarComponent;
