import React, { useRef, useEffect, forwardRef } from 'react';
import Lottie from 'lottie-react-native'

const LottieAnimation = forwardRef((props, ref) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    return () => {
      if (lottieRef.current) {
        lottieRef.current.stop();
        lottieRef.current.destroy(); // Destroy animation instance
      }
    };
  }, []);

  return (
    <Lottie
      {...props}
      ref={ref || lottieRef}
    />
  );
});

export default LottieAnimation;
