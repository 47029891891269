import AnimatedLottieView from "@utils/lottieAnimation";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { View, Text, Platform, KeyboardAvoidingView, SafeAreaView, ScrollView, Keyboard, Pressable } from "react-native";
import { actions, RichEditor, RichToolbar } from "react-native-pell-rich-editor";
import { Title } from "@constants/Themed";
import { MaterialIcons } from '@expo/vector-icons';
import styles from "../../../../stylesheet";
import EditorToolbar from "../EditorToolbar/EditorToolbar";
import * as Animatable from 'react-native-animatable';

export interface EditorProps {
  placeHolder: string;
  knowledge?: boolean;
  initialContentHTML?: string;
  noBackground?: boolean;
  onFocus?: () => void;
  onChange?: (descriptionText: string) => void;
}

const TempScreen: React.ForwardRefExoticComponent<Omit<EditorProps, "ref"> & React.RefAttributes<unknown>> = forwardRef((props: any, ref) => {
  const { placeHolder, knowledge, initialContentHTML, noBackground, onFocus } = props;
    const [focused, setFocused] = useState(false);
    const toolbarAnim = useRef(null);
    const timeoutRef = useRef(null);

    const _handleSlideOut = () => {
      timeoutRef.current = setTimeout(() => toolbarAnim.current?.slideOutLeft(500).then(() => {
        setFocused(false);
      }), 3000);
    };

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    return (
      <View style={{ flex: 1 }}>
        {
          Platform.OS != 'web' &&
          <View style={{ flexDirection: 'row', flex: 1, minHeight: 50, justifyContent: 'center' }}>
            <Title style={{ padding: 10, height: 50 }}>{props.title || ""}</Title>
            <MaterialIcons name="keyboard-hide" size={24} color="black" style={styles.centerEverything} />
          </View>
        }
        <View style={{ flexDirection: "row", flex: 1 }}>
          {/* {
            Platform.OS === 'web' && focused &&
            <Animatable.View style={{ flex: 1, maxWidth: 40, borderRadius: 20, backgroundColor: "rgba(0,0,0,0.2)" }} ref={toolbarAnim} animation={'slideInLeft'}>
              <EditorToolbar
                actions={[
                  actions.undo,
                  actions.setBold,
                  actions.setItalic,
                  actions.setUnderline,
                  actions.setStrikethrough,
                  actions.heading1,
                  actions.alignLeft,
                  actions.alignCenter,
                  actions.insertBulletsList,
                  actions.insertOrderedList,
                  actions.setSuperscript,
                  actions.insertLink
                ]}
                vertical
                ref={ref}
                iconTint="black"
                backgroundColor='rgba(0,0,0,0.2)'
                style={{ borderRadius: 20 }} />
            </Animatable.View>
          } */}
          <Animatable.View style={{ flex: 3.5 }}>
            <RichEditor
              ref={ref as React.RefObject<RichEditor>}
              style={{ backgroundColor: "transparent", borderRadius: 20, flex: 1 }}
              hideKeyboardAccessoryView={true}
              onKeyDown={({ keyCode }) => {
                if (keyCode === 13 && knowledge) {
                  (ref as React.RefObject<RichEditor>).current!.insertText("\n");
                }
              }}
              onFocus={() => {
                onFocus?.();
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                }
                if (!focused) {
                  setFocused(true);
                }
              }}
              onBlur={_handleSlideOut}
              editorStyle={{ caretColor: "black", backgroundColor: "transparent" }}
              placeholder={placeHolder || "Type something.."}
              keyboardDisplayRequiresUserAction={true}
              initialContentHTML={initialContentHTML}
              containerStyle={{ flex: 1, backgroundColor: "transparent", borderWidth: 1, borderColor: 'rgba(90,192,234,0.8)', minHeight: 400, shadowRadius: 20, shadowOpacity: .2, overflow: "scroll" }}
              onChange={descriptionText => {
                props.onChange(descriptionText)
              }}
            />
          </Animatable.View>
        </View>
      </View>
    )
  });
export default TempScreen;
