import { View, FlatList, Text, Pressable, Platform } from 'react-native';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDispatch, useSelector } from 'react-redux';
import ProductItem from './ProductItem';
import Animated from 'react-native-reanimated';
import AnimatedLottieView from '@utils/lottieAnimation';
interface ProductListProps {
  refresh?: boolean;
  setRefresh?: (val: boolean) => void;
  type?: string;
}

export interface ListHandle {
  setColumns: (val: number) => void;
}


const ProductList = forwardRef<_, ProductListProps>((props, ref) => {
  const { type } = props;
  const productList = useSelector((state: DefaultRootState) => state.store.productList);
  const [retrievedProducts, setRetrievedProducts] = useState(productList);
  const [containerWidth, setContainerWidth] = useState(0);
  const dispatch = useDispatch();
  const getNearestWholeNumber = () => {
    const returnValue = Math.floor(containerWidth / 350);
    return returnValue;
  }

  useEffect(() => {
    setRetrievedProducts([]);
  }, [type])

  useEffect(() => {
    setColumns(getNearestWholeNumber());
  }, [containerWidth])

  const [columns, setColumns] = useState(getNearestWholeNumber());

  useImperativeHandle(ref, () => ({
    setColumns: (val: React.SetStateAction<number>) => setColumns(val)
  }));

  return (
    <View onLayout={e => setContainerWidth(e.nativeEvent.layout.width)}>
      {
        !productList ?
          <AnimatedLottieView source={require('@assets/animations/leaf_Loader.json')} autoPlay loop style={{ width: 100, height: 100, alignSelf: 'center', flex: 1 }} />
          :
          <FlatList 
          // onScroll={() => _handleSwipe()} 
            key={columns}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ gap: 10, paddingHorizontal: 10, paddingBottom:50 }}
            numColumns={columns} 
            data={productList}
            renderItem={({ item, index }) => (<ProductItem item={item} index={index} type={type} />)} 
             />
      }
    </View>
  )
});

export default ProductList;