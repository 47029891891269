import { produce } from 'immer';
import { Platform } from 'react-native';
import {
	MENU_RESIZE,
	SECOND_MENU_CONTEXT,
	SECOND_MENU_TOGGLE,
	CLOSE_SECOND_MENU,
	MENU_OPEN,
	MENU_CLOSE,
	TOGGLE_OTP,
	TOGGLE_MENU_WARPER,
	CLOSE_MENU_WARPER,
	INITIALISE_MENU,
	CURRENT_PAN,
	ACHIEVEMENT_POINTS_ADDED,
	ACHIEVEMENT_ANIMATION_END,
	DISMISSED_APP_INFO,
	TOGGLE_CONTEXT,
	TOGGLE_AI,
	SHOW_STICKY_WIDGET,
	BEGAN_ARROW_DRAG,
	STOPPED_ARROW_DRAG,
	SHOW_OTP,
	HIDE_OTP,
	NOTIFICATION_RECEIVED,
	NOTIFICATIONS_ACKNOWLEDGED,
	UPDATED_INVITATIONS,
	UPDATED_MESSAGES,
	UPDATED_CALENDAR_ENTRIES,
	RESET_INVITATIONS,
	INITIALISE_INVITATIONS,
	CHANGE_MENU,
	OPEN_QUICK_ACTIONS,
	CLOSE_QUICK_ACTIONS,
	OPEN_MENU,
	CONTEXT_SIZE_TOGGLE,
	HEADER_HEIGHT,
	STATUSBAR_HEIGHT,
	SET_MESSAGE_APP_FILTER,
	UPDATE_MAIL_COUNT,
	USER_HIERARCHY_FOCUSED,
	SET_SALES_DATE_FILTER,
	TOGGLE_GLOBAL_HIERARCHY,
	RESET_GLOBAL_HIERARCHY,
	SET_DATAFILTER,
	SET_UPLOADER_STAGE,
	SET_DRAGGED_USER,
	CLEAR_DRAGGED_USER,
	TOGGLE_ADVANCED_MODE,
	OPEN_CONTEXT,
	CLOSE_CONTEXT,
	UPDATE_PAGE_BADGES,
	CLEAR_PAGE_BADGES,
} from '../actions/types';
import { updateObject } from './utils/globalStateFunctions';

const initialState = {
	menuOpen: Platform.OS === 'web' && window.innerWidth > 400 ? true : false,
	menuWasOpen: null,
	secondaryMenuOpen: false,
	secondaryMenuContext: { active: false, content: [] },
	showOTP: false,
	menuWarper: false,
	quickActions: false,
	pannedView: false,
	pannedViewDetails: null,
	messageAppFilter: 'default',
	achievementAdded: false,
	appInfo: true,
	contextAvailable: false,
	contextMinimized: false,
	aiAvailable: false,
	advancedMode: false,
	stickyNoteWidget: false,
	arrowDragged: false,
	draggedUser: null,
	uploaderStage: 0,
	notification: false,
	dataFilter: [],
	badges:{
		welcome:0,
		data:0,
		sales: 0,
		projectmanagement: 0,
organiser: 0,
reports: 0,
company: 0,
knowledge: 0,
integrations: 0,
settings: 0,
academy: 0,
ai:0
	},
	salesDateFilter: { label: 'month', value: 'month' },
	userHierarchyFocused: false,
	hideRobots: false,
	userHierarchyGlobal: true,
	static: { statusBarHeight: 70, headerHeight: 70 },
	counts: { messageCount: 0, invitationCount: 0, calendarCount: 0 },
};

export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_PAGE_BADGES: 
		return updateObject(state, {
			badges: {
				...state.badges,
				[action.payload.page]: state.badges[action.payload.page] + action.payload.count,
			},
		});
		case CLEAR_PAGE_BADGES:
		return updateObject(state, {
			badges: {
				...state.badges,
[action.payload]: 0,
			}})
		case "HIDE_ROBOT": 
			return updateObject(state, {
				hideRobots: true,
			});
		case TOGGLE_ADVANCED_MODE:
			return updateObject(state, {
				...state,
				advancedMode: !state.advancedMode,
			});
		case SET_DRAGGED_USER:
			return updateObject(state, {
				...state,
				draggedUser: action.payload,
			});
		case CLEAR_DRAGGED_USER:
			return updateObject(state, {
				...state,
				draggedUser: null,
			});
		case SET_MESSAGE_APP_FILTER:
			return updateObject(state, {
				...state,
				messageAppFilter: action.payload,
			});
		case SET_UPLOADER_STAGE:
			return updateObject(state, {
				uploaderStage: action.payload,
			});
		case SET_DATAFILTER:
			return updateObject(state, {
				...state,
				dataFilter: action.payload,
			});
		case RESET_GLOBAL_HIERARCHY:
			return updateObject(state, {
				...state,
				userHierarchyGlobal: false,
			});
		case TOGGLE_GLOBAL_HIERARCHY:
			return updateObject(state, {
				...state,
				userHierarchyGlobal: !state.userHierarchyGlobal,
			});
		case SET_SALES_DATE_FILTER:
			return updateObject(state, {
				salesDateFilter: action.payload,
			});
		case UPDATE_MAIL_COUNT:
			return updateObject(state, {
				counts: { ...state.counts, messageCount: action.payload },
			});
		case USER_HIERARCHY_FOCUSED:
			return updateObject(state, {
				...state,
				userHierarchyFocused: action.payload,
			});
		case HEADER_HEIGHT:
			return updateObject(state, {
				...state,
				static: { ...state.static, headerHeight: action.payload },
			});
		case STATUSBAR_HEIGHT:
			return updateObject(state, {
				...state,
				static: { ...state.static, statusBarHeight: action.payload },
			});
		case CONTEXT_SIZE_TOGGLE:
			return updateObject(state, {
				...state,
				contextMinimized: !state.contextMinimized,
			});
			case OPEN_CONTEXT:
			return updateObject(state, {
				...state,
				contextMinimized: false,
			});
			case CLOSE_CONTEXT:
			return updateObject(state, {
				...state,
				contextMinimized: true,
			});
		case UPDATED_INVITATIONS:
			return updateObject(state, {
				counts: {
					...state.counts,
					invitationCount:
						action.payload + state.counts.invitationCount,
				},
			});
		case RESET_INVITATIONS:
			return updateObject(state, {
				counts: { ...state.counts, invitationCount: 0 },
			});
		case INITIALISE_INVITATIONS:
			return updateObject(state, {
				counts: { ...state.counts, invitationCount: action.payload },
			});
		case UPDATED_CALENDAR_ENTRIES:
			return updateObject(state, {
				counts: { ...state.counts, calendarCount: action.payload },
			});
		case UPDATED_MESSAGES:
			return updateObject(state, {
				counts: { ...state.counts, messageCount: action.payload },
			});
		case OPEN_QUICK_ACTIONS:
			return updateObject(state, {
				...state,
				quickActions: true,
			});
		case CLOSE_QUICK_ACTIONS:
			return updateObject(state, {
				...state,
				quickActions: false,
			});
		case INITIALISE_MENU:
			return updateObject(state, initialState);
		case DISMISSED_APP_INFO:
			return updateObject(state, {
				appInfo: false,
			});
		case BEGAN_ARROW_DRAG:
			return updateObject(state, {
				...state,
				arrowDragged: true,
			});
		case STOPPED_ARROW_DRAG:
			return updateObject(state, {
				...state,
				arrowDragged: false,
			});
		case TOGGLE_CONTEXT:
			return updateObject(state, {
				...state,
				contextAvailable: action.payload,
			});
		case TOGGLE_AI:
			return updateObject(state, {
				...state,
				aiAvailable: action.payload,
			});
		case MENU_RESIZE:
			const openMenu = state.menuOpen;
			return updateObject(state, {
				...state,
				menuOpen: !openMenu,
				menuWasOpen: !openMenu,
			});
		case CURRENT_PAN: {
			return updateObject(state, {
				...state,
				pannedView: true,
				pannedViewDetails: action.payload,
			});
		}
		case NOTIFICATION_RECEIVED: {
			return updateObject(state, {
				...state,
				notification: true,
			});
		}
		case NOTIFICATIONS_ACKNOWLEDGED: {
			return updateObject(state, {
				...state,
				notification: false,
			});
		}
		case ACHIEVEMENT_POINTS_ADDED: {
			return updateObject(state, {
				...state,
				achievementAdded: true,
			});
		}
		case ACHIEVEMENT_ANIMATION_END: {
			return updateObject(state, {
				...state,
				achievementAdded: false,
			});
		}
		case TOGGLE_MENU_WARPER:
			return updateObject(state, {
				...state,
				menuWarper: !state.menuWarper,
			});
		case CHANGE_MENU:
			return updateObject(state, {
				...state,
				menuWarper: false,
			});
		case CLOSE_MENU_WARPER:
			return updateObject(state, {
				...state,
				menuWarper: false,
			});
		case SHOW_OTP:
			return updateObject(state, {
				...state,
				showOTP: true,
				heldCredentials: action.payload,
			});
		case HIDE_OTP:
			return updateObject(state, {
				...state,
				showOTP: false,
				heldCredentials: undefined,
			});
		case MENU_OPEN:
			let wasSecondaryMenuOpen = state.secondaryMenuOpen;
			let wasSecondaryMenuContext = state.secondaryMenuContext;
			return updateObject(state, {
				...state,
				secondaryMenuOpen: wasSecondaryMenuOpen,
				secondaryMenuContext: wasSecondaryMenuContext,
				menuOpen: true,
				menuWasOpen: false,
			});
		case MENU_CLOSE:
			return updateObject(state, {
				...state,
				secondaryMenuOpen: state.secondaryMenuOpen,
				secondaryMenuContext: state.secondaryMenuContext,
				menuOpen: false,
				menuWasOpen: true,
			});
		case SECOND_MENU_TOGGLE:
			return updateObject(state, {
				...state,
				secondaryMenuOpen: false,
				secondaryMenuContext: {
					...state.secondaryMenuContext,
					content: [
						...state.secondaryMenuContext.content.slice(10, -1),
					],
				},
			});
		case SECOND_MENU_CONTEXT:
			if (Platform.OS != 'web') {
				const returnContentArray = produce(
					state.secondaryMenuContext.content,
					(newContent) => {
						newContent = [];
						action.data?.map((item) => {
							newContent.push(item);
						});
						return newContent;
					}
				);
				return updateObject(state, {
					...state,
					secondaryMenuOpen: true,
					secondaryMenuContext: {
						...state.secondaryMenuContext,
						active: action.data.active,
						content: returnContentArray,
					},
				});
			} else {
				return updateObject(state, {
					...state,
					secondaryMenuOpen: true,
					secondaryMenuContext: {
						...state.secondaryMenuContext,
						active: action.data.active,
						content: action.data,
					},
				});
			}
		case CLOSE_SECOND_MENU:
			return updateObject(state, {
				...state,
				secondaryMenuOpen: false,
				menuOpen: true,
				secondaryMenuContext: { active: false, content: [] },
			});
		default:
			return state;
	}
}
