import React, { useEffect, useState, memo, useMemo, useCallback } from "react";
import { Image, ImageProps, StyleProp, ImageStyle } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../stylesheet";
import * as Animatable from 'react-native-animatable';
import { store, DefaultRootState } from "@reduxLocal/persistState";
import { debounce } from "lodash";
import { SHOW_AUTH_MODAL } from "@reduxLocal/actions/types";

type AvatarImageProps = {
  style?: StyleProp<ImageStyle>;
  image?: string;
  withShadow?: boolean;
};

declare global {
  interface String {
    hashCode(): number;
  }
}

String.prototype.hashCode = function () {
  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    const char = this.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  if (hash < 0) {
    hash = hash * -1;
  } else if (!hash) {
    hash = 0;
  }
  return hash;
};

const AvatarImage = ({ style, image, withShadow }: AvatarImageProps) => {
  const user = useSelector((state: DefaultRootState) => state.promiseData.data.userData);
  const [loaded, setLoaded] = useState(false);
  const [counter, setCounter] = useState(0);
  const [avatarState, setAvatarState] = useState({ avatarFile: user.avatar?.img, hash: 0 });
  const lastAction = store.getState().lastAction;
  const dispatch = useDispatch();

  const updateCounter = useMemo(
    () => debounce(() => setCounter(prev => prev + 1), 2000),
    []
  );

  useEffect(() => {
    if (lastAction.type === 'AVATAR UPDATED SUCCESSFULLY') {
      updateCounter();
    }
  }, [lastAction, updateCounter]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }

    // Generate a hash using a counter and other relevant data
    const hash = hashFunction(user.avatar?.img, loaded, style, counter);

    setAvatarState({ avatarFile: user.avatar?.img ? user.avatar.img : "https://storage.googleapis.com/smartsaas-avatar-store/Asset%202.png", hash });
  }, [user.avatar?.img, loaded, style, counter]);

  const hashFunction = useCallback((...args) => {
    return args.join('-').hashCode();
  }, []);

  const imageUri = useMemo(() => {
    return `${avatarState.avatarFile}?${avatarState.hash}`;
  }, [avatarState]);

  return (
    <Animatable.Image
      key={user.avatar?.img}
      onError={() => {
        //Check string to see if it is a valid URL and originates from: https://storage.cloud.google.com
        if (user.avatar?.img?.includes('https://storage.cloud.google.com')) {
          dispatch({ type: SHOW_AUTH_MODAL, payload: { modalType: 'error', modalContent: 'Error loading image' } });
        } else {
          //Replace beginning of string with correct URL
          const newUrl = user.avatar?.img?.replace('https://storage.googleapis.com/smartsaas-avatar-store/', 'https://storage.cloud.google.com/smartsaas-avatar-store/');
          setAvatarState({ avatarFile: newUrl, hash: avatarState.hash });
        }

      }}
      transition={['height', 'width', 'borderRadius', 'minHeight', 'minWidth', 'maxHeight', 'maxWidth']}
      source={{ uri: image ? image : imageUri }}
      style={[styles.bigAvatar, { borderRadius: 30, aspectRatio: 1, shadowRadius: withShadow ? 20 : undefined, shadowOpacity: withShadow ? 0.2 : undefined }, style]}
    />
  )
};

export default memo(AvatarImage);
